import { useState } from "react";
import { useKeycloak } from "@react-keycloak/web";
import { getChannelConfig } from "../config";

const customKeycloak = () => ({
  keycloak: {
    onReady: () => null,
    onAuthRefreshSuccess: () => null,
    login: () => null,
    logout: () => null,
    authenticated: false,
    token: null,
    refreshToken: null,
  },
});

const useKeycloakSwitchFunc = () => {
  const [keycloakValue] = useState(customKeycloak);
  return keycloakValue;
};

const useKeycloakSwitch = getChannelConfig().keycloakLogin
  ? useKeycloak
  : useKeycloakSwitchFunc;

export default useKeycloakSwitch;
