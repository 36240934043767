import { Link as RouterLink } from "react-router-dom";
import { Box, Button, Container, Typography, AppBar } from "@mui/material";
import useTranslation from "../../hooks/useTranslation";
import GenericErrorImg from "../../assets/images/GenericErrorImg.svg";

const AuthorizationError = () => {
  const { t } = useTranslation("translation", { keyPrefix: "authError" });
  return (
    <>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          minHeight: "calc(100vh - 50px)",
        }}
      >
        <Container maxWidth="lg">
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              pb: "30px",
            }}
          >
            <Box
              alt="Error img not found"
              component="img"
              src={GenericErrorImg}
              sx={{
                height: "60px",
                width: "60px",
              }}
            />
          </Box>
          <Typography
            variant="h4"
            align="center"
            color="textPrimary"
            sx={{ pb: "25px" }}
          >
            {t("401")}
          </Typography>
          <Typography
            variant="h6"
            align="center"
            color="textSecondary"
            sx={{ pb: "50px" }}
          >
            {t("You are not allowed to access this.")}
          </Typography>
        </Container>
        <AppBar
          position="fixed"
          sx={{
            width: "100%",
            top: "auto",
            bottom: 0,
            p: "16px 16px 32px",
            backgroundColor: "background.paper",
          }}
        >
          <Button
            id="home-btn"
            color="primary"
            component={RouterLink}
            to="/"
            variant="contained"
            sx={{ borderRadius: "24px", width: "100%", height: "48px" }}
            fullWidth
          >
            <Typography variant="button">{"Back to Home"}</Typography>
          </Button>
        </AppBar>
      </Box>
    </>
  );
};

export default AuthorizationError;
