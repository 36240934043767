import { Box, IconButton } from "@mui/material";
import PartnerButton from "../PartnerButton";

const PartnerRewardButton = ({ onClick, ...partnerButtonProps }) => {
  return (
    <IconButton
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        py: "20px",
        border: "1px solid",
        borderColor: "grey.accent3",
        borderRadius: "16px",
      }}
      onClick={onClick}
      data-testid="partner-reward-button"
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <PartnerButton
          {...partnerButtonProps}
          partnerNameSx={{ mt: "16px" }}
          havePoint
          onlyUI
        />
      </Box>
    </IconButton>
  );
};

export default PartnerRewardButton;
