import React, { useEffect } from "react";
import MainConvertButton from "../MainConvertButton/MainConvertButton";
import SkeletonMainConvertButton from "../SkeletonMainConvertButton/SkeletonMainConvertButton";
import { useNavigate } from "react-router-dom";
import {
  useDispatch,
  useSelector,
} from "../../../../../../redux/store/configureStore";
import {
  fetchAsyncPartnerList,
  resetPartnerList,
} from "../../../../redux/slices/partnerList/partnerList";
import { trackClickLoyaltyBanner } from "../../../../../../mixpanel";
import { Status } from "../../../../../../redux/constant";
import { getCurrentLanguage } from "../../../../../../utils/getLanguage";
import { getPartnerImageByLang } from "utils/imageAsset";

const PartnerConvert = () => {
  const dispatch = useDispatch();
  const { partnerList, status: partnerListStatus } = useSelector(
    state => state.partnerList,
  );
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchAsyncPartnerList());

    return () => {
      dispatch(resetPartnerList());
    };
  }, [dispatch]);

  const handleOnClick = partnerId => {
    trackClickLoyaltyBanner(partnerId);
    navigate(`/convert/${partnerId}`);
  };

  return (
    <>
      {partnerListStatus === Status.LOADED ? (
        <>
          {partnerList?.items?.map(partner => (
            <MainConvertButton
              key={partner.id}
              onClick={() => handleOnClick(partner.id)}
              text={partner.name[getCurrentLanguage()] || ""}
              logo={getPartnerImageByLang(partner.id)}
            />
          ))}
        </>
      ) : (
        <SkeletonMainConvertButton />
      )}
    </>
  );
};

export default PartnerConvert;
