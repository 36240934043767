import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import NftMyCollectionAPI from "../../../../../apiConfig/NftMyCollectionAPI";
import { phoneNumberWithThaiCountryCode } from "../../../../../utils/phoneNumberFormatter";

export const status = {
  INITIAL: "INITIAL",
  LOADING: "LOADING",
  LOADED: "LOADED",
  EMPTY: "EMPTY",
  ERROR: "ERROR",
};

const initialState = {
  transaction: null,
  status: status.INITIAL,
  errorMessage: null,
};

//Thunks
export const fetchAsyncNftMyCollectionSend = createAsyncThunk(
  "nfts/fetchAsyncNftMyCollectionSend",
  async ({ address, tokenID, mobileNumber }, { rejectWithValue }) => {
    try {
      const response = await NftMyCollectionAPI.post(
        `/collections/${address}/transfers`,
        {
          tokenID: parseInt(tokenID, 10),
          recipient: {
            mobileNumber: phoneNumberWithThaiCountryCode(mobileNumber),
          },
        },
      );
      return response.data;
    } catch (err) {
      if (err.response) {
        return rejectWithValue({
          status: err.response.status,
          data: err.response.data,
        });
      }
      return rejectWithValue({
        status: "",
        data: err.message,
      });
    }
  },
);

//Slices
const NftMyCollectionSendSlice = createSlice({
  name: "nftMyCollectionSend",
  initialState,
  reducers: {
    resetNftMyCollectionSend: state => {
      state.transaction = null;
      state.status = status.INITIAL;
      state.errorMessage = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchAsyncNftMyCollectionSend.pending, state => {
        state.errorMessage = null;
        state.status = status.LOADING;
      })
      .addCase(
        fetchAsyncNftMyCollectionSend.fulfilled,
        (state, { payload }) => {
          state.transaction = payload;
          state.errorMessage = null;
          state.status = status.LOADED;
        },
      )
      .addCase(fetchAsyncNftMyCollectionSend.rejected, (state, { payload }) => {
        if (payload === undefined) {
          state.status = status.ERROR;
          return;
        }
        state.errorMessage = payload.data;
        state.status = status.ERROR;
      });
  },
});

export const { resetNftMyCollectionSend } = NftMyCollectionSendSlice.actions;

export default NftMyCollectionSendSlice.reducer;
