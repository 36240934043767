import { mixpanel } from "./mixpanelConfig";

export const trackVisitBuyCoinWithTMNPage = () => {
  mixpanel.track("Visit Buy Coin with TMN Page");
};

export const trackVisitEnterTrueMoneyAccountPage = () => {
  mixpanel.track("Visit Enter TrueMoney account page");
};

export const trackTMNOTPAttempt = (numOfAttempt = 1) => {
  mixpanel.track(`TMN OTP attempt #${numOfAttempt}`);
};

export const trackTMNOTPSuccess = () => {
  mixpanel.track("TMN OTP Success");
};

export const trackTMNForceOTPResend = () => {
  mixpanel.track("TMN Force OTP Resend");
};

export const trackCompletedBuyCoinWithTMN = amount => {
  mixpanel.track("Completed Buy Coin with TMN", {
    amount,
  });
};

export const trackFailedBuyCoinWithTMNInsufficientFund = amount => {
  mixpanel.track("Failed Buy Coin with TMN", {
    reason: "insufficient fund",
    amount,
  });
};

export const trackFailedBuyCoinWithTMNOtherCase = errorMessage => {
  mixpanel.track("Failed Buy Coin with TMN", {
    reason: errorMessage || "unknown",
  });
};
