import React from "react";
import { Button, Box, Typography, Avatar } from "@mui/material";
import { ReactComponent as SendIcon } from "../../../../../assets/icons/transaction-send.svg";
import { ReactComponent as ReceiveIcon } from "../../../../../assets/icons/transaction-receive.svg";
import { ReactComponent as RedeemIcon } from "../../../../../assets/icons/transaction-redeem.svg";
import { ReactComponent as ConvertIcon } from "../../../../../assets/icons/transaction-convert.svg";
import { abcLogo } from "../../../utils/abc";
import { formatDateTime } from "../../../../../utils/dateTimeFormatter";
import { useNavigate } from "react-router-dom";
import useTranslation from "../../../../../hooks/useTranslation";
import { coinFormatter } from "../../../../../utils/coinFormatter";
import { getPartnerImageByLang } from "utils/imageAsset";
import { getFeatureFlags, isEarth } from "../../../../../config";

export const KIND_TYPE = {
  voucher: "voucher",
  airdrop: "airdrop",
  swap: "swap",
  send: "send",
  receive: "receive",
  p2p: "p2p",
  f2c: "f2c",
};
export const CURRENCY = isEarth ? "ABC Coin" : "ABC Point";

export const getTransactionDetail = (
  t,
  lang,
  kind,
  metadata,
  currency = CURRENCY,
) => {
  const { enableTeslaSprint16_08_09_2021 } = getFeatureFlags()
  if (kind === KIND_TYPE.voucher) {
    return {
      title: `${t("Redeem")} ${currency}`,
      symbol: "-",
      icon: <ReceiveIcon />,
    };
  }
  if (kind === KIND_TYPE.airdrop) {
    return {
      title:
        lang === "th"
          ? `${t("Receive")} ${t("Airdrop")} ${currency}`
          : `${t("Receive")} ${currency} ${t("Airdrop")}`,
      symbol: "+",
      icon: <ReceiveIcon />,
    };
  }
  if (kind === KIND_TYPE.swap) {
    if (enableTeslaSprint16_08_09_2021) {
      return {
        title: t("Convert in partner point"),
        symbol: "+",
        icon: (
          <Box
            alt="coin-icon"
            component="img"
            src={getPartnerImageByLang(metadata?.partner?.id)}
            sx={{
              height: "40px",
              width: "40px",
            }}
          />
        ),
      };
    } else {
      return {
        title: `${t("Convert loyalty point to")} ${currency}`,
        symbol: "+",
        icon: <ConvertIcon />,
      };
    }
  }

  if (kind === KIND_TYPE.send) {
    return {
      title: `${t("Send")} ${currency}`,
      symbol: "-",
      icon: <SendIcon />,
    };
  }
  if (kind === KIND_TYPE.receive) {
    return {
      title: `${t("Receive")} ${currency}`,
      symbol: "+",
      icon: <ReceiveIcon />,
    };
  }
  if (kind === KIND_TYPE.f2c) {
    return {
      title: `${t("Buy")} ${currency}`,
      symbol: "+",
      icon: (
        <Box
          alt="coin-icon"
          component="img"
          src={abcLogo}
          sx={{
            height: "40px",
            width: "40px",
          }}
        />
      ),
    };
  }
  return {
    title: "",
    icon: null,
    symbol: "",
  };
};

const TransactionHistoryCard = ({ data, coinAccount }) => {
  /**
   * Detail: https://ascendbit.atlassian.net/wiki/spaces/ABC/pages/2106196153/Copywriting+Wallet+-+Info#Transaction-type
   * kind: voucher, p2p, airdrop,exchange
   * type: Recive, Redeem, Buy, Convert loyalty point to, Send
   *
   * Redeem currency name  -> "voucher"
   * Receive currency name Airdrop ->"airdrop"
   * Convert loyalty point to currency name   ->"swap"
   * Send currency name ->"send"
   * Receive currency name ->"receive"
   * */

  const { t } = useTranslation("translation", { keyPrefix: "walletHistory" });
  const navigate = useNavigate();
  const lang = localStorage.getItem("lang");

  const handleClick = transactionHash => {
    navigate(`/wallet/transaction/${transactionHash}`, {
      state: {
        ...data,
        coinAccount,
        currency: CURRENCY,
      },
    });
  };

  const { transactionHash, amount, kind, metadata, confirmedAt } = data;

  const { title, symbol, icon } = getTransactionDetail(
    t,
    lang,
    kind,
    metadata,
    CURRENCY,
  );

  return (
    <Button
      id="transaction-history-card"
      data-testid="transaction-history-card"
      onClick={() => handleClick(transactionHash)}
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        width: "100%",
        padding: "16px",
        borderRadius: "16px",
        mb: "16px",
        border: "1px solid",
        borderColor: "grey.accent3",
        backgroundColor: "background.paper",
      }}
    >
      <Avatar
        sx={{
          bgcolor: "grey.accent4",
          border: "1px solid",
          borderColor: "grey.accent3",
          marginRight: "8px",
          width: "40px",
          height: "40px",
        }}
      >
        {metadata?.merchant?.logo ? (
          <Box
            component="img"
            src={metadata.merchant.logo}
            sx={{
              height: "40px",
              width: "40px",
            }}
          />
        ) : (
          icon
        )}
      </Avatar>
      <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
        <Typography
          variant="body2"
          sx={{ textAlign: "left", color: "grey.accent1" }}
        >
          {title}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            mt: "8px",
            alignItems: "center",
          }}
        >
          <Typography variant="body2" sx={{ color: "grey.accent2" }}>
            {formatDateTime(confirmedAt, "HH:mm")}
          </Typography>
          <Typography
            variant="subtitle"
            sx={{ textAlign: "right", color: "grey.accent1" }}
          >
            {`${symbol}${coinFormatter(amount + "")} ${CURRENCY}`}
          </Typography>
        </Box>
      </Box>
    </Button>
  );
};

export default TransactionHistoryCard;
