import { isEarth } from "config";
import en from "../assets/locales/en/translation.json";
import th from "../assets/locales/th/translation.json";
import getLanguage from "../utils/getLanguage";
import { mapValues } from "lodash";

let translationDataEN = en;
let translationDataTH = th;

const useTranslation = (_, config) => {
  const t = text => {
    const lang = localStorage.getItem("lang");
    return lang === "th"
      ? translationDataTH[config.keyPrefix][text]
      : translationDataEN[config.keyPrefix][text];
  };

  const initializeTranslation = () => {
    const validLanguages = ["en", "th"];
    let language = getLanguage();
    if (!language) {
      language = localStorage.getItem("lang");
    }
    if (language && validLanguages.includes(language)) {
      localStorage.setItem("lang", language);
    } else {
      localStorage.setItem("lang", "en");
    }

    // loop and replace wording "Wallet" or "Point" base on channel
    // Guess that defaul will be Earth that will use "Wallet"
    const data = language === "th" ? th : en;
    const abcPointWord = language === "th" ? "เอบีซี พอยต์" : "ABC Point";
    const translationData = isEarth
      ? data
      : mapValues(data, val => {
          return mapValues(val, value => {
            return value.replaceAll(
              /(ABC Wallet|ABC วอลเล็ท|ABC wallet|ABC Coins|ABC Coin|ABC coins|ABC coin)/g,
              abcPointWord,
            );
          });
        });
    if (language === "th") {
      translationDataTH = translationData;
    } else {
      translationDataEN = translationData;
    }
  };

  return { t, initializeTranslation };
};

export default useTranslation;
