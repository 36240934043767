import React, { useEffect, useState, useRef } from "react";
import { Box, Button, Typography, Paper } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { useLocation, useNavigate } from "react-router-dom";
import useTranslation from "../../../../hooks/useTranslation";
import useNavigationHook from "../../../../hooks/useNavigation";
import CarouselOnboarding from "./carouselOnboarding/CarouselOnboarding";
import ConnectWalletModal from "components/connectWalletModal/ConnectWalletModal";
import { useWeb3React } from "@web3-react/core";
import { trackTapConnectWallet } from "mixpanel/nft";
import {
  walletconnect,
  resetWalletConnector,
} from "smartcontract/utils/connector";
import { isMobile } from "react-device-detect";

const BridgeNftOnboarding = () => {
  const { token, collection } = useLocation().state;
  const { t } = useTranslation("translation", {
    keyPrefix: "bridgeNft",
  });
  const { activate, active, deactivate, chainId } = useWeb3React();
  const [open, setOpen] = useState(false);
  const [isRedirect, setIsRedirect] = useState(false);
  const setActiveMenu = useNavigationHook();
  const navigate = useNavigate();
  const [footerHeight, setFooterHeight] = useState(0);
  const footerRef = useRef(null);
  useEffect(() => {
    setFooterHeight(footerRef.current.clientHeight);
  }, []);

  useEffect(() => {
    setActiveMenu(true);
  }, [setActiveMenu]);

  useEffect(() => {
    if (active && isRedirect && chainId == process.env.REACT_APP_CHAIN_ID) {
      navigate("/wallet/nfts/my-collection/bridge-detail-nft", {
        state: {
          collection,
          token,
        },
      });
    } else {
      setIsRedirect(true);
      deactivate();
    }
  }, [active]);

  const handleOnClickCancel = () => navigate("/wallet/nfts");
  const handleConnectWalet = async () => {
    trackTapConnectWallet();
    if (isMobile) {
      await resetWalletConnector(walletconnect);
      await activate(walletconnect);
    } else {
      setOpen(true);
    }
  };

  return (
    <>
      <Helmet>
        <title>{t("Bridge NFT")}</title>
      </Helmet>
      <Box maxWidth="552px" m="auto">
        <CarouselOnboarding footerHeight={footerHeight} />
      </Box>
      <Paper
        sx={{
          position: "sticky",
          bottom: 0,
          left: 0,
          right: 0,
        }}
        elevation={0}
      >
        <Box
          ref={footerRef}
          p="16px"
          m="auto"
          maxWidth="360px"
          display="flex"
          flexDirection="column"
          sx={{
            gap: "16px",
          }}
        >
          <Button
            data-testid="btn_connect-metamask"
            variant="contained"
            sx={{ height: "48px", borderRadius: "24px", gap: "8px" }}
            fullWidth
            display="flex"
            onClick={handleConnectWalet}
          >
            {t("Connect Wallet")}
          </Button>
          <Button
            data-testid="btn_cancel"
            variant="text"
            fullWidth
            sx={{ height: "48px", borderRadius: "24px" }}
            onClick={handleOnClickCancel}
          >
            <Typography variant="button">{t("Cancel")}</Typography>
          </Button>
        </Box>
      </Paper>
      <ConnectWalletModal open={open} handleClose={() => setOpen(false)} />
    </>
  );
};

export default BridgeNftOnboarding;
