import React, { useEffect, useRef, useState, useCallback } from "react";
import { Helmet } from "react-helmet-async";
import {
  Box,
  Typography,
  Button,
  Checkbox,
  FormGroup,
  FormControlLabel,
} from "@mui/material";
import { styled } from "@mui/system";
import { useLocation, useNavigate } from "react-router-dom";
import useNavigationHook from "hooks/useNavigation";
import { useDispatch, useSelector } from "redux/store/configureStore";
import { Status } from "redux/constant";
import useTranslation from "hooks/useTranslation";
import TmnWalletLogo from "assets/icons/company-logos/tmn-wallet-logo.svg";
import AbcCoinRotateLeft from "assets/images/abc-coin-rotate-left.svg";
import AbcCoinRotateRight from "assets/images/abc-coin-rotate-right.svg";
import useWindowDimensions from "hooks/useWindowDimensions";
import useNavbarHeight from "hooks/useNavbarHeight";
import { trackVisitEnterTrueMoneyAccountPage } from "mixpanel/buyCoins";
import EnterAccountCard from "features/wallet/components/convertCoins/enterAccountCard/EnterAccountCard";
import { getCurrentLanguage } from "utils/getLanguage";
import {
  fetchAsyncConvertF2CSendOtp,
  resetConvertF2CSendOtp,
} from "features/wallet/redux/slices/convertF2C/convertF2CSendOtp";
import Prompt from "components/Prompt/Prompt";

const MainBackground = styled(Box)(() => ({
  position: "absolute",
  width: "100%",
  height: "100%",
  left: "0px",
  top: "0px",
  background:
    "linear-gradient(73.47deg, rgba(111, 255, 246, 0.2) 11.45%, rgba(229, 88, 9, 0.1) 61.1%, rgba(176, 174, 168, 0.1) 88.55%)",
  zIndex: "-1",
}));

const LeftCircleBackground = styled(Box)(() => ({
  position: "absolute",
  width: "383.74px",
  height: "383.74px",
  left: "-52.25px",
  top: "-55px",
  background:
    "linear-gradient(67.83deg, rgba(255, 171, 165, 0.65) 23.99%, rgba(255, 193, 189, 0) 81.36%)",
  filter: "blur(30px)",
  borderRadius: "200px",
  transform: "rotate(19.13deg)",
  zIndex: "-1",
}));

const RightCircleBackground = styled(Box)(() => ({
  position: "absolute",
  width: "408.55px",
  height: "408.55px",
  right: "-52.25px",
  top: "10px",
  background:
    "linear-gradient(67.83deg, rgba(255, 246, 165, 0.65) 23.99%, rgba(255, 193, 189, 0) 81.36%)",
  filter: "blur(30px)",
  borderRadius: "200px",
  transform: "rotate(-128.62deg)",
  zIndex: "-1",
}));

const EnterAccount = () => {
  const { height, width } = useWindowDimensions();
  const navbarHeight = useNavbarHeight();
  const [footerHeight, setFooterHeight] = useState(0);
  const footerRef = useRef(null);
  useEffect(() => {
    setFooterHeight(footerRef.current.clientHeight);
  }, []);

  const { abcBalance, payBalance } = useLocation().state || {};
  const setActiveMenu = useNavigationHook();
  const { t } = useTranslation("translation", {
    keyPrefix: "BuyCoins",
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    convertF2CSendOtp,
    status: convertF2CSendOtpStatus,
    error: convertF2CSendOtpError,
  } = useSelector(state => state.convertF2CSendOtp);

  const mobileNumberRef = useRef("");

  const [mobileNumber, setMobileNumber] = useState({
    number: "",
    errorMessage: "",
  });

  const [acceptTNS, setAcceptTNS] = useState(false);

  const [promptErrorOpen, setPromptErrorOpen] = useState(false);

  useEffect(() => {
    if (!abcBalance) {
      navigate("/wallet/buy-coins");
    } else {
      trackVisitEnterTrueMoneyAccountPage();
    }
  }, [navigate, abcBalance]);

  useEffect(() => {
    setActiveMenu(true);
  }, [setActiveMenu]);

  useEffect(() => {
    return () => {
      dispatch(resetConvertF2CSendOtp());
    };
  }, [dispatch]);

  const getSendOtpErrorMessage = useCallback(error => {
    if (error === "account_invalid") {
      return t(
        "This mobile number is not registered for TrueMoney account or this account has been suspended, please contact 1240.",
      );
    } else if (error === "number_invalid") {
      return t("Invalid mobile number");
    }
    return "";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      convertF2CSendOtpStatus === Status.LOADED ||
      (convertF2CSendOtpStatus === Status.ERROR &&
        convertF2CSendOtpError?.statusCode === 420)
    ) {
      dispatch(resetConvertF2CSendOtp());
      navigate("/wallet/buy-coins/confirm-purchase", {
        state: {
          abcBalance,
          payBalance,
          mobileNumber: mobileNumberRef.current,
          otpRef: convertF2CSendOtp?.otpRef,
          paymentRef: convertF2CSendOtp?.paymentRef,
          retriesLeft: convertF2CSendOtp?.retriesLeft,
          retriesMax: convertF2CSendOtp?.retriesMax,
          validUntil: convertF2CSendOtp?.validUntil,
        },
      });
    } else if (convertF2CSendOtpStatus === Status.ERROR) {
      const errorMessage = getSendOtpErrorMessage(
        convertF2CSendOtpError?.errorMessage?.error,
      );
      if (errorMessage) {
        setMobileNumber({
          number: mobileNumberRef.current,
          errorMessage,
        });
      } else {
        setPromptErrorOpen(true);
      }
    }
  }, [
    convertF2CSendOtpStatus,
    convertF2CSendOtp,
    convertF2CSendOtpError,
    getSendOtpErrorMessage,
    abcBalance,
    payBalance,
    navigate,
    dispatch,
  ]);

  const isDisableButton = () => {
    return (
      !acceptTNS ||
      !!mobileNumber.errorMessage ||
      convertF2CSendOtpStatus === Status.LOADING
    );
  };

  const isInvalidEmptyInput = () => {
    if (!mobileNumber.number) {
      setMobileNumber({
        number: mobileNumber.number,
        errorMessage: t("Invalid mobile number"),
      });
      return true;
    }
    return false;
  };

  const isInvalidMobileNumber = () => {
    if (mobileNumber.number?.length !== 10) {
      setMobileNumber({
        number: mobileNumber.number,
        errorMessage: t("Invalid mobile number"),
      });
      return true;
    }
    return false;
  };

  const isInvalidInput = () => {
    return isInvalidEmptyInput() || isInvalidMobileNumber();
  };

  const handleClick = () => {
    if (isInvalidInput()) return;
    mobileNumberRef.current = mobileNumber.number;
    dispatch(
      fetchAsyncConvertF2CSendOtp({ mobileNumber: mobileNumberRef.current }),
    );
  };

  const handleClickCancel = () => {
    navigate("/wallet");
  };

  const handleClickAcceptTermsOfService = () => {
    setAcceptTNS(isCheck => !isCheck);
  };

  const handleClickTermsOfService = e => {
    e.preventDefault();
    const link =
      getCurrentLanguage() === "th"
        ? process.env.REACT_APP_TMN_TERMS_OF_SERVICE_TH
        : process.env.REACT_APP_TMN_TERMS_OF_SERVICE_EN;
    window.open(link, "_blank", "noopener");
  };

  const renderBackground = () => {
    return (
      <Box sx={{ position: "relative" }}>
        <MainBackground
          sx={{ width: `${width}px`, height: `${height - navbarHeight}px` }}
        />
        <LeftCircleBackground />
        <RightCircleBackground />
        <Box
          component="img"
          src={AbcCoinRotateLeft}
          sx={{
            position: "absolute",
            top: "420px",
            left: "30px",
            zIndex: -1,
          }}
        />
        <Box
          component="img"
          src={AbcCoinRotateRight}
          sx={{
            position: "absolute",
            top: "370px",
            right: "15px",
            zIndex: -1,
          }}
        />
      </Box>
    );
  };

  const renderFooter = () => {
    const lang = getCurrentLanguage();
    return (
      <Box
        ref={footerRef}
        sx={{
          position: "sticky",
          bottom: 0,
          backgroundColor: "background.paper",
          width: "100%",
          padding: "16px 16px 22px 16px",
        }}
      >
        <Box sx={{ mb: "16px" }}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={acceptTNS}
                  onChange={handleClickAcceptTermsOfService}
                />
              }
              label={
                lang === "th" ? (
                  <Typography variant="subtitle1">
                    {`${t("Accept")} `}
                    <Typography
                      variant="subtitle1"
                      component={"a"}
                      onClick={handleClickTermsOfService}
                      sx={{ color: "secondary.blue.accent5" }}
                    >
                      {t("Terms of Service")}
                    </Typography>
                    {` ${t("TrueMoney's")}`}
                  </Typography>
                ) : (
                  <Typography variant="subtitle1">
                    {`${t("Accept")} ${t("TrueMoney's")} `}
                    <Typography
                      variant="subtitle1"
                      component={"a"}
                      onClick={handleClickTermsOfService}
                      sx={{ color: "secondary.blue.accent5" }}
                    >
                      {t("Terms of Service")}
                    </Typography>
                  </Typography>
                )
              }
            />
          </FormGroup>
        </Box>
        <Button
          id="btn_continue"
          variant="contained"
          fullWidth
          sx={{ height: "48px", borderRadius: "24px", mb: "8px" }}
          disabled={isDisableButton()}
          onClick={handleClick}
        >
          <Typography variant="button">{t("GET OTP")}</Typography>
        </Button>
        <Button
          id="btn_cancel"
          variant="text"
          fullWidth
          sx={{ height: "48px", borderRadius: "24px" }}
          onClick={handleClickCancel}
        >
          <Typography variant="button">{t("CANCEL")}</Typography>
        </Button>
      </Box>
    );
  };

  return (
    <>
      <Helmet>
        <title>{t("Enter TMN account")}</title>
      </Helmet>
      <Box sx={{ overflowX: "hidden" }}>
        {renderBackground()}
        <Box
          sx={{
            pt: "32px",
            pb: "32px",
            px: "16px",
            overflowY: "auto",
            height: `calc(${height}px - ${navbarHeight}px - ${footerHeight}px)`,
            maxHeight: `calc(${height}px - ${navbarHeight}px - ${footerHeight}px)`,
          }}
          data-testid="enter-account"
        >
          <Box>
            <EnterAccountCard
              logo={TmnWalletLogo}
              useMobileNumber={{
                mobileNumber,
                setMobileNumber,
              }}
            />
          </Box>
        </Box>
        {renderFooter()}
      </Box>
      <Prompt
        isVisible={promptErrorOpen}
        icon={"error"}
        onClose={() => setPromptErrorOpen(false)}
        title={"Oops, there was an issue"}
        subTitle={"Please try again"}
        buttonText={"OK"}
      />
    </>
  );
};

export default EnterAccount;
