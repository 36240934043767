import { useState, useEffect, useCallback, useMemo } from "react";
import { Status } from "redux/constant";
import { useDispatch, useSelector } from "redux/store/configureStore";
import {
  fetchAsyncConvertPartnerBalance,
  resetConvertPartnerBalance,
} from "../redux/slices/convertPartnerCoins/convertPartnerBalance";

export default function usePartnerBalance() {
  const dispatch = useDispatch();
  const { status: convertPartnerBalanceStatus } = useSelector(
    state => state.convertPartnerBalance,
  );
  const { status: partnerListStatus } = useSelector(state => state.partnerList);
  const { linkedPartner, status: linkedPartnerStatus } = useSelector(
    state => state.linkedPartner,
  );
  const [partnersBalance, setPartnersBalance] = useState({});

  const filteredLinkedPartner = useMemo(
    () =>
      linkedPartner?.filter(
        linkedItem => linkedItem.connected && !linkedItem.social,
      ),
    [linkedPartner],
  );

  const fetchBalance = useCallback(
    async partnerId => {
      try {
        const _balance = await dispatch(
          fetchAsyncConvertPartnerBalance({ partnerId }),
        );
        setPartnersBalance(state => ({
          ...state,
          [partnerId]: _balance.payload.balance,
        }));
      } catch (error) {
        setPartnersBalance(state => ({
          ...state,
          [partnerId]: 0,
        }));
      }
    },
    [dispatch],
  );

  useEffect(() => {
    if (
      linkedPartnerStatus === Status.LOADED &&
      partnerListStatus === Status.LOADED
    ) {
      filteredLinkedPartner?.forEach(item => {
        fetchBalance(item.providerName);
      });
    }
  }, [
    linkedPartnerStatus,
    partnerListStatus,
    filteredLinkedPartner,
    fetchBalance,
  ]);

  useEffect(() => {
    return () => {
      dispatch(resetConvertPartnerBalance());
    };
  }, [dispatch]);

  return partnersBalance;
}
