import { createContext, useEffect, useReducer } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import AuthContextServices from "./AuthContextServices";
import { useSelector } from "../../redux/store/configureStore";
import jwtDecode from "jwt-decode";
import { trackLoginSignup } from "../../mixpanel";
import * as Sentry from "@sentry/react";
import useKeycloakSwitch from "channel/useKeycloakSwitch";
import KeycloakAPI from "apiConfig/KeycloakAPI";
import { isFusion } from "config";

export const authStatus = {
  INITIAL: "INITIAL",
  LOADING: "LOADING",
  LOGGED_IN: "LOGGED_IN",
  INACTIVE: "INACTIVE",
  ONBOARDING: "ONBOARDING",
  TOS_REQUIRED: "TOS_REQUIRED",
  GUEST: "GUEST",
  ERROR: "ERROR",
  SIGN_UP_EXCEPTION_ERROR: "SIGN_UP_EXCEPTION_ERROR",
};

const authAction = {
  INITIALIZE_EXISTING: "INITIALIZE_EXISTING",
  INITIALIZE_GUEST: "INITIALIZE_GUEST",
  ACCEPT_TOS: "ACCEPT_TOS",
  DECLINE_TOS: "DECLINE_TOS",
  LOADING: "LOADING",
  UPDATE_USER_PROFILE_PHOTO: "UPDATE_USER_PROFILE_PHOTO",
  UPDATE_USERNAME: "UPDATE_USERNAME",
  LOGOUT: "LOGOUT",
  SIGN_UP_EXCEPTION: "SIGN_UP_EXCEPTION",
};

export const initialState = {
  tmwTokenRef: null,
  token: null,
  user: null,
  status: authStatus.INITIAL,
};

export const AuthContext = createContext({
  ...initialState,
  acceptTOS: () => Promise.resolve(),
  declineTOS: () => {},
  initialize: () => {},
  updateUsername: ({ firstNameEN, lastNameEN, firstNameTH, lastNameTH }) => {},
  updateUserProfilePhoto: ({ profilePicture }) => {},
  logout: () => null,
});

export const reducer = (state, action) => {
  if (action.type === authAction.LOADING) {
    const { status } = action.payload;
    return {
      ...state,
      status,
    };
  }

  if (action.type === authAction.INITIALIZE_EXISTING) {
    const { tmwTokenRef, token, user, status } = action.payload;
    return {
      ...state,
      tmwTokenRef,
      token,
      user,
      status,
    };
  } else if (action.type === authAction.INITIALIZE_GUEST) {
    const { tmwTokenRef, status } = action.payload;
    return {
      ...state,
      tmwTokenRef,
      status,
    };
  } else if (action.type === authAction.ACCEPT_TOS) {
    const { token, user, status } = action.payload;
    return {
      ...state,
      token,
      user,
      status,
    };
  } else if (action.type === authAction.DECLINE_TOS) {
    const { status } = action.payload;
    return {
      ...state,
      status,
    };
  } else if (action.type === authAction.UPDATE_USER_PROFILE_PHOTO) {
    const { user, status } = action.payload;
    return {
      ...state,
      user: { ...state.user, ...user },
      status,
    };
  } else if (action.type === authAction.UPDATE_USERNAME) {
    const { user, status } = action.payload;
    return {
      ...state,
      user: { ...state.user, ...user },
      status,
    };
  } else if (action.type === authAction.LOGOUT) {
    return {
      ...state,
      token: null,
      status: authStatus.GUEST,
    };
  } else if (action.type === authAction.SIGN_UP_EXCEPTION) {
    const { status } = action.payload;
    return {
      ...state,
      status,
    };
  }

  return state;
};

const fetchLinkedAccount = async () => {
  try {
    const response = await KeycloakAPI.get("/account/linked-accounts");
    return response.data;
  } catch (error) {
    return error;
  }
};

const saveKeycloakToken = keycloak => {
  const data = {
    access_token: keycloak?.token,
    refresh_token: keycloak?.refreshToken,
  };
  AuthContextServices.setLocalStorageToken(JSON.stringify(data));
  return data;
};

export const AuthProvider = props => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const { terms } = useSelector(_state => _state.terms);
  const navigate = useNavigate();
  const { keycloak } = useKeycloakSwitch();

  keycloak.onReady = authenticated => {
    if (state.status === authStatus.GUEST && keycloak?.authenticated) {
      initialize();
    }
  };
  keycloak.onAuthRefreshSuccess = () => {
    // save the new token to storage after refresh token
    saveKeycloakToken(keycloak);
  };

  useEffect(() => {
    if (state.status === authStatus.INITIAL) {
      initialize();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialize = async () => {
    let keyFromParam = AuthContextServices.getQueryParam();
    if (keyFromParam) {
      navigate("", { replace: true });
    }
    let tmwTokenRef =
      keyFromParam ||
      state.tmwTokenRef ||
      AuthContextServices.getTmwTokenRefFromLocalStorage();

    if (!tmwTokenRef && !keycloak?.authenticated) {
      dispatch({
        type: authAction.INITIALIZE_GUEST,
        payload: { status: authStatus.GUEST },
      });
      return;
    }
    try {
      let data = undefined;
      if (isFusion) {
        if (keyFromParam) {
          const response = await AuthContextServices.getUserFromKey(
            tmwTokenRef,
          );
          data = response.data;
          AuthContextServices.setLocalStorageTmwTokenRef(tmwTokenRef);
          AuthContextServices.setLocalStorageToken(JSON.stringify(data));
        } else {
          const token = AuthContextServices.getTokenFromLocalStorage();
          data = JSON.parse(token);
        }
      } else {
        data = saveKeycloakToken(keycloak);
      }
      let parsedAccessToken = jwtDecode(data.access_token);

      if (parsedAccessToken.status === "onboarding") {
        dispatch({
          type: authAction.INITIALIZE_EXISTING,
          payload: {
            tmwTokenRef: tmwTokenRef,
            token: data,
            status: authStatus.ONBOARDING,
          },
        });
        return;
      }
      if (parsedAccessToken.termsAgreement !== "agreed") {
        dispatch({
          type: authAction.INITIALIZE_EXISTING,
          payload: {
            tmwTokenRef: tmwTokenRef,
            token: data,
            status: authStatus.TOS_REQUIRED,
          },
        });
        return;
      }
      const profileResponse = await AuthContextServices.getProfile(
        parsedAccessToken.profileID,
      );

      const user = {
        ...profileResponse.data,
        sub: parsedAccessToken.sub,
      };

      dispatch({
        type: authAction.INITIALIZE_EXISTING,
        payload: {
          tmwTokenRef: tmwTokenRef,
          token: data,
          user,
          status: authStatus.LOGGED_IN,
        },
      });

      const linkedAccountList = await fetchLinkedAccount();
      const trackData = { ...user, linkedAccountList: linkedAccountList || [] };
      trackLoginSignup(trackData);

      return;
    } catch (err) {
      Sentry.captureException(err);
      if (err.response) {
        const {
          status,
          data: { error, error_description },
        } = err.response;

        if (
          status === 401 &&
          error === "You already have an account." &&
          error_description ===
            "Try to log in with your existing account to continue."
        ) {
          dispatch({
            type: authAction.SIGN_UP_EXCEPTION,
            payload: {
              status: authStatus.SIGN_UP_EXCEPTION_ERROR,
            },
          });
          navigate("/unhandled-exception", { replace: true });
          return;
        }
        if (
          err.response.status === 401 &&
          err.response.data.error_description === "User disabled"
        ) {
          dispatch({
            type: authAction.INITIALIZE_EXISTING,
            payload: {
              status: authStatus.INACTIVE,
            },
          });
          return;
        }
      }

      dispatch({
        type: authAction.INITIALIZE_GUEST,
        payload: {
          tmwTokenRef: tmwTokenRef,
          status: authStatus.ERROR,
        },
      });
      return;
    }
  };

  const acceptTOS = async () => {
    dispatch({
      type: authAction.LOADING,
      payload: {
        status: authStatus.LOADING,
      },
    });

    try {
      let language = localStorage.getItem("lang");
      language === "en-US" && (language = "en");
      await AuthContextServices.acceptTOS(
        language,
        "abc-wallet-tnc",
        terms?.data?.version,
      );
      const response = await AuthContextServices.refreshUser(
        state.token?.refresh_token,
      );
      const parsedAccessToken = jwtDecode(response.data.access_token);
      const profileResponse = await AuthContextServices.getProfile(
        parsedAccessToken.profileID,
      );

      const user = {
        ...profileResponse.data,
        sub: parsedAccessToken.sub,
      };
      AuthContextServices.setLocalStorageToken(JSON.stringify(response.data));
      const linkedAccountList = await fetchLinkedAccount();
      const trackData = { ...user, linkedAccountList: linkedAccountList || [] };
      trackLoginSignup(trackData);
      dispatch({
        type: authAction.ACCEPT_TOS,
        payload: {
          token: response.data,
          user,
          status: authStatus.LOGGED_IN,
        },
      });
    } catch (err) {
      navigate("/unhandled-exception", { replace: true });
    }
  };

  const declineTOS = async () => {
    dispatch({
      type: authAction.DECLINE_TOS,
      payload: {
        status: authStatus.ONBOARDING,
      },
    });
  };

  const updateUserProfilePhoto = async ({ profilePicture }) => {
    dispatch({
      type: authAction.UPDATE_USER_PROFILE_PHOTO,
      payload: {
        user: { profilePicture },
        status: authStatus.LOGGED_IN,
      },
    });
  };

  const updateUsername = async ({
    firstNameEN,
    lastNameEN,
    firstNameTH,
    lastNameTH,
  }) => {
    dispatch({
      type: authAction.UPDATE_USER_PROFILE_PHOTO,
      payload: {
        user: { firstNameEN, lastNameEN, firstNameTH, lastNameTH },
        status: authStatus.LOGGED_IN,
      },
    });
  };

  const logout = () => {
    dispatch({ type: authAction.LOGOUT });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        acceptTOS,
        declineTOS,
        initialize,
        updateUserProfilePhoto,
        updateUsername,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthContext;
