import AccessTokenService from "contexts/authContext/AuthContextServices";

export const getAccessToken = () => {
  let accessToken = null;
  try {
    const tokenObj = AccessTokenService.getTokenFromLocalStorage();
    const { access_token } = JSON.parse(tokenObj);
    accessToken = access_token;
  } catch (e) {}
  return accessToken;
};
