import React from "react";
import { Box, IconButton, Typography } from "@mui/material";
import walletCoinIcon from "../../../../assets/icons/coins.svg";
import ABCPointIcon from "../../../../assets/icons/ABCPoint.svg";
import useTranslation from "../../../../hooks/useTranslation";
import {
  useDispatch,
  useSelector,
} from "../../../../redux/store/configureStore";
import {
  fetchAsyncCoins,
  status as CoinsStatus,
} from "../../redux/slices/coins/Coins";
import useAuth from "../../../../hooks/useAuth";
import { coinFormatter } from "../../../../utils/coinFormatter";
import MyWalletSkeleton from "../../skeletonLoading/MyWalletSkeleton";
import TransactionHistoryList from "../transactionHistoryList/TransactionHistoryList";
import { phoneNumberSpaceSeparatorFormatter } from "../../../../utils/phoneNumberFormatter";
import ConvertButton from "./convertButton/ConvertButton";
import { getChannelConfig, getFeatureFlags } from "../../../../config";
import SendLogo from "../../../../assets/icons/wallet/send-button.svg";
import ConvertIcon from "../../../../assets/icons/home/quick-action-convert.svg";
import BuyIcon from "../../../../assets/icons/home/quick-action-buy.svg";
import { useNavigate } from "react-router-dom";
import {
  QUICK_ACTION_TYPE,
  trackMyWalletQuickAction,
  trackVisitMyWalletPage,
} from "../../../../mixpanel";
import PartnerConvert from "./convert/PartnerConvert/PartnerConvert";

const MyWallet = () => {
  const { t } = useTranslation("translation", {
    keyPrefix: "myWallet",
  });
  const { coins, status } = useSelector(state => state.coins);
  const { user } = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleQuickActionClick = (pathname, search, quickActionType) => {
    trackMyWalletQuickAction(quickActionType);
    navigate({
      pathname,
      search,
    });
  };

  React.useEffect(() => {
    if (status === CoinsStatus.INITIAL) {
      dispatch(fetchAsyncCoins());
    }
  }, [status, dispatch]);

  React.useEffect(() => {
    if (coins?.balance !== null && typeof coins?.balance !== "undefined") {
      trackVisitMyWalletPage(coins.balance);
    }
  }, [coins]);

  React.useEffect(() => {
    // return () => dispatch(resetCoins());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { enableRelease_1_3_0, enableConvertLoyaltyPoint, enableF2C, enableP2P } = getFeatureFlags()

  const MyWalletError = () => {
    return (
      <Box
        sx={{
          border: 1,
          borderColor: "grey.accent3",
          borderRadius: "16px",
          minHeight: "292px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "background.paper",
        }}
      >
        <Typography
          sx={{
            m: "122px 47px",
          }}
          color="grey.accent2"
          variant="body2"
          align="center"
        >
          {t(
            "This function is temporarily unavailable. Please try again later",
          )}
        </Typography>
      </Box>
    );
  };

  const MyWalletComponent = () => {
    let coinsObj = coins;
    let length = coinsObj.account.length;
    let redactedAddress =
      coinsObj.account.substring(0, 10) +
      "..." +
      coinsObj.account.substring(length - 10, length);
    return (
      <Box
        sx={{
          border: 1,
          borderColor: "grey.accent3",
          borderRadius: "16px",
          minHeight: "292px",
          backgroundColor: "background.paper",
        }}
      >
        <Box sx={{ m: "24px 16px" }}>
          <Box
            sx={{
              mb: "8px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box
              alt="Coins"
              component="img"
              src={getChannelConfig().showCoin ? walletCoinIcon : ABCPointIcon}
              sx={{
                height: "40px",
                width: "40px",
                mr: "8px",
              }}
            />
            <Typography variant="subtitle">
              {getChannelConfig().showCoin ? t("ABC Coin") : t("ABC Point")}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
              mb: "8px",
            }}
          >
            <Typography variant="h5" sx={{ mb: "4px" }}>
              {coinFormatter(coins.balance)}
            </Typography>
            <Typography variant="body2" color="grey.accent2">
              {t("Balance")}
            </Typography>
          </Box>
          <Box>
            <Typography
              variant="body2"
              color="grey.accent2"
              sx={{
                mb: "8px",
              }}
            >
              {t("Address")}
            </Typography>
            <Box
              sx={{
                backgroundColor: "grey.accent4",
                mb: "16px",
                minHeight: "40px",
                borderRadius: "24px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography variant="body2" sx={{ m: "8px 16px" }}>
                {phoneNumberSpaceSeparatorFormatter(user.mobileNumber)}
              </Typography>
            </Box>
            <Box
              sx={{
                backgroundColor: "grey.accent4",
                minHeight: "40px",
                borderRadius: "24px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography variant="body2" sx={{ m: "8px 16px" }}>
                {redactedAddress}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-evenly",
                alignItems: "center",
                marginTop: "24px",
              }}
            >
              {enableP2P && (
                <IconButton
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100px",
                    padding: "4px 8px",
                  }}
                  onClick={() =>
                    handleQuickActionClick(
                      "/transfer",
                      "",
                      QUICK_ACTION_TYPE.P2P,
                    )
                  }
                >
                  <Box component="img" src={SendLogo} sx={{ width: "48px" }} />
                  <Typography
                    variant="subtitle"
                    sx={{
                      color: "#333333",
                      marginTop: "6px",
                    }}
                  >
                    {t("Send")}
                  </Typography>
                </IconButton>
              )}
              {enableRelease_1_3_0 && (
                <IconButton
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100px",
                    padding: "4px 8px",
                  }}
                  onClick={() =>
                    handleQuickActionClick(
                      "/wallet/partner-convert-in",
                      "",
                      QUICK_ACTION_TYPE.P2C,
                    )
                  }
                  id="btn-quick-action-p2c-myWallet"
                  data-testid="btn-quick-action-p2c-myWallet"
                >
                  <Box
                    component="img"
                    src={ConvertIcon}
                    sx={{ width: "48px" }}
                  />
                  <Typography
                    variant="subtitle"
                    sx={{
                      color: "#333333",
                      marginTop: "6px",
                    }}
                  >
                    {t("Convert")}
                  </Typography>
                </IconButton>
              )}
              {enableF2C && (
                <IconButton
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100px",
                    padding: "4px 8px",
                  }}
                  onClick={() =>
                    handleQuickActionClick(
                      "/wallet/buy-coins",
                      "",
                      QUICK_ACTION_TYPE.F2C,
                    )
                  }
                  id="btn-quick-action-f2c-myWallet"
                  data-testid="btn-quick-action-f2c-myWallet"
                >
                  <Box component="img" src={BuyIcon} sx={{ width: "48px" }} />
                  <Typography
                    variant="subtitle"
                    sx={{
                      color: "#333333",
                      marginTop: "6px",
                    }}
                  >
                    {t("Buy")}
                  </Typography>
                </IconButton>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  const renderMyWallet = () => {
    if (status === CoinsStatus.LOADING) {
      return <MyWalletSkeleton id="wallet-loading" />;
    } else if (status === CoinsStatus.ERROR) {
      return (
        <Box id="wallet-error" data-testid="wallet-error">
          {MyWalletError()}
        </Box>
      );
    } else if (status === CoinsStatus.EMPTY) {
      return (
        <Box id="wallet-empty" data-testid="wallet-empty">
          {MyWalletComponent()}
        </Box>
      );
    } else if (coins && status !== CoinsStatus.INITIAL) {
      return (
        <Box id="wallet-component" data-testid="wallet-component">
          {MyWalletComponent()}
        </Box>
      );
    }
    return null;
  };

  return (
    <>
      <Box sx={{ m: "0px 20px" }} data-testid="my-wallet">
        {renderMyWallet()}
      </Box>
      {enableConvertLoyaltyPoint && (
        <>
          <ConvertButton id="convert-btn" />
          {enableRelease_1_3_0 && <PartnerConvert />}
        </>
      )}
      {coins && status !== CoinsStatus.INITIAL && (
        <TransactionHistoryList id={user.sub} coinAccount={coins.account} />
      )}
    </>
  );
};

export default MyWallet;
