import queryString from "query-string";
import AuthContextService from "contexts/authContext/AuthContextServices";
import { apiEndpoint, authConfig } from "../../../config";
import { getCurrentLanguage } from "utils/getLanguage";

function dec2hex(dec) {
  return dec.toString(16).padStart(2, "0");
}

function generateRandomString(len) {
  var arr = new Uint8Array((len || 40) / 2);
  window.crypto.getRandomValues(arr);
  return Array.from(arr, dec2hex).join("");
}

export const getTrueIdAuthRedirectUrl = () => {
  const lang = getCurrentLanguage();
  const randomString = generateRandomString(10);
  AuthContextService.setLocalStorageTrueRandomState(randomString);
  return queryString.stringifyUrl({
    url: apiEndpoint.authTrueId,
    query: {
      client_id: authConfig.trueClientId,
      redirect_uri: `${window.location.origin}/verify-link/trueid?lang=${lang}`,
      state: randomString,
      lang,
    },
  });
};
