import { Box, Button, Card, Container, Typography } from "@mui/material";
import { styled } from "@mui/system";
import Logo from "../../assets/icons/Logo.svg";
import MobileStar from "../../assets/images/OnBoarding/mobile_star.png";
import VoucherBanner from "../../assets/images/OnBoarding/voucher_banner.png";
import NFTBanner from "../../assets/images/OnBoarding/nft_banner.png";
import Coin1 from "../../assets/images/OnBoarding/Coin1.png";
import Coin2 from "../../assets/images/OnBoarding/Coin2.png";
import Coin3 from "../../assets/images/OnBoarding/Coin3.png";
import Coin4 from "../../assets/images/OnBoarding/Coin4.png";
import Coin5 from "../../assets/images/OnBoarding/Coin5.png";
import CTAConversionIcon from "../../assets/images/OnBoarding/CTA_conversion_icon.svg";
import CTARedemptionIcon from "../../assets/images/OnBoarding/CTA_redemption_icon.svg";
import CTATransferIcon from "../../assets/images/OnBoarding/CTA_transfer_icon.svg";
import parser from "html-react-parser";
const IBMPlexTypography = styled(Typography)`
  font-family: "IBM Plex Sans Thai";
`;

export const IBMPlexBtn = styled(Button)`
  font-family: "IBM Plex Sans Thai";
  height: 48px;
  padding: 12px 32px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  border-radius: 24px;
  text-transform: uppercase;
`;

const Header2 = styled(IBMPlexTypography)`
  font-weight: 600;
  font-size: 64px;
  /* line-height: 104px; */
  background: linear-gradient(90deg, #e53109 0%, #ffca4b 63.68%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  @media (max-width: 900px) {
    font-size: 48px;
    line-height: 80px;
  }
`;

const Header4 = styled(IBMPlexTypography)`
  font-weight: 400;
  font-size: 32px;
  line-height: 48px;
  display: flex;
  align-items: center;
  color: #6f737e;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  @media (max-width: 900px) {
    font-size: 20px;
    line-height: 32px;
  }
`;

const AbcCard = ({ icon, title, subTitle, desc, index }) => {
  return (
    <Card
      sx={{
        borderRadius: 5,
        padding: 5,
        textAlign: "center",
        minWidth: "360px",
        width: "360px",
        height: "388px",
        margin: "auto",
      }}
    >
      <Box
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Box data-testid={`img-card-${index}`} component="img" src={icon} />
      </Box>
      <Box
        sx={{ display: "flex", flexDirection: "column", gap: 2, marginTop: 3 }}
      >
        <Typography
          data-testid={`header5-card-${index}`}
          variant="h5"
          sx={{ fontWeight: "600" }}
        >
          {title}
        </Typography>
        <Typography
          variant="h6"
          data-testid={`header6-card-${index}`}
          sx={{ fontWeight: "600", color: "primary.main" }}
        >
          {subTitle}
        </Typography>
        <Typography
          data-testid={`body1-card-${index}`}
          variant="body1"
          sx={{ color: "#6F737E" }}
        >
          {desc}
        </Typography>
      </Box>
    </Card>
  );
};

const EarthOnBoarding = ({ t, handleSignup }) => {
  const featureCardList = [
    {
      icon: CTATransferIcon,
      title: t("Coin Transfer"),
      subTitle: t("Wherever, Whenever to Everyone"),
      desc: t(
        "Send coins to Wherever, Whenever to Everyone with no expire date.",
      ),
    },
    {
      icon: CTAConversionIcon,
      title: t("Coin Conversion"),
      subTitle: t("Better Together, just Connect & Convert"),
      desc: t("Enable to aggregate different loyalty points into ABC Coin."),
    },
    {
      icon: CTARedemptionIcon,
      title: t("Coin Redemption"),
      subTitle: t("More Coins, More Enjoy"),
      desc: t("Using ABC Coin to redeem the variety of lifestyle vouchers."),
    },
  ];
  const footerMenuList = [
    {
      title: t("ABC Coin"),
      url: "https://abccoin.digital/",
    },
    {
      title: t("Partnership"),
      email: "abc-business@ascendcorp.com",
    },
    {
      title: t("Terms and policies"),
      url: "https://policies.ascendbit.net/",
    },
    {
      title: t("Contact us"),
      subTitle: "wallet.support@ascendbit.net",
      email: "wallet.support@ascendbit.net",
    },
  ];
  return (
    <Box data-testid="earth-comp">
      {/* Coin Decorate Section */}
      <Box
        component="img"
        sx={{
          position: "absolute",
          top: "530px",
          display: {
            xs: "none",
            lg: "block",
          },
          left: {
            md: "20%",
            lg: "20%",
            xl: "30%",
          },
        }}
        src={Coin1}
      />
      <Box
        component="img"
        sx={{
          position: "absolute",
          top: "840px",
          display: {
            xs: "none",
            lg: "block",
          },
          right: {
            md: "5%",
            lg: "10%",
            xl: "16%",
          },
        }}
        src={Coin2}
      />
      <Box
        component="img"
        sx={{
          position: "absolute",
          top: {
            md: "1890px",
            lg: "1480px",
            xl: "1480px",
          },
          display: {
            xs: "none",
            lg: "block",
          },
          left: {
            md: "30%",
            lg: "40%",
            xl: "43%",
          },
        }}
        src={Coin3}
      />
      <Box
        component="img"
        sx={{
          position: "absolute",
          top: {
            md: "2424px",
            lg: "2038px",
            xl: "2038px",
          },
          display: {
            xs: "none",
            lg: "block",
          },
          left: {
            md: "15%",
            lg: "20%",
            xl: "30%",
          },
        }}
        src={Coin4}
      />
      <Box
        component="img"
        sx={{
          position: "absolute",
          top: {
            md: "3000px",
            lg: "2582px",
            xl: "2582px",
          },
          display: {
            xs: "none",
            lg: "block",
          },
          right: {
            md: "5%",
            lg: "10%",
            xl: "16%",
          },
        }}
        src={Coin5}
      />
      {/* Section 1 */}
      <Container
        sx={{
          height: {
            xs: "auto",
            lg: "648px",
          },
          display: "grid",
          gridTemplateAreas: {
            xs: `
            "content"
            "img"
            "start"
          `,
            lg: `
              "content img"
              "start   img"
            `,
          },
          gridTemplateRows: {
            xs: "auto auto auto",
            lg: "1fr 1fr",
          },
          gridTemplateColumns: "1fr",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: {
              xs: "grid",
              lg: "block",
            },
            justifyContent: "center",
            gridArea: "content",
            marginTop: "auto",
          }}
        >
          <Header2
            data-testid="header2-1"
            sx={{
              width: {
                xs: "343px",
                lg: "480px",
              },
              margin: {
                xs: "auto",
                lg: 0,
              },
              textAlign: {
                xs: "center",
                lg: "left",
              },
              marginTop: {
                xs: "48px",
                lg: 0,
              },
            }}
          >
            {t("ABC Wallet")}
          </Header2>
          <Header4
            data-testid="header4-1"
            sx={{
              fontSize: {
                xs: "20px",
                lg: "32px",
              },
              margin: {
                xs: "auto",
                lg: 0,
              },
              textAlign: {
                xs: "center",
                lg: "left",
              },
            }}
          >
            {parser(
              t(
                "With one app, enjoy your digital life with ready-to-use utility tokens.",
              ),
            )}
          </Header4>
        </Box>
        <Box
          sx={{
            gridArea: "start",
            marginBottom: {
              xs: 6,
              lg: "auto",
            },
            marginTop: {
              xs: 4,
              lg: 7,
            },
            display: {
              xs: "flex",
              lg: "block",
            },
            justifyContent: "center",
          }}
        >
          <IBMPlexBtn
            data-testid="login-btn"
            variant="contained"
            onClick={handleSignup}
          >
            {t("LET’S START!")}
          </IBMPlexBtn>
        </Box>

        <Box
          component="img"
          sx={{
            width: "100%",
            gridArea: "img",
            margin: {
              xs: "auto",
              sm: "auto",
              md: "auto",
              lg: 0,
              xl: 0,
            },
          }}
          src={MobileStar}
        />
      </Container>
      {/* Section 2 */}
      <Box
        sx={{
          width: "100%",
          padding: {
            xs: "48px 0",
            md: "100px 0",
          },
          background:
            "linear-gradient(73.47deg, rgba(111, 255, 246, 0.04) 11.45%, rgba(229, 88, 9, 0.06) 61.1%, rgba(255, 206, 88, 0.09) 88.55%)",
        }}
      >
        <Container>
          <Box
            sx={{
              display: {
                xs: "grid",
                lg: "flex",
              },
              alignContent: "end",
              gridTemplateColumns: {
                xs: "auto",
                lg: "auto auto",
              },
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Header2
              data-testid="header2-2"
              sx={{
                width: "281px",
                margin: {
                  xs: "auto",
                  lg: 0,
                },
                textAlign: {
                  xs: "center",
                  lg: "left",
                },
              }}
            >
              {t("ABC Coin")}
            </Header2>
            <IBMPlexTypography
              data-testid="txt-1"
              sx={{
                marginLeft: {
                  xs: 0,
                  lg: 2,
                },
                fontSize: {
                  xs: "32px",
                  lg: "48px",
                },
                position: {
                  xs: "inherit",
                  lg: "relative",
                },
                top: "4px",
              }}
            >
              {t("Easy Everyday Coin")}
            </IBMPlexTypography>
          </Box>
          <Header4
            data-testid="header4-2"
            justifyContent="center"
            sx={{ textAlign: { xs: "center", md: "left" } }}
          >
            {t("An easy coin for everyone & everyday")}
          </Header4>
          <Box
            sx={{
              marginTop: {
                xs: 4,
                lg: 7,
              },
              display: "grid",
              gridTemplateColumns: {
                xs: "1fr",
                lg: "1fr 1fr 1fr",
              },
              justifyContent: "space-between",
              gap: 2,
            }}
          >
            {featureCardList.map((val, index) => {
              return (
                <AbcCard key={`feature-card-${index}`} {...val} index={index} />
              );
            })}
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: {
                xs: "center",
                lg: "end",
              },
            }}
          >
            <IBMPlexBtn
              data-testid="learn-more-btn"
              href="https://abccoin.digital/"
              target="_blank"
              variant="contained"
              sx={{ marginTop: 7 }}
            >
              {t("LEARN MORE")}
            </IBMPlexBtn>
          </Box>
        </Container>
      </Box>
      {/* Section 3 */}
      <Container
        sx={{
          height: {
            xs: "auto",
            lg: "648px",
          },
        }}
      >
        <Box
          sx={{
            height: "100%",
            display: "grid",
            gridTemplateColumns: {
              xs: "1fr",
              lg: "1fr 648px",
            },
          }}
        >
          <Box>
            <Header2
              data-testid="header2-3"
              sx={{
                width: {
                  xs: "348px",
                  lg: "477px",
                },
                margin: {
                  xs: "48px auto auto auto",
                  lg: "100px 0 0 0",
                },
                textAlign: {
                  xs: "center",
                  lg: "left",
                },
              }}
            >
              {t("Voucher")}
            </Header2>
            <Header4
              data-testid="header4-3"
              sx={{
                display: {
                  xs: "flex",
                  lg: "block",
                },
                textAlign: { xs: "center", md: "left" },
                justifyContent: "center",
              }}
            >
              {t(
                "Make your everyday special with the great deals of vouchers here.",
              )}
            </Header4>
          </Box>
          <Box
            component="img"
            sx={{
              width: "100%",
              margin: {
                xs: "auto",
                lg: 0,
              },
            }}
            src={VoucherBanner}
          />
        </Box>
      </Container>
      {/* Section 4 */}
      <Box
        sx={{
          background:
            "linear-gradient(89.99deg, rgba(50, 70, 255, 0.06) 0.01%, rgba(53, 255, 61, 0.02) 46.95%, rgba(0, 194, 255, 0.07) 114.71%);",
        }}
      >
        <Container
          sx={{
            display: "grid",
            gridTemplateColumns: {
              xs: "1fr",
              lg: "648px 1fr",
            },
            gridTemplateRows: {
              xs: "auto 1fr",
              lg: "1fr",
            },
            gridTemplateAreas: {
              xs: `
                  "content"
                  "img"
                `,
              lg: `"img content"`,
            },
          }}
        >
          <Box
            component="img"
            sx={{
              gridArea: "img",
              width: "100%",
              margin: {
                xs: "auto",
                lg: 0,
              },
            }}
            src={NFTBanner}
          />
          <Box sx={{ gridArea: "content" }}>
            <Header2
              data-testid="header2-4"
              sx={{
                width: {
                  xs: "140px",
                  lg: "477px",
                },
                margin: {
                  xs: "48px auto auto auto",
                  lg: "100px 0 0 0",
                },
                textAlign: {
                  xs: "center",
                  lg: "left",
                },
              }}
            >
              {t("NFT")}
            </Header2>

            <Header4
              data-testid="header4-4"
              sx={{ justifyContent: { xs: "center", lg: "start" } }}
            >
              {t("Next-Gen! Collection")}
            </Header4>
            <Typography
              data-testid="header6-1"
              variant="h6"
              sx={{
                fontWeight: "600",
                color: "primary.main",
                textAlign: {
                  xs: "center",
                  lg: "left",
                },
              }}
            >
              {t("Simply Save - Show - Swap in one place.")}
            </Typography>
          </Box>
        </Container>
      </Box>
      {/* Section 5 */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "80px 0",
        }}
      >
        <IBMPlexBtn
          data-testid="letstart-btn"
          sx={{
            width: { xs: "100%", md: "auto" },
            margin: { xs: "0 16px", md: 0 },
            padding: {
              xs: 0,
              md: "0 100px",
            },
          }}
          variant="contained"
          onClick={handleSignup}
        >
          {t("LET’S START!")}
        </IBMPlexBtn>
      </Box>
      {/* Section 6 */}
      <Box component="footer" sx={{ backgroundColor: "#141312" }}>
        <Container
          sx={{
            paddingTop: {
              xs: "48px",
              md: "100px",
            },
            paddingBottom: {
              xs: "48px",
              md: "100px",
            },
            display: "grid",
            gridTemplateAreas: {
              xs: `
                "img"
                "content"
              `,
              md: `"img content"`,
            },
            gridTemplateColumns: {
              xs: "1fr",
              md: "1fr auto",
            },
          }}
        >
          <Box
            component="img"
            src={Logo}
            sx={{ gridArea: "img", height: "40px", marginBottom: 3 }}
          />
          <Box
            sx={{
              gridArea: "content",
              display: "flex",
              flexDirection: "column",
              gap: 3,
              textAlign: {
                xs: "end",
                md: "start",
              },
            }}
          >
            {footerMenuList.map(({ title, subTitle, url, email }, index) => {
              return (
                <Box key={`footer-item-${index}`}>
                  <IBMPlexTypography
                    data-testid={`footer-menu-${index}`}
                    sx={{ fontSize: 16, color: "#C5C8CF", cursor: "pointer" }}
                    onClick={() => {
                      if (email) {
                        window.location = `mailto:${email}`;
                      } else {
                        window.open(url, "_blank");
                      }
                    }}
                  >
                    {title}
                    {subTitle && (
                      <IBMPlexTypography
                        as="span"
                        sx={{
                          display: "block",
                          fontSize: 16,
                          color: "#C5C8CF",
                        }}
                      >
                        {subTitle}
                      </IBMPlexTypography>
                    )}
                  </IBMPlexTypography>
                </Box>
              );
            })}
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default EarthOnBoarding;
