import React, { useEffect } from "react";
import useTranslation from "../../../../hooks/useTranslation";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import toast, { Toaster, ToastBar } from "react-hot-toast";
import { Box, Avatar, Typography, ButtonBase, Skeleton } from "@mui/material";
import { ReactComponent as CopyIcon } from "../../../../assets/icons/copy.svg";
import useNavigationHook from "../../../../hooks/useNavigation";
import { coinFormatter, toDecimal } from "../../../../utils/coinFormatter";
import { formatDateTime } from "../../../../utils/dateTimeFormatter";
import {
  getTransactionDetail,
  CURRENCY,
  KIND_TYPE,
} from "../transactionHistoryList/transactionHistoryCard/TransactionHistoryCard";
import {
  useDispatch,
  useSelector,
} from "../../../../redux/store/configureStore";
import {
  fetchTransactionDetail,
  resetTransactionDetail,
  status as fetchTransactionDetailStatus,
} from "../../redux/slices/transactionDetail/transactionDetail";
import { getValueByLanguage } from "utils/getLanguage";
import { getFeatureFlags } from "../../../../config";

const TransactionDetails = () => {
  const dispatch = useDispatch();
  const setActiveMenu = useNavigationHook();
  const location = useLocation();

  const { enableTeslaSprint16_08_09_2021 } = getFeatureFlags();

  const { detail, status } = useSelector(state => state.transactionDetail);
  const { t } = useTranslation("translation", {
    keyPrefix: "transactionDetails",
  });

  const toaster = () => {
    toast.remove();
    toast(t("Transaction ID Copied"), {
      style: {
        textAlign: "start",
        padding: "12px, 16px, 12px, 16px",
        color: "#FFFFFF",
        backgroundColor: "#141312",
        width: "100%",
        borderRadius: "16px",
      },
    });
  };

  useEffect(() => {
    setActiveMenu(true);
  }, [setActiveMenu]);

  useEffect(() => {
    const transactionHash = location.pathname.split("/")[3];
    dispatch(
      fetchTransactionDetail({
        transactionHash,
      }),
    );

    return () => {
      dispatch(resetTransactionDetail());
    };
  }, [dispatch, location.pathname]);

  // check status here
  if (!detail && status !== fetchTransactionDetailStatus.LOADED) {
    return (
      <Box
        sx={{
          border: 1,
          m: "50px 20px 0px 20px",
          backgroundColor: "background.paper",
          borderRadius: "16px",
          borderColor: "grey.accent3",
          p: "16px 16px 32px 16px",
        }}
      >
        <Skeleton
          data-testid="skl_loading"
          animation="wave"
          height={40}
          width="100%"
        />
        <Skeleton animation="wave" height={40} width="100%" />
        <Skeleton animation="wave" height={40} width="100%" />
        <Skeleton animation="wave" height={40} width="100%" />
        <Skeleton animation="wave" height={40} width="100%" />
      </Box>
    );
  }

  const {
    transactionHash,
    from,
    to,
    amount,
    kind,
    metadata,
    confirmedAt,
    currency,
  } = detail;

  const lang = localStorage.getItem("lang");

  const { title, symbol, icon } = getTransactionDetail(
    t,
    lang,
    kind,
    metadata,
    CURRENCY,
  );

  const labels = [
    {
      id: "date",
      label: t("Date"),
      data: formatDateTime(confirmedAt, "DD MMM YYYY HH:mm"),
    },
    {
      id: "type",
      label: t("Type"),
      data:
        kind === KIND_TYPE.send || kind === KIND_TYPE.receive
          ? t("Peer to peer transfer")
          : title,
    },
    {
      id: "transctionId",
      label: t("Transaction ID"),
      data: transactionHash
        ? `${transactionHash.slice(0, 7)}...${transactionHash.slice(
            transactionHash.length - 7,
            transactionHash.length,
          )}`
        : transactionHash,
    },
    {
      id: "from",
      label: t("From"),
      data: from
        ? `${from.slice(0, 7)}...${from.slice(from.length - 7, from.length)}`
        : from,
    },
  ];

  if (kind === KIND_TYPE.send || kind === KIND_TYPE.receive) {
    labels.push({
      id: "to",
      label: t("To"),
      data: `xxxxxx${metadata?.mobileNumber.slice(6, 10)}`,
    });
  } else {
    labels.push({
      id: "to",
      label: t("To"),
      data: `${to.slice(0, 7)}...${to.slice(to.length - 7, to.length)}`,
    });
  }

  if (kind === KIND_TYPE.swap) {
    if (enableTeslaSprint16_08_09_2021) {
      const { exchangeRate, partner } = metadata;
      labels.push({
        id: "convertFrom",
        label: t("Convert from"),
        data: getValueByLanguage(partner?.name),
      });

      labels.push({
        id: "amountChange",
        label: t("amount_change"),
        data: `-${amount * exchangeRate} ${getValueByLanguage(
          partner?.unitName,
        )}`,
      });
    } else {
      const { exchangeRate, from: metaFrom } = metadata;
      labels.push({
        id: "youSpent",
        label: t("You spent"),
        data: `-${amount * exchangeRate} ${metaFrom}`,
      });
    }
  }

  if (kind === KIND_TYPE.f2c) {
    labels.push({
      id: "sourceOfFund",
      label: t("Source of fund"),
      data: t("TrueMoney Wallet"),
    });
    labels.push({
      id: "sourceAccount",
      label: t("Source account"),
      data: metadata?.providerInfo?.mobileNumber,
    });
    labels.push({
      id: "amountChange",
      label: t("amount_change"),
      data: `-${toDecimal(amount)} ${t("THB")}`,
    });
  }

  return (
    <>
      <Helmet>
        <title>{t("Transaction Detail")}</title>
      </Helmet>
      <Box sx={{ height: 50 }} />
      <Box
        sx={{
          border: 1,
          m: "0px 20px 0px 20px",
          backgroundColor: "background.paper",
          borderRadius: "16px",
          borderColor: "grey.accent3",
          p: "16px 0px 32px 0px",
        }}
        data-testid="transaction-details"
      >
        <Box
          alignItems="center"
          justifyContent="center"
          sx={{
            m: "24px 0 24px 0",
            display: "flex",
            flexDirection: "column",
            flex: 1,
          }}
        >
          <Avatar
            data-testid="container-logo-img"
            sx={{
              border: 1,
              bgcolor: "grey.accent4",
              borderColor: "grey.accent3",
              width: "40px",
              height: "40px",
            }}
          >
            {metadata?.merchant?.logo ? (
              <Box
                component="img"
                src={metadata.merchant.logo}
                sx={{
                  height: "40px",
                  width: "40px",
                }}
              />
            ) : (
              icon
            )}
          </Avatar>
          <Typography
            data-testid="lbl-coins-receive"
            variant="subtitle"
            sx={{ my: "16px" }}
          >
            {`${symbol}${coinFormatter(amount + "")}  ${currency || CURRENCY}`}
          </Typography>
        </Box>
        {labels.map(({ id, label, data }) => {
          return label !== t("Transaction ID") ? (
            <Box
              key={label}
              sx={{
                m: "32px 16px 0 16px",
                display: "flex",
                justifyContent: "space-between",
              }}
              id="toastButton"
            >
              <Typography
                data-testid={`lbl-${id}`}
                variant="body2"
                color="grey.accent2"
              >
                {label}
              </Typography>
              <Typography
                data-testid={`lbl-${id}-value`}
                variant="body2"
                sx={{
                  flexGrow: 1,
                  textAlign: "end",
                }}
              >
                {data}
              </Typography>
            </Box>
          ) : (
            <Box
              key={label}
              sx={{
                m: "32px 16px 0 16px",
                display: "flex",
                justifyContent: "space-between",
              }}
              className="btn"
              data-clipboard-text={transactionHash}
              id="btn_toaster"
              data-testid="btn_toaster"
              onClick={() => toaster()}
            >
              <Typography
                data-testid={`lbl-${id}`}
                variant="body2"
                color="grey.accent2"
                sx={{ pt: 0.75 }}
              >
                {label}
              </Typography>
              <ButtonBase
                data-testid={`btn-${id}`}
                sx={{
                  display: "flex",
                  p: "4px 16px 4px 12px",
                  borderRadius: "16px",
                  backgroundColor: "grey.accent4",
                }}
              >
                <Typography data-testid={`lbl-${id}-value`} variant="body2">
                  {data}
                </Typography>
                <CopyIcon style={{ position: "relative", left: "6px" }} />
              </ButtonBase>
            </Box>
          );
        })}
        <Toaster position="bottom-center" reverseOrder={false}>
          {t => (
            // Custimize default toast
            <ToastBar toast={t}>
              {({ message }) => (
                <>
                  <div>{message}</div>
                </>
              )}
            </ToastBar>
          )}
        </Toaster>
      </Box>
    </>
  );
};

export default TransactionDetails;
