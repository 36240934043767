import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import PointExchangeAPI from "../../../../../apiConfig/PointExchangeAPI";
import { Status } from "../../../../../redux/constant";

const initialState = {
  convertPartnerLink: {},
  status: Status.INITIAL,
  error: null,
};

export const fetchAsyncConvertPartnerLink = createAsyncThunk(
  "convertPartnerLink/fetchAsyncConvertPartnerLink",
  async ({ partnerId, code }, { rejectWithValue }) => {
    try {
      const response = await PointExchangeAPI.post(
        `/partners/${partnerId}/link`,
        {
          code,
        },
      );
      return response.data;
    } catch (err) {
      return rejectWithValue({
        status: err.response.status,
        data: err.response.data,
      });
    }
  },
);

const ConvertPartnerLinkSlice = createSlice({
  name: "convertPartnerLink",
  initialState,
  reducers: {
    resetConvertPartnerLink: state => {
      state.convertPartnerLink = [];
      state.status = Status.INITIAL;
      state.error = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchAsyncConvertPartnerLink.pending, state => {
        state.error = null;
        state.status = Status.LOADING;
      })
      .addCase(fetchAsyncConvertPartnerLink.fulfilled, (state, { payload }) => {
        state.convertPartnerLink = payload;
        state.status = Status.LOADED;
      })
      .addCase(fetchAsyncConvertPartnerLink.rejected, (state, { payload }) => {
        if (payload === undefined) {
          state.status = Status.ERROR;
          return;
        }
        state.error = {
          statusCode: payload.status,
          errorMessage: payload.data,
        };
        state.status = Status.ERROR;
      });
  },
});

export const { resetConvertPartnerLink } = ConvertPartnerLinkSlice.actions;

export default ConvertPartnerLinkSlice.reducer;
