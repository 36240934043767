import React, { useState } from "react";
import { Box, Button, Container, styled, Typography } from "@mui/material";
import SplitTopBottomLayout from "components/SplitTopBottomLayout/SplitTopBottomLayout";
import useTranslation from "hooks/useTranslation";
import OtpField from "components/otpField/OtpField";
import { Helmet } from "react-helmet-async";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffectOnce } from "usehooks-ts";
import useCountDown from "react-countdown-hook";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import utc from "dayjs/plugin/utc";
import { useDispatch, useSelector } from "redux/store/configureStore";
import {
  fetchAsyncSendOTP,
  resetSendOTP,
} from "features/wallet/redux/slices/accountSetting/sendOTP";
import { useEffect } from "react";
import {
  fetchAsyncOtpGenerate,
  resetEditMobileNumber,
} from "features/wallet/redux/slices/accountSetting/editMobileNumber";
import LoadingPrompt from "components/loadingPrompt/LoadingPrompt";
import { Status } from "redux/constant";
import useAuth from "hooks/useAuth";
import { transformMobile } from "./EditMobileNumber";
import Prompt from "components/Prompt/Prompt";

dayjs.extend(duration);
dayjs.extend(utc);

const ResendBtn = styled(Button)(({ theme }) => ({
  fontWeight: "500",
  padding: 0,
  color: theme.palette.background.red,
  fontSize: "16px",
  cursor: "pointer",
  "&:hover": {
    color: "rgb(160, 6, 14)",
  },
}));

const SendOTP = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [otpNo, setOtpNo] = useState("");
  const [isError, setIsError] = useState("");
  const [showOopModal, setShowOopModal] = useState(false);
  const { data } = useSelector(state => state.editMobileNumber);
  const sendOTPState = useSelector(state => state.sendOTP);
  const dispatch = useDispatch();
  const mobileNumber = useLocation().state?.mobileNumber;
  const [otpState, setOtpState] = useState(data);

  const { t } = useTranslation("translation", {
    keyPrefix: "editMobileNumber",
  });
  const initialTime = otpState?.resendValidTime
    ? (dayjs(otpState.resendValidTime).unix() - dayjs().unix()) * 1000
    : 0;
  const [count, { start }] = useCountDown(initialTime, 1000);

  const handleOopModalClose = e => {
    e.preventDefault();
    setShowOopModal(false);
  };

  const handleSubmit = () => {
    setIsError("");
    if (otpNo.length >= 6) {
      dispatch(
        fetchAsyncSendOTP({
          otp: otpNo,
          userid: user.id,
          mobileNumber: transformMobile(mobileNumber),
        }),
      );
    } else {
      setIsError(t("It must be a 6-digit number"));
    }
  };

  const onResendOtp = async e => {
    e.preventDefault();
    setOtpNo("");
    dispatch(fetchAsyncOtpGenerate({ mobileNumber, userid: user.id }));
  };

  const handleChangeMobile = e => {
    e.preventDefault();
    dispatch(resetSendOTP());
    dispatch(resetEditMobileNumber());
    navigate("/settings/account-settings/edit-mobile-number");
  };

  useEffectOnce(() => {
    if (!mobileNumber) {
      navigate("/settings/account-settings");
    } else {
      start();
    }
  });

  useEffect(() => {
    return () => {
      dispatch(resetSendOTP());
      dispatch(resetEditMobileNumber());
    };
  }, []);

  useEffect(() => {
    if (
      sendOTPState.status === Status.LOADED &&
      sendOTPState.data &&
      sendOTPState.data.mobileNumber === transformMobile(mobileNumber)
    ) {
      navigate("/settings/account-settings", {
        state: {
          showEditmobileNumberSuccess: true,
        },
      });
      return;
    }
    if (sendOTPState.status === Status.ERROR && sendOTPState.errorMessage) {
      const { statusCode, message, errors } = sendOTPState.errorMessage;
      if (statusCode === 403 && message === "mobileNumber already in used") {
        setShowOopModal(true);
      }
      if (errors === "invalid_otp") {
        setIsError(t("Invalid code. Please try again."));
      }
      if (errors === "maximum_retry_otp") {
        setIsError(
          t(
            "You have reached the maximum number of code trials. Please request a new code.",
          ),
        );
      }
      if (errors === "otp_expired") {
        setIsError(t("Code expired. Please request a new code. "));
      }
    }
  }, [sendOTPState]);

  useEffect(() => {
    if (!data?.resendValidTime) return;
    if (data.resendValidTime !== otpState.resendValidTime) {
      setOtpState(data);
      start((dayjs(data.resendValidTime).unix() - dayjs().unix()) * 1000);
    }
  }, [data, otpState]);
  return (
    <SplitTopBottomLayout>
      <Helmet>
        <title>{t("Change Mobile Number")}</title>
      </Helmet>
      <Container>
        <Box mt="3rem" mb="3rem" textAlign="center">
          <Typography
            variant="subtitle2"
            sx={{
              fontWeight: 400,
              fontSize: "1.125rem",
            }}
          >
            {t("We sent a 6-digit code to")}
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{
              fontWeight: 400,
              fontSize: "1.125rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "baseline",
            }}
          >
            {mobileNumber}
            <ResendBtn data-testid="change-btn" onClick={handleChangeMobile}>
              {t("Change")}
            </ResendBtn>
          </Typography>
        </Box>
        <Box
          mt="2rem"
          mb="2rem"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          gap="15px"
        >
          <OtpField
            value={otpNo}
            setValue={setOtpNo}
            isError={isError !== ""}
          />
          {isError && (
            <Typography
              component="span"
              variant="subtitle2"
              color="error.main"
              sx={{ fontWeight: "400", display: "block", textAlign: "center" }}
            >
              {isError}
            </Typography>
          )}
        </Box>
        <Box mt="2rem" textAlign="center">
          <Typography
            variant="subtitle2"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "baseline",
              color: "grey.accent2",
              fontWeight: "400",
            }}
          >
            {t("Didn't receive a code?")}
            <>
              <ResendBtn
                data-testid="resend-btn"
                disabled={count > 0}
                onClick={onResendOtp}
                sx={{
                  paddingLeft: 0.5,
                  paddingRight: 0,
                  "&:disabled": { color: "grey.accent2" },
                }}
              >
                {t("Resend Code")}
              </ResendBtn>
              {count > 0 ? (
                <Typography
                  sx={{
                    display: "inline",
                    color: "grey.accent2",
                    fontWeight: "500",
                    marginLeft: 0.5,
                  }}
                >
                  ({dayjs.duration(count).format("mm:ss")})
                </Typography>
              ) : (
                ""
              )}
            </>
          </Typography>
        </Box>
      </Container>
      <Container
        id="btn-container"
        sx={{
          marginTop: 10,
          backgroundColor: "background.paper",
          width: "100%",
          padding: "0 16px 32px 16px",
        }}
      >
        <Button
          id="next-btn"
          data-testid="next-btn"
          variant="contained"
          fullWidth
          sx={{ height: 48, width: "100%", borderRadius: "24px" }}
          onClick={handleSubmit}
        >
          <Typography variant="button" color="background.paper">
            {t("NEXT")}
          </Typography>
        </Button>
      </Container>
      <LoadingPrompt
        isVisible={sendOTPState.status === Status.LOADING}
        title={"Processing"}
        keyPrefix={"editMobileNumber"}
      />
      {showOopModal && (
        <Prompt
          data-testid="oop-modal"
          isVisible
          keyPrefix="editMobileNumber"
          title="Oops!"
          subTitle="Mobile no. already exists.Try another number."
          buttonText="GOT IT"
          onClose={handleOopModalClose}
        />
      )}
    </SplitTopBottomLayout>
  );
};

export default SendOTP;
