import React, { createContext } from "react";

const EarthNavbarContext = createContext();

export const EarthNavbarProvider = ({ children, value }) => {
  return (
    <EarthNavbarContext.Provider value={value}>
      {children}
    </EarthNavbarContext.Provider>
  );
};

export default EarthNavbarContext;
