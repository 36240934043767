import React from "react";
import { Box, Modal } from "@mui/material";
import GenericErrorImg from "../../../../../assets/images/GenericErrorImg.svg";
import { ReactComponent as CloseIcon } from "../../../../../assets/icons/close.svg";

const ViewImageDialog = ({ open, url, onClose }) => {
  return (
    <Modal open={open} data-testid="view-img-modal">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        bgcolor="transparent"
        height="100%"
      >
        <Box position="relative" minWidth="400px" maxWidth="700px">
          <Box
            alt="NFT image"
            component="img"
            src={url}
            width="100%"
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = GenericErrorImg;
            }}
          />
          <Box
            id="btn-close-icon"
            data-testid="btn-close-icon"
            sx={{
              position: "absolute",
              right: "11px",
              top: "11px",
              height: "32px",
              width: "32px",
              boxShadow: 1,
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
            }}
            onClick={onClose}
            bgcolor="#ffffff"
          >
            <CloseIcon height="16px" width="16px" />
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default ViewImageDialog;
