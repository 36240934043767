import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Box, Button, Typography, AppBar } from "@mui/material";
import useNavigationHook from "../../../../hooks/useNavigation";
import PartnerAllYourRewardList from "features/wallet/components/partnerConvertIn/partnerAllYourRewardList/PartnerAllYourRewardList";
import useTranslation from "hooks/useTranslation";
import {
  useDispatch,
  useSelector,
} from "../../../../redux/store/configureStore";
import {
  fetchAsyncPartnerList,
  resetPartnerList,
} from "features/wallet/redux/slices/partnerList/partnerList";
import {
  fetchAsyncLinkedPartner,
  resetLinkedPartner,
} from "features/wallet/redux/slices/linkedPartner/linkedPartner";
import { Status } from "redux/constant";
import { useNavigate } from "react-router-dom";

const PartnerAllYourReward = () => {
  const { t } = useTranslation("translation", {
    keyPrefix: "PartnerConvert",
  });
  const setActiveMenu = useNavigationHook();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { status: partnerListStatus } = useSelector(state => state.partnerList);
  const { status: linkedPartnerStatus } = useSelector(
    state => state.linkedPartner,
  );

  useEffect(() => {
    setActiveMenu(true);
  }, [setActiveMenu]);

  useEffect(() => {
    if (partnerListStatus === Status.INITIAL) {
      dispatch(fetchAsyncPartnerList());
    }
  }, [dispatch, partnerListStatus]);

  useEffect(() => {
    if (linkedPartnerStatus === Status.INITIAL) {
      dispatch(fetchAsyncLinkedPartner());
    }
  }, [dispatch, linkedPartnerStatus]);

  useEffect(() => {
    return () => {
      dispatch(resetPartnerList());
      dispatch(resetLinkedPartner());
    };
  }, [dispatch]);

  const handleOnClickBack = () => {
    navigate("/wallet/partner-convert-in");
  };

  const renderBackButton = () => {
    return (
      <AppBar
        position="fixed"
        sx={{
          top: "auto",
          bottom: 0,
          backgroundColor: "background.paper",
          py: "32px",
          px: "16px",
          boxShadow: 2,
        }}
      >
        <Button
          data-testid="review"
          variant="contained"
          fullWidth
          sx={{ height: 48, borderRadius: "24px" }}
          onClick={handleOnClickBack}
        >
          <Typography variant="button" color="background.paper">
            {t("BackToConvertPointPage")}
          </Typography>
        </Button>
      </AppBar>
    );
  };

  return (
    <>
      <Helmet>
        <title>{t("Your rewards")}</title>
      </Helmet>
      <Box sx={{ mt: "32px", px: "16px" }}>
        <Box sx={{ mb: "32px" }}>
          <PartnerAllYourRewardList />
        </Box>
        <Box sx={{ mt: "120px" }}>{renderBackButton()}</Box>
      </Box>
    </>
  );
};

export default PartnerAllYourReward;
