import { useState, useEffect, useCallback, useMemo } from "react";
import { Status } from "redux/constant";
import { useDispatch, useSelector } from "redux/store/configureStore";
import {
  fetchAsyncConvertPartnerRates,
  resetConvertPartnerRates,
} from "../redux/slices/convertPartnerCoins/convertPartnerRates";

export default function usePartnerRates({ targetId }) {
  const dispatch = useDispatch();
  const { status: partnerListStatus } = useSelector(state => state.partnerList);
  const { linkedPartner, status: linkedPartnerStatus } = useSelector(
    state => state.linkedPartner,
  );
  const [partnersRates, setPartnersRates] = useState({});

  const filteredLinkedPartner = useMemo(
    () =>
      linkedPartner?.filter(
        linkedItem => linkedItem.connected && !linkedItem.social,
      ),
    [linkedPartner],
  );

  const fetchRates = useCallback(
    async ({ source, target }) => {
      try {
        const _rates = await dispatch(
          fetchAsyncConvertPartnerRates({ source, target }),
        );
        setPartnersRates(state => ({
          ...state,
          [source]: _rates.payload,
        }));
      } catch (error) {
        setPartnersRates(state => ({
          ...state,
          [source]: 0,
        }));
      }
    },
    [dispatch],
  );

  useEffect(() => {
    if (
      linkedPartnerStatus === Status.LOADED &&
      partnerListStatus === Status.LOADED
    ) {
      filteredLinkedPartner?.forEach(item => {
        fetchRates({ source: item.providerName, target: targetId });
      });
    }
  }, [
    linkedPartnerStatus,
    partnerListStatus,
    filteredLinkedPartner,
    targetId,
    fetchRates,
  ]);

  useEffect(() => {
    return () => {
      dispatch(resetConvertPartnerRates());
    };
  }, [dispatch]);

  return partnersRates;
}
