import { Box, Typography, Skeleton } from "@mui/material";
import { styled } from "@mui/system";
import { getChannelConfig } from "config";
import { Status } from "redux/constant";
import { coinFormatter } from "utils/coinFormatter";
import Coins from "assets/icons/coins.svg";
import ABCPointIcon from "assets/icons/ABCPoint.svg";

const coinsUnit = getChannelConfig().showCoin ? "ABC" : "ABCP";

export const DetailLine = styled(Box)`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
`;

const CoinsBalance = ({
  coinsStatus,
  coinsBalance,
  totalBalanceText,
  style,
}) => {
  const renderBalance = (status, balance) => {
    if (status === Status.LOADING) {
      return (
        <Skeleton
          animation="wave"
          width="50px"
          data-testid="balance-skeleton"
        />
      );
    } else if (
      status === Status.LOADED &&
      typeof balance !== "undefined" &&
      balance !== null
    ) {
      return (
        <Typography id="lbl_balance-coinsBalance" variant="subtitle">
          {coinFormatter(balance.toString())} {coinsUnit}
        </Typography>
      );
    }
    return null;
  };

  return (
    <DetailLine {...style} data-testid="coins-balance">
      <Typography
        id="lbl_balanceTitle-coinsBalance"
        variant="body2"
        sx={{ color: "grey.accent2" }}
      >
        {totalBalanceText}
      </Typography>
      <Box sx={{ display: "flex" }}>
        <Box
          id="img_coins-coinsBalance"
          alt="Coins-icon"
          component="img"
          src={getChannelConfig().showCoin ? Coins : ABCPointIcon}
          sx={{
            objectFit: "cover",
            height: "24px",
            width: "24px",
            mr: "8px",
          }}
        />
        {renderBalance(coinsStatus, coinsBalance)}
      </Box>
    </DetailLine>
  );
};

export default CoinsBalance;
