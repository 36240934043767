import { Box } from "@mui/material";
import React from "react";

const SplitTopBottomLayout = ({ children }) => {
  return (
    <Box
      sx={{
        height: "calc(100vh - 60px)",
        display: "grid",
        gridTemplateColumns: "1fr",
        gridTemplateRows: {
          xs: "1fr auto",
          md: "auto 1fr",
        },
      }}
    >
      {children}
    </Box>
  );
};

export default SplitTopBottomLayout;
