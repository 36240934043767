import { combineReducers } from "redux";
import termsReducers from "../slices/terms/Terms";
import ConsentReducers from "../slices/consent/Consent";
import SaveConsentReducers from "../slices/consent/SaveConsent";
import dealsReducers from "../../features/deals/redux/slices/deals/Deals";
import merchantsReducers from "../../features/deals/redux/slices/deals/Merchants";
import dealsSearchReducers from "../../features/deals/redux/slices/deals/DealsSearch";
import MerchantDealsReducers from "../../features/deals/redux/slices/deals/MerchantDeals";
import CoinsReducers from "../../features/wallet/redux/slices/coins/Coins";
import TransactionHistoryReducers from "../../features/wallet/redux/slices/transactionHistory/TransactionHistory";
import vouchersReducers from "../../features/wallet/redux/slices/vouchers/vouchers";
import redeemReducers from "../../features/deals/redux/slices/deals/Redeem";
import PartnerRatesReducers from "../../features/wallet/redux/slices/partnerRates/PartnerRates";
import nftsReducers from "../../features/wallet/redux/slices/nfts/Nfts";
import convertPointsReducers from "../../features/wallet/redux/slices/convertPoints/ConvertPoints";
import truePointsBalanceReducers from "../../features/wallet/redux/slices/truePointsBalance/TruePointsBalance";
import nftMyCollectionReducers from "../../features/wallet/redux/slices/nftMyCollection/NftMyCollection";
import nftMyTokenReducers from "../../features/wallet/redux/slices/nftMyCollection/NftMyToken";
import userProfileReducers from "../../features/wallet/redux/slices/userProfile/userProfile";
import nftCheckMobileNumberReducers from "../../features/wallet/redux/slices/nftCheckMobileNumber/NftCheckMobileNumber";
import nftMyCollectionSendnReducers from "../../features/wallet/redux/slices/nftMyCollectionSend/NftMyCollectionSend";
import transferCoinReducers from "../../features/wallet/redux/slices/transferCoin/TransferCoin";
import trueIdLoginReducers from "../../features/wallet/redux/slices/trueIdLogin/trueIdLogin";
import partnerListReducers from "../../features/wallet/redux/slices/partnerList/partnerList";
import transactionDetailReducers from "../../features/wallet/redux/slices/transactionDetail/transactionDetail";
import linkedPartnerReducers from "../../features/wallet/redux/slices/linkedPartner/linkedPartner";
import convertPartnerDetailReducers from "../../features/wallet/redux/slices/convertPartnerCoins/convertPartnerDetail";
import convertPartnerBalanceReducers from "../../features/wallet/redux/slices/convertPartnerCoins/convertPartnerBalance";
import convertPartnerRatesReducers from "../../features/wallet/redux/slices/convertPartnerCoins/convertPartnerRates";
import convertPartnerSwapReducers from "../../features/wallet/redux/slices/convertPartnerCoins/convertPartnerSwap";
import convertPartnerRedirectAuthReducers from "../../features/wallet/redux/slices/convertPartnerCoins/convertPartnerRedirectAuth";
import convertPartnerLinkReducers from "../../features/wallet/redux/slices/convertPartnerCoins/convertPartnerLink";
import keycloakAccountReducers from "features/wallet/redux/slices/keycloak/keycloakAccount";
import keycloakLinkedAccountReducers from "features/wallet/redux/slices/keycloak/keycloakLinkedAccount";
import uploadProfilePhotoReducers from "features/wallet/redux/slices/profilePhotoUpload/profilePhotoUpload";
import userProfilePhotoReducers from "features/wallet/redux/slices/userProfilePhoto/userProfilePhoto";
import unlinkPartnerReducers from "features/wallet/redux/slices/unlinkPartner/unlinkPartner";
import convertF2CRatesReducers from "features/wallet/redux/slices/convertF2C/convertF2CRates";
import convertF2CSendOtpReducers from "features/wallet/redux/slices/convertF2C/convertF2CSendOtp";
import convertF2CCheckOtpReducers from "features/wallet/redux/slices/convertF2C/convertF2CCheckOtp";
import convertF2CCreateTransactionReducers from "features/wallet/redux/slices/convertF2C/convertF2CCreateTransaction";
import editUsernameReducers from "features/wallet/redux/slices/accountSetting/editUsername";
import nftMyCollectionBridgeReducers from "features/wallet/redux/slices/nftMyCollectionBridge/nftMyCollectionBridge";
import unlinkedAccountReducers from "features/wallet/redux/slices/accountSetting/unlinkedAccount";
import nftMintingReducers from "features/wallet/redux/slices/nftMinting/nftMinting";
import editMobileNumberReducers from "features/wallet/redux/slices/accountSetting/editMobileNumber";
import sendOTPReducers from "features/wallet/redux/slices/accountSetting/sendOTP";
import notificationReducers from "features/wallet/redux/slices/accountSetting/notification";
const rootReducer = () =>
  combineReducers({
    terms: termsReducers,
    consent: ConsentReducers,
    saveConsent: SaveConsentReducers,
    deals: dealsReducers,
    merchants: merchantsReducers,
    dealsSearch: dealsSearchReducers,
    merchantDeals: MerchantDealsReducers,
    coins: CoinsReducers,
    transactionHistory: TransactionHistoryReducers,
    vouchers: vouchersReducers,
    redeem: redeemReducers,
    rates: PartnerRatesReducers,
    nfts: nftsReducers,
    convertPoints: convertPointsReducers,
    truePointsBalance: truePointsBalanceReducers,
    nftMyCollection: nftMyCollectionReducers,
    nftMyToken: nftMyTokenReducers,
    userProfile: userProfileReducers,
    nftCheckMobileNumber: nftCheckMobileNumberReducers,
    nftMyCollectionSend: nftMyCollectionSendnReducers,
    nftMyCollectionBridge: nftMyCollectionBridgeReducers,
    nftMinting: nftMintingReducers,
    transferCoin: transferCoinReducers,
    trueIdLogin: trueIdLoginReducers,
    partnerList: partnerListReducers,
    transactionDetail: transactionDetailReducers,
    linkedPartner: linkedPartnerReducers,
    convertPartnerDetail: convertPartnerDetailReducers,
    convertPartnerBalance: convertPartnerBalanceReducers,
    convertPartnerRates: convertPartnerRatesReducers,
    convertPartnerSwap: convertPartnerSwapReducers,
    convertPartnerRedirectAuth: convertPartnerRedirectAuthReducers,
    convertPartnerLink: convertPartnerLinkReducers,
    keycloakAccount: keycloakAccountReducers,
    keycloakLinkedAccount: keycloakLinkedAccountReducers,
    uploadProfilePhoto: uploadProfilePhotoReducers,
    userProfilePhoto: userProfilePhotoReducers,
    unlinkPartner: unlinkPartnerReducers,
    convertF2CRates: convertF2CRatesReducers,
    convertF2CSendOtp: convertF2CSendOtpReducers,
    convertF2CCheckOtp: convertF2CCheckOtpReducers,
    convertF2CCreateTransaction: convertF2CCreateTransactionReducers,
    editUsername: editUsernameReducers,
    unlinkedAccount: unlinkedAccountReducers,
    editMobileNumber: editMobileNumberReducers,
    sendOTP: sendOTPReducers,
    notification: notificationReducers,
  });

export default rootReducer;
