import React, { createContext } from "react";

const DropdownLanguageContext = createContext();

export const DropdownLanguageProvider = ({ children, value }) => {
  return (
    <DropdownLanguageContext.Provider value={value}>
      {children}
    </DropdownLanguageContext.Provider>
  );
};

export default DropdownLanguageContext;
