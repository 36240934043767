export const saveWithToken = async (url, tokenId, imgColor, imgFileType) => {
  const image = await loadImage(url + `?timestamp=${new Date().getTime()}`);
  const fileName = transformFileName(image.src);
  const canvas = document.createElement("canvas");

  const standardWidth =
    (image.height > image.width ? image.width : image.height) * 0.055;
  canvas.height = image.height + standardWidth * 3;
  canvas.width = image.width + standardWidth * 2;
  const ctx = canvas.getContext("2d");

  //draw background
  ctx.fillStyle = "#ffffff";
  ctx.fillRect(0, 0, canvas.width, canvas.height);
  const middleColor = rgb2hex(`rgb(${imgColor.join(",")})`);
  const darkColor = shadeColor(rgb2hex(`rgb(${imgColor.join(",")})`), -40);

  //draw text
  textGradient(
    ctx,
    standardWidth,
    canvas.height - standardWidth * 2 * 0.375,
    standardWidth + image.width * (image.width > image.height ? 0.125 : 0.175),
    canvas.height - standardWidth * 2 * 0.375,
    darkColor,
    middleColor,
    middleColor,
    darkColor,
    `Token ID #${transformTokenId(tokenId)}`,
  );

  //draw round img
  roundImg(
    ctx,
    image,
    standardWidth,
    standardWidth,
    image.width,
    image.height,
    16,
  );

  saveImg(fileName, imgFileType, canvas);
};

export const saveImgOnly = async (url, imgFileType) => {
  const image = await loadImage(url + `?timestamp=${new Date().getTime()}`);
  const fileName = transformFileName(image.src);
  const canvas = document.createElement("canvas");
  canvas.height = image.height;
  canvas.width = image.width;
  const ctx = canvas.getContext("2d");
  ctx.drawImage(image, 0, 0);
  saveImg(fileName, imgFileType, canvas);
};

const roundImg = (ctx, image, x, y, width, height, radius) => {
  radius = { tl: radius, tr: radius, br: radius, bl: radius };
  ctx.beginPath();
  ctx.moveTo(x + radius.tl, y);
  ctx.lineTo(x + width - radius.tr, y);
  ctx.quadraticCurveTo(x + width, y, x + width, y + radius.tr);
  ctx.lineTo(x + width, y + height - radius.br);
  ctx.quadraticCurveTo(
    x + width,
    y + height,
    x + width - radius.br,
    y + height,
  );
  ctx.lineTo(x + radius.bl, y + height);
  ctx.quadraticCurveTo(x, y + height, x, y + height - radius.bl);
  ctx.lineTo(x, y + radius.tl);
  ctx.quadraticCurveTo(x, y, x + radius.tl, y);
  ctx.closePath();
  ctx.clip();

  ctx.drawImage(image, x, y);
};

const textGradient = (
  ctx,
  x0,
  y0,
  x1,
  y1,
  color0,
  color1,
  color2,
  color3,
  text,
) => {
  ctx.font = `${x0 * 2 * 0.25}px Arial`;
  var gradient = ctx.createLinearGradient(x0, y0, x1, y1);
  gradient.addColorStop(0, color0);
  gradient.addColorStop(0.33, color1);
  gradient.addColorStop(0.66, color2);
  gradient.addColorStop(1, color3);
  ctx.fillStyle = gradient;
  ctx.fillText(text, x0, y0);
};

const saveImg = (fileName, imgFileType, canvas) => {
  const link = document.createElement("a");
  link.download = fileName;
  link.href = canvas.toDataURL(imgFileType);
  link.click();
  link.remove();
};

export const loadImage = path => {
  return new Promise((resolve, reject) => {
    const img = document.createElement("img");
    img.crossOrigin = "Anonymous";
    img.src = path;
    img.onload = () => {
      resolve(img);
    };
    img.onerror = e => {
      reject(e);
    };
  });
};

const shadeColor = (color, percent) => {
  var R = parseInt(color.substring(1, 3), 16);
  var G = parseInt(color.substring(3, 5), 16);
  var B = parseInt(color.substring(5, 7), 16);

  R = parseInt((R * (100 + percent)) / 100);
  G = parseInt((G * (100 + percent)) / 100);
  B = parseInt((B * (100 + percent)) / 100);

  R = R < 255 ? R : 255;
  G = G < 255 ? G : 255;
  B = B < 255 ? B : 255;

  var RR = R.toString(16).length == 1 ? "0" + R.toString(16) : R.toString(16);
  var GG = G.toString(16).length == 1 ? "0" + G.toString(16) : G.toString(16);
  var BB = B.toString(16).length == 1 ? "0" + B.toString(16) : B.toString(16);

  return "#" + RR + GG + BB;
};

const rgb2hex = rgb =>
  `#${rgb
    .match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/)
    .slice(1)
    .map(n => parseInt(n, 10).toString(16).padStart(2, "0"))
    .join("")}`;

const transformFileName = url =>
  url.split("/").pop().split("?timestamp").shift().split(".").shift();

const transformTokenId = tokenId => {
  return tokenId.toString().padStart(3, "0");
};
