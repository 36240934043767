import React from "react";
import { Box, Typography, ListItem } from "@mui/material";
import useTranslation from "hooks/useTranslation";

const BridgeInstruction = () => {
  const { t } = useTranslation("translation", {
    keyPrefix: "bridgeNft",
  });

  return (
    <Box color="grey.accent2">
      <Typography data-testid="text_note-title" variant="body2">
        {t("Note")}
      </Typography>
      <ListItem
        data-testid="text_note-desc-1"
        sx={{ display: "list-item", py: "0px" }}
      >
        <Typography variant="body2" component={"span"}>
          {t("ABC NFT will be completely gone from ABC Chain")}
        </Typography>
      </ListItem>
      <ListItem
        data-testid="text_note-desc-2"
        sx={{ display: "list-item", py: "0px" }}
      >
        <Typography variant="body2" component={"span"}>
          {t(
            "While Bridging to other chain, please don’t close the window in-between process",
          )}
        </Typography>
      </ListItem>
      <ListItem
        data-testid="text_note-desc-3"
        sx={{ display: "list-item", py: "0px" }}
      >
        <Typography variant="body2" component={"span"}>
          {t("When counter Error, please contract")}{" "}
          <Typography
            variant="body2"
            color="grey.accent2"
            sx={{ textDecoration: "none" }}
            component="a"
            href="mailto:nft-service@ascencorp.com"
          >
            nft-service@ascencorp.com
          </Typography>
        </Typography>
      </ListItem>
    </Box>
  );
};

export default BridgeInstruction;
