import React from "react";
import { styled } from "@mui/system";
import TabsUnstyled from "@mui/base/TabsUnstyled";
import TabPanelUnstyled from "@mui/base/TabPanelUnstyled";
import TabsListUnstyled from "@mui/base/TabsListUnstyled";
import { buttonUnstyledClasses } from "@mui/base/ButtonUnstyled";
import TabUnstyled, { tabUnstyledClasses } from "@mui/base/TabUnstyled";
import { Typography } from "@mui/material";
import { paletteLight } from "../../../../theme/index";
import { useNavigate } from "react-router-dom";
import useTranslation from "hooks/useTranslation";

const tabLinks = ["/marketplace", "/marketplace/my-vouchers"];

const MarketplaceTab = ({ onChange, activeTab }) => {
  const navigate = useNavigate();
  const { t } = useTranslation("translation", {
    keyPrefix: "marketplaceTab",
  });

  const Tab = styled(TabUnstyled)`
    min-height: 32px;
    color: ${paletteLight.grey.accent1};
    cursor: pointer;
    background-color: transparent;
    width: 100%;
    margin: 4px 4px;
    border: none;
    border-radius: 24px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      background-color: ${paletteLight.primary.accent4};
    }

    &:focus {
      color: ${paletteLight.grey.accent1};
    }

    &.${tabUnstyledClasses.selected} {
      background-color: ${paletteLight.background.default};
      color: ${paletteLight.primary.main};
    }

    &.${buttonUnstyledClasses.disabled} {
      opacity: 0.5;
      cursor: not-allowed;
    }
  `;

  const TabsList = styled(TabsListUnstyled)({
    minHeight: "40px",
    borderRadius: "25px",
    backgroundColor: paletteLight.grey.accent4,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    alignContent: "space-between",
  });

  const TabPanel = styled(TabPanelUnstyled)({
    width: "100%",
  });

  const TypographyWithFont = styled(Typography)({
    fontFamily:
      localStorage.getItem("lang") === "th"
        ? '"Anuyart", "Noto Serif Thai", "sans-serif"'
        : "Roboto",
  });

  const handleOnChange = (_, selectedIndex) => {
    navigate(tabLinks[selectedIndex]);
    onChange(selectedIndex);
  };

  return (
    <TabsUnstyled
      id="wallet-tabs"
      onChange={handleOnChange}
      value={activeTab}
      variant="fullWidth"
    >
      <TabsList sx={{ m: "0px 20px 20px 20px" }}>
        <Tab>
          <TypographyWithFont variant="subtitle">
            {t("Marketplace")}
          </TypographyWithFont>
        </Tab>
        <Tab>
          <TypographyWithFont variant="subtitle">
            {t("My voucher")}
          </TypographyWithFont>
        </Tab>
      </TabsList>
      <TabPanel value={0}></TabPanel>
      <TabPanel value={1}></TabPanel>
    </TabsUnstyled>
  );
};

export default MarketplaceTab;
