import React from "react";

import { Box, Avatar, Skeleton } from "@mui/material";

const MyWalletSkeleton = () => {
  return (
    <Box
      sx={{
        border: 1,
        borderColor: "grey.accent3",
        borderRadius: "16px",
        minHeight: "292px",
        minWidth: "343px",
        backgroundColor: "background.paper",
      }}
      data-testid="my-wallet-skeleton"
    >
      <Box sx={{ m: "24px 16px" }}>
        <Box
          sx={{
            mb: "8px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Avatar
            sx={{
              height: "40px",
              width: "40px",
              mr: "8px",
            }}
          >
            <Skeleton
              animation="wave"
              variant="circular"
              width={40}
              height={40}
            />
          </Avatar>
          <Skeleton animation="wave" width="20%" />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            mb: "8px",
          }}
        >
          <Skeleton animation="wave" width="15%" sx={{ mb: "4px" }} />
          <Skeleton animation="wave" width="20%" />
        </Box>
        <Box>
          <Skeleton
            animation="wave"
            width="40%"
            sx={{
              mb: "8px",
            }}
          />
          <Skeleton
            animation="wave"
            width="100%"
            sx={{
              mb: "16px",
              minHeight: "40px",
              borderRadius: "16px",
              display: "flex",
              alignItems: "center",
            }}
          />
          <Skeleton
            animation="wave"
            width="100%"
            sx={{
              minHeight: "40px",
              borderRadius: "16px",
              display: "flex",
              alignItems: "center",
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default MyWalletSkeleton;
