import useAuth from "hooks/useAuth";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import AuthContextService from "../../contexts/authContext/AuthContextServices";
import GenericError from "../genericError/GenericError";

const SessionExpiredError = () => {
  const navigate = useNavigate();
  const { logout } = useAuth();

  return (
    <>
      <Helmet>
        <title>ABC</title>
      </Helmet>
      <GenericError
        keyPrefix="expiredToken"
        title="Session expired"
        body="Please re-open the app to renew your session."
        buttonText="OK"
        navigateTo=""
        redirect={() => {
          if (AuthContextService.getTmwTokenRefFromLocalStorage()) {
            window.location.replace("ascendmoney://closewebview");
          } else {
            AuthContextService.clearTokenFromLocalStorage();
            logout();
            navigate("/");
          }
        }}
        isHideMenu={true}
        center={true}
      />
    </>
  );
};

export default SessionExpiredError;
