import { Box, Typography, Modal, Button } from "@mui/material";
import React, { useEffect } from "react";
import { styled } from "@mui/system";
import LoadingIcon from "assets/icons/loading.svg";
import useTranslation from "hooks/useTranslation";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  resetNftMyCollectionBridge,
  fetchAsyncNftMyCollectionBridge,
  status as NftMyCollectionBridgeStatus,
  fetchAsyncApproveBridge,
  updateStep,
} from "features/wallet/redux/slices/nftMyCollectionBridge/nftMyCollectionBridge";
import { useWeb3React } from "@web3-react/core";
import { trackSuccessfulBridge } from "mixpanel/nft";
import {
  getPublicChainCollection,
  getResourceIdCollection,
} from "smartcontract/utils/mapContractToPublic";
import useNftBridge from "../../../hooks/useNftBridge";
import { useGalleryContract } from "smartcontract/hooks/useContract";

const LoadingAnimationBox = styled(Box)`
  animation-duration: 1.4s;
  animation-timing-function: linear;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-fill-mode: none;
  animation-play-state: running;
  animation-name: animation-61bdi0;
`;

const BridgingModal = ({ isLoading, token, collection, isPending }) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "bridgeNft",
  });
  const navigate = useNavigate();
  const { account } = useWeb3React();
  const dispatch = useDispatch();
  const {
    status: nftMyCollectionBridgeStatus,
    statusApprove: nftApproveTokenStatus,
    step,
  } = useSelector(state => state.nftMyCollectionBridge);
  const galleryContract = useGalleryContract(
    getPublicChainCollection(collection.address),
  );

  const { withdrawNftBridge } = useNftBridge({
    collectionAddress: process.env.REACT_APP_DST_ERC_721_HANDLER,
  });

  useEffect(() => {
    if (isLoading) isPending ? dispatch(updateStep(1)) : approveTransaction();
    return () => {
      dispatch(resetNftMyCollectionBridge());
      galleryContract.removeAllListeners("Transfer");
    };
  }, [dispatch, isLoading]);

  useEffect(() => {
    if (nftApproveTokenStatus === NftMyCollectionBridgeStatus.LOADED)
      depositTransaction();
    if (nftApproveTokenStatus === NftMyCollectionBridgeStatus.ERROR)
      handleBackToMyCollection();
  }, [nftApproveTokenStatus, navigate]);

  useEffect(() => {
    if (nftMyCollectionBridgeStatus === NftMyCollectionBridgeStatus.LOADED)
      waitBridgingEventTransaction();
    if (nftMyCollectionBridgeStatus === NftMyCollectionBridgeStatus.ERROR)
      handleBackToMyCollection();
  }, [nftMyCollectionBridgeStatus, navigate]);

  const approveTransaction = () => {
    dispatch(
      fetchAsyncApproveBridge({
        collectionAddress: collection.address,
        tokenId: token.tokenID,
      }),
    );
  };

  const depositTransaction = () => {
    dispatch(
      fetchAsyncNftMyCollectionBridge({
        recipientAddress: account,
        resourceId: getResourceIdCollection(collection.address),
        bridgeAddress: process.env.REACT_APP_SRC_BRIDGE_ADDRESS,
        destination: "1",
        tokenId: token.tokenID,
      }),
    );
  };

  const waitBridgingEventTransaction = () => {
    trackSuccessfulBridge(collection.name, token.metadata.name, token.tokenID);
    galleryContract.on("Transfer", (from, to, tokenId) => {
      /* istanbul ignore next */
      if (
        to === process.env.REACT_APP_DST_ERC_721_HANDLER &&
        parseInt(tokenId._hex, 16) === parseInt(token.tokenID)
      )
        /* istanbul ignore next */
        dispatch(updateStep(1));
    });
  };

  const handleConfirmTransaction = async () => {
    try {
      dispatch(updateStep(2));
      const resultTransaction = await withdrawNftBridge(
        getPublicChainCollection(collection.address),
        token.tokenID,
      );
      if (
        resultTransaction?.transactionHash &&
        resultTransaction?.status === 1
      ) {
        dispatch(updateStep(3));
      } else {
        handleBackToMyCollection();
      }
    } catch (error) {
      handleBackToMyCollection();
    }
  };
  const handleBackToMyCollection = () =>
    isPending ? navigate("/wallet/nfts/my-pending") : navigate("/wallet/nfts");

  const bridgingStepComponent = () => {
    switch (step) {
      case 0:
        return (
          <Box display="flex" flexDirection="column" alignItems="center">
            <Typography variant="body2">{t("Bridging...")}</Typography>
            <Typography variant="body2">
              {t("Please wait and don’t close the window")}
            </Typography>
          </Box>
        );
      case 1:
        return (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            sx={{ gap: "32px" }}
          >
            <Box width="80%">
              <Typography variant="body2">{t("Bridging...")}</Typography>
              <Typography variant="body2">
                {t(
                  "Your NFTs is now ready to be withdraw to destination chain, Tap OK and please continue in your Metamask to continue",
                )}
              </Typography>
            </Box>
            <Button
              variant="contained"
              sx={{ height: "48px", borderRadius: "24px" }}
              fullWidth
              display="flex"
              onClick={handleConfirmTransaction}
            >
              {t("Ok")}
            </Button>
          </Box>
        );
      case 2:
        return <Typography variant="body2">{t("Bridging...")}</Typography>;
      case 3:
        return (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            sx={{ gap: "32px" }}
          >
            <Box width="80%">
              <Typography variant="body2">
                {t(
                  "It will take 1-5 minutes for the bridge to get completed. Once completion, you can check NFT in Metamask wallet",
                )}
              </Typography>
            </Box>
            <Button
              variant="contained"
              sx={{ height: "48px", borderRadius: "24px" }}
              fullWidth
              display="flex"
              onClick={handleBackToMyCollection}
            >
              {t("Ok")}
            </Button>
          </Box>
        );
      default:
        return <Typography variant="body2">{t("Bridging...")}</Typography>;
    }
  };

  return (
    <Modal
      data-testid="modal_bridging"
      open={isLoading}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        alignContent: "center",
        textAlign: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          bgcolor: "background.paper",
          width: "100%",
          maxWidth: "340px",
          minHeight: "144px",
          m: "16px",
          justifyContent: "space-between",
          borderRadius: "16px",
          p: "24px",
          overflowY: "auto",
          maxHeight: "100%",
          alignItems: "center",
        }}
      >
        {step !== 3 && (
          <LoadingAnimationBox
            data-testid="img_loading-icon"
            sx={{
              marginBottom: "24px",
              width: "48px",
              height: "48px",
            }}
          >
            <Box
              id="img_logoPopup"
              component="img"
              src={LoadingIcon}
              sx={{
                width: "48px",
                height: "48px",
              }}
            />
          </LoadingAnimationBox>
        )}
        {bridgingStepComponent()}
      </Box>
    </Modal>
  );
};

export default BridgingModal;
