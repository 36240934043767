import axios from "axios";
import { apiEndpoint } from "../config";

const GrowthbookAPI = axios.create({
    baseURL: apiEndpoint.growthbook,
    headers: {
      "content-type": "application/json",
    },
});

export default GrowthbookAPI