import { Box } from "@mui/system";
import AllDeals from "features/deals/components/allDeals/AllDeals";
import NewDeals from "features/deals/components/NewDeals/AllDeals";
import CarouselBanner from "features/deals/components/carousel/CarouselBanner";
import MerchantList from "features/deals/components/merchants/MerchantList";
import useTranslation from "hooks/useTranslation";
import { trackVisitMarketplacePage } from "mixpanel";
import React from "react";
import { Helmet } from "react-helmet-async";
import { getFeatureFlags } from "../../config";

const MarketplaceHome = () => {
  const { t } = useTranslation("translation", { keyPrefix: "marketplace" });

  //feature flag
  const { enableNewAllDeals } = getFeatureFlags();

  React.useEffect(() => {
    trackVisitMarketplacePage();
  }, []);

  return (
    <>
      <Helmet>
        <title>{t("Marketplace")}</title>
      </Helmet>
      <Box
        sx={{
          pt: "32px",
        }}
      >
        <Box sx={{ mb: "32px", p: "0 16px" }}>
          <CarouselBanner />
          <MerchantList />
        </Box>
        <Box sx={{ mb: "16px" }}>
          {enableNewAllDeals ? <NewDeals /> : <AllDeals />}
        </Box>
      </Box>
    </>
  );
};

export default MarketplaceHome;
