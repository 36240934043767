import React from "react";
import { Box, Typography, Divider } from "@mui/material";
import { styled } from "@mui/system";
import Coins from "assets/icons/coins.svg";
import ABCPointIcon from "assets/icons/ABCPoint.svg";
import CheckIcon from "assets/images/MyWallet/Check_Base.png";
import { getChannelConfig } from "config";
import CoinsBalance from "./CoinsBalance";
import { coinFormatter } from "utils/coinFormatter";

export const MainLayout = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #e1e1e1;
  box-sizing: border-box;
  border-radius: 16px;
  padding: 16px;
  gap: 16px;
`;

export const DetailLine = styled(Box)`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  p {
    line-height: 24px;
  }
`;

export const DetailDivinder = styled(Divider)`
  width: 100%;
  border-color: #e1e1e1;
`;

export const DetailItemType = {
  TEXT: "text",
};

const coinsUnit = getChannelConfig().showCoin ? "ABC" : "ABCP";

const ConvertCompleteLayout = ({
  successTitle,
  amount,
  details,
  coinsStatus,
  coinsBalance,
  totalBalanceText,
}) => {
  return (
    <MainLayout data-testid="convert-complete-layout">
      <Box component="img" src={CheckIcon} />
      <Typography id="lbl_successTitle" variant="subtitle">
        {successTitle}
      </Typography>
      <Box sx={{ display: "flex" }}>
        <Box
          id="img_coins-successTitle"
          alt="Coins-icon"
          component="img"
          src={getChannelConfig().showCoin ? Coins : ABCPointIcon}
          sx={{
            objectFit: "cover",
            height: "24px",
            width: "24px",
            mr: "8px",
          }}
        />
        <Typography id="amountState" variant="h6">
          {`${coinFormatter(amount?.toString())} ${coinsUnit}`}
        </Typography>
      </Box>
      <DetailDivinder />
      {details?.map(item => {
        return (
          <DetailLine key={item.title}>
            <Typography
              id={`lbl_detailTitle-${item.title}`}
              variant="body2"
              sx={{ color: "grey.accent2" }}
            >
              {item.title}
            </Typography>
            {item.type === DetailItemType.TEXT ? (
              <Typography id={`lbl_detailValue-${item.title}`} variant="body2">
                {item.value}
              </Typography>
            ) : (
              <>{item.value}</>
            )}
          </DetailLine>
        );
      })}
      <DetailDivinder />
      <CoinsBalance
        coinsStatus={coinsStatus}
        coinsBalance={coinsBalance}
        totalBalanceText={totalBalanceText}
        style={{ marginTop: "8px" }}
      />
    </MainLayout>
  );
};

export default ConvertCompleteLayout;
