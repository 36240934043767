import React, { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";
import useTranslation from "../../../../../hooks/useTranslation";
import { useSelector } from "../../../../../redux/store/configureStore";
import { getValueByLanguage } from "../../../../../utils/getLanguage";
import { Status } from "../../../../../redux/constant";
import SkeletonPartnerButton from "../../partnerButton/SkeletonPartnerButton";
import PartnerRewardButton from "../../partnerButton/partnerRewardButton/PartnerRewardButton";
import { getPartnerImageByLang } from "utils/imageAsset";
import { trackVisitAllLinkedPartnersPage } from "../../../../../mixpanel";
import usePartnerBalance from "features/wallet/hooks/usePartnerBalance";

const PartnerAllYourRewardList = () => {
  const navigate = useNavigate();
  const { partnerList, status: partnerListStatus } = useSelector(
    state => state.partnerList,
  );
  const { linkedPartner, status: linkedPartnerStatus } = useSelector(
    state => state.linkedPartner,
  );
  const partnersBalance = usePartnerBalance();

  useEffect(() => {
    trackVisitAllLinkedPartnersPage();
  }, []);

  const { t } = useTranslation("translation", {
    keyPrefix: "PartnerConvert",
  });

  const filteredLinkedPartner = useMemo(
    () =>
      linkedPartner?.filter(
        linkedItem => linkedItem.connected && !linkedItem.social,
      ),
    [linkedPartner],
  );

  const handleClickManage = () => {
    navigate("/settings/linked-rewards");
  };

  const handleClickPartnerButton = _partnerId => {
    navigate(`/convert/${_partnerId}`);
  };

  const getPartnerDetail = partnerId => {
    return partnerList?.items?.find(item => item.id === partnerId);
  };

  const renderPartnerAllYourRewardList = () => {
    if (
      linkedPartnerStatus === Status.LOADED &&
      partnerListStatus === Status.LOADED
    ) {
      return (
        <>
          {filteredLinkedPartner?.map(item => (
            <PartnerRewardButton
              key={item.providerName}
              id={`btn_yourReward_${item.providerName}`}
              partnerId={item.providerName}
              partnerName={getValueByLanguage(
                getPartnerDetail(item.providerName)?.name,
              )}
              partnerImage={getPartnerImageByLang(item.providerName)}
              onClick={() => handleClickPartnerButton(item.providerName)}
              isLinked
              havePoint
              pointBalance={partnersBalance[item.providerName]}
              pointBalanceStatus={
                partnersBalance[item.providerName] !== undefined
                  ? Status.LOADED
                  : Status.LOADING
              }
              pointUnit={getValueByLanguage(
                getPartnerDetail(item.providerName)?.unitName,
              )}
            />
          ))}
        </>
      );
    }

    return (
      <>
        {[...Array(4)].map((_, index) => (
          <SkeletonPartnerButton key={index} />
        ))}
      </>
    );
  };

  return (
    <>
      <Box
        sx={{ display: "flex", justifyContent: "space-between" }}
        data-testid="partner-all-your-reward-list"
      >
        <Typography id="lbl_title_yourReward" variant="h6">
          {t("Your rewards")}
        </Typography>
        <Button variant="text" onClick={handleClickManage}>
          <Typography
            id="btn_seeAll_partnerList"
            variant="body1"
            sx={{ color: "primary.main" }}
          >
            {t("Manage")}
          </Typography>
        </Button>
      </Box>
      <Box
        sx={{
          display: "grid",
          columnGap: "23px",
          rowGap: "16px",
          gridTemplateColumns: "repeat(auto-fill, minmax(130px, 1fr))",
          mt: "16px",
        }}
        data-testid="your-reward-list"
      >
        {renderPartnerAllYourRewardList()}
      </Box>
    </>
  );
};

export default PartnerAllYourRewardList;
