import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import MainAPI from "apiConfig/MainAPI";
import { serviceEndpoint } from "config";
import { Status } from "../../../../../redux/constant";

const initialState = {
  convertF2CSendOtp: {},
  status: Status.INITIAL,
  error: null,
};

export const fetchAsyncConvertF2CSendOtp = createAsyncThunk(
  "convertF2CSendOtp/fetchAsyncConvertF2CSendOtp",
  async ({ mobileNumber }, { rejectWithValue }) => {
    try {
      const response = await MainAPI.post(
        `${serviceEndpoint.f2c}/providers/tmn-otp/auth/code`,
        {
          mobileNumber,
        },
      );
      return response.data;
    } catch (err) {
      if (err.response) {
        return rejectWithValue({
          status: err.response.status,
          data: err.response.data,
        });
      }
      return rejectWithValue({
        status: "",
        data: err.message,
      });
    }
  },
);

const ConvertF2CSendOtpSlice = createSlice({
  name: "convertF2CSendOtp",
  initialState,
  reducers: {
    resetConvertF2CSendOtp: state => {
      state.convertF2CSendOtp = {};
      state.status = Status.INITIAL;
      state.error = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchAsyncConvertF2CSendOtp.pending, state => {
        state.error = null;
        state.status = Status.LOADING;
      })
      .addCase(fetchAsyncConvertF2CSendOtp.fulfilled, (state, { payload }) => {
        state.convertF2CSendOtp = payload;
        state.status = Status.LOADED;
      })
      .addCase(fetchAsyncConvertF2CSendOtp.rejected, (state, { payload }) => {
        if (payload === undefined) {
          state.status = Status.ERROR;
          return;
        }
        if (payload.status === 420) {
          state.convertF2CSendOtp = payload.data?.info;
        }
        state.error = {
          statusCode: payload.status,
          errorMessage: payload.data,
        };
        state.status = Status.ERROR;
      });
  },
});

export const { resetConvertF2CSendOtp } = ConvertF2CSendOtpSlice.actions;

export default ConvertF2CSendOtpSlice.reducer;
