import { useState, useMemo } from "react";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Modal,
  RadioGroup,
  Typography,
  Radio,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { ReactComponent as UnlinkBaseIcon } from "assets/icons/unlink-base.svg";
import useTranslation from "hooks/useTranslation";
import { getPartnerImageByLang } from "utils/imageAsset";
import getLanguage from "utils/getLanguage";
import { parseJsonWithDefaultValue } from "utils/jsonManagement";
import { getChannelConfig } from "config";
import walletCoinIcon from "assets/icons/coins.svg";
import ABCPointIcon from "assets/icons/ABCPoint.svg";

const UnlinkRewards = ({
  partnerId = "krungsri",
  isVisible,
  onClickUnlink,
  onClickCancel,
}) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "ManageLinkedRewards",
  });
  const [selectedReason, setSelectedReason] = useState();

  const reasonsList = useMemo(() => {
    const lang = getLanguage();
    const reasonsListJson =
      process.env[`REACT_APP_UNLINK_PARTNER_REASON_${lang.toUpperCase()}`];
    let list = parseJsonWithDefaultValue(reasonsListJson, []);
    if (!Array.isArray(list)) {
      return [];
    }
    return list.filter(text => text);
  }, []);

  const handleChange = event => {
    setSelectedReason(event.target.value);
  };

  const renderReasonRadio = () => {
    return (
      <>
        <FormControl>
          <RadioGroup
            id="radio_unlink-reason"
            aria-labelledby="radio-buttons-group-unlink-reason"
            name="radio-buttons-group-unlink-reason"
            onChange={handleChange}
          >
            {reasonsList.map((reasonText, index) => (
              <FormControlLabel
                key={reasonText}
                id={`radio-reason-${index}`}
                data-testid="radio-reason"
                value={reasonText}
                control={<Radio />}
                label={reasonText}
                componentsProps={{
                  typography: {
                    variant: "body2",
                  },
                }}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </>
    );
  };

  return (
    <Modal
      id="modal_unlink-rewards"
      open={isVisible}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      data-testid="unlink-rewards"
      onClose={onClickCancel}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          bgcolor: "background.paper",
          alignItems: "center",
          borderRadius: "16px",
          m: "16px",
          p: "24px",
          overflowY: "auto",
          maxHeight: "90%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            mb: "8px",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={onClickCancel}
        >
          <CloseIcon />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: "24px",
            width: "200px",
          }}
        >
          <Box
            id={`img_partner-icon-${partnerId}"`}
            alt="partner-icon"
            component="img"
            src={getPartnerImageByLang(partnerId)}
            sx={{
              height: "56px",
              width: "56px",
            }}
          />
          <UnlinkBaseIcon />
          <Box
            id={"img_abc-icon"}
            alt="abc-icon"
            component="img"
            src={getChannelConfig().showCoin ? walletCoinIcon : ABCPointIcon}
            sx={{
              height: "56px",
              width: "56px",
            }}
          />
        </Box>
        <Box sx={{ mb: "16px", textAlign: "center" }}>
          <Typography variant="h6">{t("Do you want to unlink")}</Typography>
        </Box>
        <Box sx={{ mb: "24px", textAlign: "center" }}>
          <Typography variant="body2">{t("Any reasons?")}</Typography>
        </Box>
        <Box sx={{ mb: "24px", width: "100%" }}>{renderReasonRadio()}</Box>
        <Box sx={{ display: "flex", gap: "13px", width: "100%" }}>
          <Button
            sx={{ borderRadius: "24px", height: "48px" }}
            variant="outlined"
            fullWidth
            onClick={onClickCancel}
          >
            {t("CANCEL")}
          </Button>
          <Button
            sx={{ borderRadius: "24px", height: "48px" }}
            variant="contained"
            fullWidth
            onClick={() => onClickUnlink(selectedReason)}
          >
            {t("CONFIRM")}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default UnlinkRewards;
