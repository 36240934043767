import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import PointExchangeAPI from "../../../../../apiConfig/PointExchangeAPI";
import { Status } from "../../../../../redux/constant";

const initialState = {
  convertPartnerBalance: {},
  status: Status.INITIAL,
  errorMessage: null,
};

export const fetchAsyncConvertPartnerBalance = createAsyncThunk(
  "convertPartnerBalance/fetchAsyncConvertPartnerBalance",
  async ({ partnerId }, { rejectWithValue }) => {
    try {
      const response = await PointExchangeAPI.get(
        `/partners/${partnerId}/balance/caller`,
      );
      return response.data;
    } catch (err) {
      if (err.response) {
        return rejectWithValue({
          status: err.response.status,
          data: err.response.data,
        });
      }
      return rejectWithValue({
        status: "",
        data: err.message,
      });
    }
  },
);

const ConvertPartnerBalanceSlice = createSlice({
  name: "convertPartnerBalance",
  initialState,
  reducers: {
    resetConvertPartnerBalance: state => {
      state.convertPartnerBalance = [];
      state.status = Status.INITIAL;
      state.errorMessage = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchAsyncConvertPartnerBalance.pending, state => {
        state.errorMessage = null;
        state.status = Status.LOADING;
      })
      .addCase(
        fetchAsyncConvertPartnerBalance.fulfilled,
        (state, { payload }) => {
          state.convertPartnerBalance = payload;
          state.status = Status.LOADED;
        },
      )
      .addCase(
        fetchAsyncConvertPartnerBalance.rejected,
        (state, { payload }) => {
          if (payload === undefined) {
            state.status = Status.ERROR;
            return;
          }
          state.errorMessage = payload.data;
          state.status = Status.ERROR;
        },
      );
  },
});

export const { resetConvertPartnerBalance } =
  ConvertPartnerBalanceSlice.actions;

export default ConvertPartnerBalanceSlice.reducer;
