import Box from "@mui/material/Box";
import Logo from "../../assets/icons/navbar_abc_logo.svg";

const SplashScreen = () => (
  <Box
    sx={{
      height: "100vh",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      pb: "20px",
    }}
  >
    <Box
      alt="Logo"
      component="img"
      src={Logo}
      sx={{
        height: "96px",
        width: "154px",
      }}
    />
  </Box>
);

export default SplashScreen;
