import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DealsAPI from "../../../../../apiConfig/DealsAPI";

export const status = {
  INITIAL: "INITIAL",
  LOADING: "LOADING",
  LOADED: "LOADED",
  EMPTY: "EMPTY",
  ERROR: "ERROR",
};

const initialState = {
  offset: 0,
  deals: [],
  status: status.INITIAL,
  errorMessage: null,
  hasMore: false,
};

//Thunks

export const fetchAsyncDealsSearch = createAsyncThunk(
  "deals/fetchAsyncDealsSearch",
  async ({ offset, limit, term }, { rejectWithValue }) => {
    try {
      const response = await DealsAPI.get(
        `/deals?skip=${offset}&limit=${limit}&search=${term}`
      );
      return {
        status: response.status,
        data: response.data.entries,
        limit,
      };
    } catch (err) {
      if (err.response) {
        return rejectWithValue({
          status: err.response.status,
          data: err.response.data,
        });
      }
      return {
        status: "",
        data: err.message,
      };
    }
  }
);

//Slices
const DealsSearchSlice = createSlice({
  name: "dealsSearch",
  initialState,
  reducers: {
    clearDeals: (state, action) => {
      state.deals = [];
      state.offset = 0;
      state.status = status.INITIAL;
      state.errorMessage = null;
      state.hasMore = false;
    },
    nextPage: (state, action) => {
      state.offset = state.offset + action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAsyncDealsSearch.pending, (state) => {
        state.errorMessage = null;
        state.status = status.LOADING;
      })
      .addCase(fetchAsyncDealsSearch.fulfilled, (state, { payload }) => {
        if (payload.data.length === 0 && state.deals.length === 0) {
          state.hasMore = false;
          state.status = status.EMPTY;
          return;
        }

        if (payload.data.length === 0) {
          state.hasMore = false;
          state.status = status.LOADED;
          return;
        }

        state.hasMore = payload.data.length < payload.limit ? false : true;
        state.deals = [ ...payload.data];
        state.errorMessage = null;
        state.status = status.LOADED;
      })
      .addCase(fetchAsyncDealsSearch.rejected, (state, { payload }) => {
        state.errorMessage = payload.data;
        state.status = status.ERROR;
      });
  },
});

export const { clearDeals, nextPage } = DealsSearchSlice.actions;

export default DealsSearchSlice.reducer;
