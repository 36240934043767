export const parseJsonWithDefaultValue = (
  jsonValue,
  defaultReturnValue = null,
) => {
  try {
    return JSON.parse(jsonValue);
  } catch (e) {
    return defaultReturnValue;
  }
};
