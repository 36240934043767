import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ProfileAPI from "../../../../../apiConfig/ProfileAPI";
import { phoneNumberURIEncodedWithThaiCountryCode } from "../../../../../utils/phoneNumberFormatter";

export const status = {
  INITIAL: "INITIAL",
  LOADING: "LOADING",
  LOADED: "LOADED",
  EMPTY: "EMPTY",
  ERROR: "ERROR",
};

const initialState = {
  destinationProfile: null,
  status: status.INITIAL,
  errorMessage: null,
};

//Thunks
export const fetchAsyncNftCheckMobileNumber = createAsyncThunk(
  "nfts/fetchAsyncNftCheckMobileNumber",
  async (mobileNumber, { rejectWithValue }) => {
    try {
      const phoneNumber = phoneNumberURIEncodedWithThaiCountryCode(mobileNumber)
      const response = await ProfileAPI.get(
        `profiles?mobileNumber=${phoneNumber}`,
      );
      if (response.data?.items?.length > 0) {
        return response.data?.items[0];
      } else {
        throw new Error("user not found");
      }
    } catch (err) {
      if (err.response) {
        return rejectWithValue({
          status: err.response.status,
          data: err.response.data,
        });
      }
      return rejectWithValue({
        status: "",
        data: err.message,
      });
    }
  },
);

//Slices
const NftCheckMobileNumberSlice = createSlice({
  name: "nftCheckMobileNumber",
  initialState,
  reducers: {
    resetNftCheckMobileNumber: state => {
      state.destinationProfile = null;
      state.status = status.INITIAL;
      state.errorMessage = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchAsyncNftCheckMobileNumber.pending, state => {
        state.errorMessage = null;
        state.status = status.LOADING;
      })
      .addCase(
        fetchAsyncNftCheckMobileNumber.fulfilled,
        (state, { payload }) => {
          state.destinationProfile = {
            id: payload.id,
            abcChainAccount: payload.abcChainAccount,
            firstNameEN: payload.firstNameEN,
            firstNameTH: payload.firstNameTH,
            lastNameEN: payload.lastNameEN,
            lastNameTH: payload.lastNameTH,
            middleNameEN: payload.middleNameEN,
            middleNameTH: payload.middleNameTH,
            mobileNumber: payload.mobileNumber,
          };
          state.errorMessage = null;
          state.status = status.LOADED;
        },
      )
      .addCase(
        fetchAsyncNftCheckMobileNumber.rejected,
        (state, { payload }) => {
          if (payload === undefined) {
            state.status = status.ERROR;
            return;
          }
          state.errorMessage = payload.data;
          state.status = status.ERROR;
        },
      );
  },
});

export const { resetNftCheckMobileNumber } = NftCheckMobileNumberSlice.actions;

export default NftCheckMobileNumberSlice.reducer;
