import { Box, Avatar, Skeleton, Typography } from "@mui/material";
import { abcLogo, abcUnit } from "features/wallet/utils/abc";
import useTranslation from "../../../../../hooks/useTranslation";
import { Status } from "../../../../../redux/constant";
import { coinFormatter } from "../../../../../utils/coinFormatter";

const AbcConvertCard = ({
  rateStatus,
  coinsStatus,
  convertBalance,
  remainingCoinsBalance = 0,
  rate,
}) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "convertCoins",
  });

  const renderBalance = () => {
    if (coinsStatus === Status.LOADING) {
      return (
        <Skeleton
          animation="wave"
          height={"20px"}
          width="100px"
          id="coins-loading"
          data-testid="balance-skeleton"
        />
      );
    } else if (coinsStatus === Status.ERROR) {
      return t("Unable to retrieve balance");
    } else if (coinsStatus === Status.LOADED) {
      return `${t("ABC Balance")} ${coinFormatter(
        remainingCoinsBalance?.toString(),
      )} ${abcUnit}`;
    }
    return null;
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        backgroundColor: "background.paper",
        border: 1,
        borderColor: "grey.accent3",
        borderRadius: "16px",
        mt: "24px",
        p: "16px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minWidth: "90px",
        }}
      >
        <Box>
          <Typography variant="body1">{abcUnit}</Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
          }}
        >
          <Avatar
            id="img_abcLogo_ConvertLoyaltyPointPage"
            sx={{
              height: "40px",
              width: "40px",
              mt: "15px",
            }}
            src={abcLogo}
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          m: "36px 0 0 15px",
        }}
      >
        <Typography
          id="lbl_amountABC_ConvertLoyaltyPointPage"
          variant="h4"
          sx={{ mb: "4px" }}
        >
          {rateStatus === Status.LOADED
            ? (convertBalance / rate).toLocaleString()
            : "0"}
        </Typography>
        <Typography
          id="lbl_ABCBalance_ConvertLoyaltyPointPage"
          variant="helpingText"
          color={"grey.accent2"}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          {renderBalance()}
        </Typography>
      </Box>
    </Box>
  );
};

export default AbcConvertCard;
