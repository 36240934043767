import { Typography, Box, Button, Avatar, Divider } from "@mui/material";
import { Helmet } from "react-helmet-async";
import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import useTranslation from "../../../../hooks/useTranslation";
import useLoadingHook from "../../../../hooks/useLoadingHook";
import { coinFormatter } from "../../../../utils/coinFormatter";
import SwapIcon from "../../../../assets/icons/swap.svg";
import {
  useDispatch,
  useSelector,
} from "../../../../redux/store/configureStore";
import useNavigationHook from "../../../../hooks/useNavigation";
import Prompt from "../../../../components/Prompt/Prompt";
import {
  fetchAsyncConvertPoints,
  status as ConvertPointsStatus,
  resetConvertPoints,
} from "../../../wallet/redux/slices/convertPoints/ConvertPoints";
import AccessTokenService from "../../../../contexts/authContext/AuthContextServices";
import { resetCoins } from "../../redux/slices/coins/Coins";
import _ from "lodash";
import { abcLogo, abcUnit } from "features/wallet/utils/abc";

const ReviewConvert = () => {
  const { partner, rates, convertPoints, convertedCoins } = useLocation().state;
  const setActiveMenu = useNavigationHook();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const convertPointsTransection = useSelector(state => state.convertPoints);
  const { t } = useTranslation("translation", {
    keyPrefix: "ReviewConvert",
  });
  const progressScreen = useLoadingHook();
  const [promptOpen, setPromptOpen] = React.useState(false);
  const [promptError, setPromptError] = React.useState({
    title: null,
    subtitle: null,
    rawTitle: null,
    rawSubTitle: null,
    buttonText: null,
    onClose: () => {},
  });

  React.useEffect(() => {
    setActiveMenu(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    return () => {
      dispatch(resetConvertPoints());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (convertPointsTransection.status === ConvertPointsStatus.LOADING) {
      progressScreen.setOpen(true);
    } else if (convertPointsTransection.status === ConvertPointsStatus.ERROR) {
      progressScreen.setOpen(false);
      if (!_.isEmpty(convertPointsTransection.errorMessage)) {
        const { code, title, body, description } =
          convertPointsTransection.errorMessage.error;
        if (title && description) {
          setPromptError({
            title: null,
            subtitle: null,
            rawTitle: title,
            rawSubTitle: description,
            buttonText: "Back to ABC Wallet",
            onClose: () => navigate("/convert"),
          });
        } else {
          setPromptError({
            title: "Unable to proceed",
            subtitle: null,
            rawTitle: null,
            rawSubTitle: code,
            buttonText: "Back to ABC Wallet",
            onClose: () => navigate("/convert"),
          });
        }
      } else {
        setPromptError({
          title: "Unable to proceed",
          subtitle: "Please try again",
          rawTitle: null,
          rawSubTitle: null,
          buttonText: "Back to ABC Wallet",
          onClose: () => navigate("/convert"),
        });
      }

      setPromptOpen(true);
    } else if (convertPointsTransection.status === ConvertPointsStatus.LOADED) {
      dispatch(resetCoins());
      progressScreen.setOpen(false);
      navigate(`/convert-complete`, {
        state: {
          convert: {
            amount: convertedCoins,
            fromName: partner?.name,
            fromImageIconUrl: partner?.logo,
            dateTime: convertPointsTransection.transection.timeReceived,
            transactionId: convertPointsTransection.transection.transactionId,
            convertPoints,
            exchangeRate: rates?.rates?.rate,
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [convertPointsTransection.status]);

  const handleClick = () => {
    const trueIdToken = AccessTokenService.getTrueIdTokenFromLocalStorage();
    dispatch(
      fetchAsyncConvertPoints({
        trueIdToken,
        swapPairId: rates.rates.id,
        fromUnit: convertPoints,
        exchangeRate: rates.rates.rate,
      }),
    );
  };

  const ConvertDetail = ({ title, details, unit }) => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minWidth: "90px",
        }}
      >
        <Box
          sx={{
            mb: "16px",
          }}
        >
          <Typography variant="subtitle">{title}</Typography>
        </Box>
        <Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Avatar
              sx={{
                height: "40px",
                width: "40px",
              }}
              src={details?.logo}
            />
            <Box sx={{ ml: "8px" }}>
              <Typography variant="body1" sx={{ mb: "8px" }}>
                {details?.name}
              </Typography>
              <Typography variant="body1" sx={{ mb: "8px" }}>
                {`${coinFormatter(details?.balance)} ${unit}`}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  const ConvertSeperation = () => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          mt: "24px",
          mb: "24px",
        }}
      >
        <Box
          alt="Coins"
          component="img"
          src={SwapIcon}
          sx={{
            objectFit: "cover",
            height: "24px",
            width: "24px",
            mr: "16px",
          }}
        />
        <Divider
          light
          sx={{
            flex: 1,
          }}
        />
      </Box>
    );
  };

  const ConvertRate = () => {
    return (
      <Box
        sx={{
          backgroundColor: "grey.accent4",
          p: "16px",
          borderRadius: "16px",
        }}
      >
        <Typography variant="body2">
          {`${t("Conversion rate")}: ${rates.rates.rate} ${
            rates.rates.from
          } = 1 ${abcUnit}`}
        </Typography>
      </Box>
    );
  };

  return (
    <Box>
      <Helmet>
        <title>{t("Review")}</title>
      </Helmet>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          p: "32px 16px 0",
          backgroundColor: "background.paper",
          height: "calc(100vh - 50px)",
        }}
      >
        <Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              border: 1,
              borderColor: "grey.accent3",
              borderRadius: "16px",
              mb: "24px",
              p: "16px",
            }}
          >
            <ConvertDetail
              title={t("You convert")}
              details={{
                logo: partner?.logo,
                name: partner?.name,
                balance: convertPoints,
              }}
              unit={t("points")}
            />
            <ConvertSeperation />
            <ConvertDetail
              title={t("You get")}
              details={{
                logo: abcLogo,
                name: abcUnit,
                balance: convertedCoins,
              }}
              unit={abcUnit}
            />
          </Box>
          <ConvertRate />
        </Box>
        <Box
          sx={{
            m: "0px -16px",
            p: "16px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              pb: "16px",
              px: "8px",
            }}
          >
            <Typography variant="subtitle">{t("You get_2")}</Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Avatar
                sx={{
                  height: "24px",
                  width: "24px",
                  mr: "8px",
                }}
                src={abcLogo}
              />
              <Typography variant="subtitle">
                {`${coinFormatter(convertedCoins)} ${abcUnit}`}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ pb: "16px" }}>
            <Button
              data-testid="review"
              variant="contained"
              fullWidth
              sx={{ height: 48, width: "100%", borderRadius: "24px" }}
              disabled={
                convertPointsTransection.status === ConvertPointsStatus.LOADING
              }
              onClick={handleClick}
            >
              <Typography variant="button" color="background.paper">
                {t("Convert")}
              </Typography>
            </Button>
          </Box>
        </Box>
      </Box>
      <Prompt
        isVisible={promptOpen}
        icon={"error"}
        onClose={promptError.onClose}
        title={promptError.title}
        subTitle={promptError.subtitle}
        rawTitle={promptError.rawTitle}
        rawSubTitle={promptError.rawSubTitle}
        buttonText={promptError.buttonText}
        keyPrefix={"ReviewConvert"}
      />
    </Box>
  );
};
export default ReviewConvert;
