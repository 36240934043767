import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import NftMyCollectionAPI from "apiConfig/NftMyCollectionAPI";

export const status = {
  INITIAL: "INITIAL",
  LOADING: "LOADING",
  LOADED: "LOADED",
  EMPTY: "EMPTY",
  ERROR: "ERROR",
};

const initialState = {
  status: status.INITIAL,
  errorMessage: null,
};

//Thunks
export const fetchAsyncNftMinting = createAsyncThunk(
  "nfts/fetchAsyncNftMinting",
  async (postData, { rejectWithValue }) => {
    try {
      const response = await NftMyCollectionAPI.post(
        `/candy-machines/${postData?.address}/mint-qr`,
        {
          amount: postData?.amount,
        },
      );
      return response.data;
    } catch (err) {
      if (err.response) {
        return rejectWithValue({
          status: err.response.status,
          data: err.response.data,
        });
      }
      return rejectWithValue({
        status: "",
        data: err.message,
      });
    }
  },
);

//Slices
const NftMintingSlice = createSlice({
  name: "nftMinting",
  initialState,
  reducers: {
    resetNftMinting: state => {
      state.status = status.INITIAL;
      state.errorMessage = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchAsyncNftMinting.pending, state => {
        state.errorMessage = null;
        state.status = status.LOADING;
      })
      .addCase(fetchAsyncNftMinting.fulfilled, (state, { payload }) => {
        state.errorMessage = null;
        state.status = status.LOADED;
      })
      .addCase(fetchAsyncNftMinting.rejected, (state, { payload }) => {
        if (payload === undefined) {
          state.status = status.ERROR;
          return;
        }
        state.errorMessage = payload.data;
        state.status = status.ERROR;
      });
  },
});

export const { resetNftMinting } = NftMintingSlice.actions;

export default NftMintingSlice.reducer;
