import React from "react";
import useTranslation from "../../../../hooks/useTranslation";
import { Box, Typography, Button, Avatar } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  useDispatch,
  useSelector,
} from "../../../../redux/store/configureStore";
import merchantIcon from "../../../../assets/icons/merchant.svg";
import MerchantListSkeleton from "../../skeletonLoading/MerchantListSkeleton";
import {
  nextPage,
  fetchAsyncMerchants,
  resetMerchants,
  status as merchantStatus,
} from "../../redux/slices/deals/Merchants";
import useDealsHook from "../../../../hooks/useDealsHook";

const MerchantList = () => {
  const { t } = useTranslation("translation", { keyPrefix: "marketplace" });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const limit = 20;
  const { page, merchants, hasMore, status } = useSelector(
    state => state.merchants,
  );
  const boxWidthRef = React.useRef();
  const flag = React.useRef(true);
  const dealsRef = useDealsHook();

  React.useEffect(() => {
    if (status !== merchantStatus.LOADING) {
      if (!dealsRef.merchantSelected.current) {
        if (window.innerWidth > 800 && flag.current) {
          // eslint-disable-next-line react-hooks/exhaustive-deps
          flag.current = false;
          dispatch(fetchAsyncMerchants({ page, limit }))
            .then(() => {
              boxWidthRef.current.scrollLeft =
                dealsRef.merchantScrollLeft.current;
            })
            .catch(e => {
              console.log(e);
            });
        } else
          dispatch(fetchAsyncMerchants({ page, limit }))
            .then(() => {
              boxWidthRef.current.scrollLeft =
                dealsRef.merchantScrollLeft.current;
            })
            .catch(e => {
              console.log(e);
            });
      } else {
        if (boxWidthRef && boxWidthRef.current) {
          boxWidthRef.current.scrollLeft = dealsRef.merchantScrollLeft.current;
          dealsRef.merchantSelected.current = false;
        }
      }
    }
  }, [page]);

  React.useEffect(() => {
    return () => {
      if (!dealsRef.merchantSelected.current) {
        dealsRef.merchantScrollLeft.current = 0;
        dispatch(resetMerchants());
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (boxWidthRef && boxWidthRef.current) {
      boxWidthRef.current.addEventListener("scroll", () => {
        if (
          boxWidthRef.current.scrollLeft +
            boxWidthRef.current.clientWidth +
            10 >=
            boxWidthRef.current.scrollWidth &&
          hasMore
        ) {
          dealsRef.merchantScrollLeft.current = boxWidthRef.current.scrollLeft;
          dispatch(nextPage(limit));
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasMore]);

  return (
    <Box sx={{ m: "32px 0" }}>
      {status === merchantStatus.LOADING ? (
        <MerchantListSkeleton id={"merchants-loading"} />
      ) : status === merchantStatus.EMPTY ||
        status === merchantStatus.ERROR ? null : merchants &&
        status !== merchantStatus.INITIAL ? (
        <>
          <Box sx={{ mb: "16px" }}>
            <Typography variant="h6">{t("All Merchants")}</Typography>
          </Box>
          <Box
            ref={boxWidthRef}
            sx={{
              bgcolor: "background.paper",
              minHeight: "120px",
              border: 0,
              borderColor: "grey.accent3",
              display: "flex",
              justifyContent:
                merchants.length < 5 ? "flex-start" : "space-between",
              borderRadius: "16px",
              overflow: "auto",
              p: "0 5px",
              my: "24px",
              alignItems: "flex-start",
            }}
          >
            {merchants.map(merchant => {
              return (
                <Button
                  id="card-btn"
                  data-testid={`btn_merchant-${merchant.id}`}
                  key={merchant.id}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    minWidth: "96px",
                    overflow: "hidden",
                    justifyContent: "flex-start",
                  }}
                  onClick={() => {
                    dealsRef.merchantSelected.current = true;
                    dealsRef.merchantScrollLeft.current =
                      boxWidthRef.current.scrollLeft;
                    navigate(`/marketplace/merchant/${merchant.id}`, {
                      state: {
                        name: merchant.name,
                      },
                    });
                  }}
                >
                  {merchant.logo ? (
                    <Avatar
                      data-testid={`img_merchant-logo-${merchant.id}`}
                      sx={{
                        height: "56px",
                        width: "56px",
                        mb: "8px",
                        border: "1px solid #DEE1E9",
                      }}
                      src={merchant.logo}
                    />
                  ) : (
                    <Avatar
                      sx={{
                        height: "56px",
                        width: "56px",
                        mb: "8px",
                        border: "1px solid #DEE1E9",
                      }}
                    >
                      <Box
                        data-testid={`img_merchant-icon-${merchant.id}`}
                        alt="Merchant icon"
                        component="img"
                        src={merchantIcon}
                        sx={{
                          objectFit: "cover",
                          height: "56px",
                          width: "56px",
                        }}
                      />
                    </Avatar>
                  )}

                  <Typography
                    data-testid={`text_merchant-name-${merchant.id}`}
                    color="textPrimary"
                    variant="body2"
                  >
                    {merchant.name}
                  </Typography>
                </Button>
              );
            })}
          </Box>
        </>
      ) : null}
    </Box>
  );
};

export default MerchantList;
