import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import useTranslation from "../../hooks/useTranslation";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { authStatus } from "../../contexts/authContext/AuthContext";
import useKeycloakSwitch from "channel/useKeycloakSwitch";
import useDropDownLanguageHook from "../../hooks/useDropdownLanguageHook";
import { trackOnboarding } from "../../mixpanel";
import useNavigationHook from "hooks/useNavigation";
import { isFusion } from "config";
import FusionOnBoarding from "./FusionOnBoarding";
import EarthOnBoarding from "./EarthOnboarding";
import useEarthNavbarHook from "hooks/useEarthNavbarHook";

const Onboarding = () => {
  const { t } = useTranslation("translation", { keyPrefix: "onboarding" });
  const setEarthNavbar = useEarthNavbarHook();
  const setActiveDropdownLanguage = useDropDownLanguageHook();
  const navigate = useNavigate();
  const { status, initialize } = useAuth();
  const { keycloak } = useKeycloakSwitch();
  const setActiveMenu = useNavigationHook();

  useEffect(() => {
    trackOnboarding();
    setActiveDropdownLanguage(true);
    setActiveMenu(false);
    setEarthNavbar("onboarding");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSignup = () => {
    const lang = localStorage.getItem("lang") || "th";
    if (status === authStatus.GUEST) {
      navigate("/");
      keycloak?.login({ locale: lang });
      return;
    }

    if (status === authStatus.ERROR) {
      initialize();
      navigate("/");
      return;
    }
    navigate("/terms-and-conditions");
  };

  return (
    <>
      <Helmet>
        <title>{t("ABC")}</title>
      </Helmet>
      {isFusion ? (
        <FusionOnBoarding t={t} handleSignup={handleSignup} />
      ) : (
        <EarthOnBoarding t={t} handleSignup={handleSignup} />
      )}
    </>
  );
};

export default Onboarding;
