import React from "react";
import { Box, Typography } from "@mui/material";
import useTranslation from "../../../../hooks/useTranslation";

const RedeemedVoucher = ({ children }) => {
  const { t } = useTranslation("translation", { keyPrefix: "voucherDetail" });
  return (
    <Box sx={{ position: "relative", width: "100%" }}>
      {children}
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "#D9D9D980",
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "16px",
        }}
      >
        <Box
          sx={{
            p: 0.5,
            border: "1px solid #666666",
            borderRadius: "12px",
            width: "100%",
            maxWidth: 187,
            textAlign: "center",
          }}
        >
          <Typography variant="button" fontSize={28}>
            {t("Redeemed")}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default RedeemedVoucher;
