import React, { useEffect, useState, useCallback } from "react";
import { Helmet } from "react-helmet-async";
import { Box, Typography, Button } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import useNavigationHook from "hooks/useNavigation";
import { useDispatch, useSelector } from "redux/store/configureStore";
import { Status } from "redux/constant";
import useTranslation from "hooks/useTranslation";
import Coins from "assets/icons/coins.svg";
import ABCPointIcon from "assets/icons/ABCPoint.svg";
import OtpField from "components/otpField/OtpField";
import { getChannelConfig } from "config";
import {
  fetchAsyncConvertF2CCreateTransaction,
  resetConvertF2CCreateTransaction,
} from "features/wallet/redux/slices/convertF2C/convertF2CCreateTransaction";
import {
  fetchAsyncConvertF2CCheckOtp,
  resetConvertF2CCheckOtp,
} from "features/wallet/redux/slices/convertF2C/convertF2CCheckOtp";
import { toDecimal, coinFormatter } from "utils/coinFormatter";
import {
  trackFailedBuyCoinWithTMNInsufficientFund,
  trackFailedBuyCoinWithTMNOtherCase,
  trackTMNForceOTPResend,
  trackTMNOTPAttempt,
  trackTMNOTPSuccess,
} from "mixpanel/buyCoins";
import LoadingPrompt from "components/loadingPrompt/LoadingPrompt";
import Prompt from "components/Prompt/Prompt";
import {
  fetchAsyncConvertF2CSendOtp,
  resetConvertF2CSendOtp,
} from "features/wallet/redux/slices/convertF2C/convertF2CSendOtp";

dayjs.extend(utc);
dayjs.extend(timezone);

const sourceId = "THB";
const provider = "tmn-otp";
const maximumResendMilisecondIndicator = 120000;
const OtpExpiredCode = "otp_expired";

const ConfirmPurchase = () => {
  const {
    abcBalance,
    payBalance,
    mobileNumber,
    otpRef,
    paymentRef,
    retriesLeft,
    retriesMax,
    validUntil,
  } = useLocation().state || {};
  const setActiveMenu = useNavigationHook();
  const { t } = useTranslation("translation", {
    keyPrefix: "BuyCoins",
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { convertF2CSendOtp, status: convertF2CSendOtpStatus } = useSelector(
    state => state.convertF2CSendOtp,
  );

  const {
    convertF2CCheckOtp,
    status: convertF2CCheckOtpStatus,
    error: convertF2CCheckOtpError,
  } = useSelector(state => state.convertF2CCheckOtp);

  const {
    convertF2CCreateTransaction,
    status: convertF2CCreateTransactionStatus,
    error: convertF2CCreateTransactionError,
  } = useSelector(state => state.convertF2CCreateTransaction);

  const [otpNo, setOtpNo] = useState("");
  const [errorMessage, setErrorMessage] = useState();
  const [currentOtpRef, setCurrentOtpRef] = useState(otpRef);
  const [currentRetriesLeft, setCurrentRetriesLeft] = useState(retriesLeft);
  const [nextActiveResendTime, setNextActiveResendTime] = useState(validUntil);
  const [forceDisableButton, setForceDisableButton] = useState(false);
  const [countdownTime, setCountdownTime] = useState();
  const [promptLoadingOpen, setPromptLoadingOpen] = useState(false);
  const [promptErrorOpen, setPromptErrorOpen] = useState(false);
  const [promptError, setPromptError] = useState({
    title: null,
    subtitle: null,
    buttonText: null,
    onClose: () => null,
  });

  useEffect(() => {
    if (!abcBalance || !mobileNumber) {
      navigate("/wallet/buy-coins");
    } else if (retriesMax === retriesLeft) {
      trackTMNOTPAttempt(1);
    } else if (retriesLeft === 0) {
      setErrorMessage(
        t(
          "Too many incorrect code attempts. Please request for a new code before trying again.",
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, abcBalance, mobileNumber, retriesMax, retriesLeft]);

  useEffect(() => {
    setActiveMenu(true);
  }, [setActiveMenu]);

  useEffect(() => {
    return () => {
      dispatch(resetConvertF2CSendOtp());
      dispatch(resetConvertF2CCheckOtp());
      dispatch(resetConvertF2CCreateTransaction());
    };
  }, [dispatch]);

  const getDiffCurrentTime = nextTime => {
    if (!nextTime) return 0;
    const nextActiveTime = dayjs(nextTime);
    const currentTime = dayjs();
    return nextActiveTime.diff(currentTime);
  };

  useEffect(() => {
    const diff = getDiffCurrentTime(nextActiveResendTime);
    setCountdownTime(diff < 0 ? 0 : diff);
  }, [nextActiveResendTime]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setCountdownTime(oldTime => (oldTime < 1000 ? 0 : oldTime - 1000));
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [countdownTime]);

  const handleGoBackToEnterAccountPage = useCallback(() => {
    dispatch(resetConvertF2CSendOtp());
    dispatch(resetConvertF2CCheckOtp());
    dispatch(resetConvertF2CCreateTransaction());
    navigate("/wallet/buy-coins/account", {
      state: {
        abcBalance,
        payBalance,
      },
    });
  }, [navigate, dispatch, abcBalance, payBalance]);

  const getErrorMessageRetiresLeft = useCallback(
    (statusCode, _retriesLeft, _error) => {
      if (statusCode !== 400 && statusCode !== 420) {
        return "";
      }
      if (_error === OtpExpiredCode) {
        return t("Code expired. Please request a new code.");
      }
      if (_retriesLeft === 0) {
        return t(
          "Too many incorrect code attempts. Please request for a new code before trying again.",
        );
      }
      const attemptLeft = _retriesLeft || 0;
      return `${t("Invalid code. You have only")} ${attemptLeft} ${t(
        "attempts left.",
      )}`;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    if (convertF2CCheckOtpStatus === Status.LOADED) {
      dispatch(
        fetchAsyncConvertF2CCreateTransaction({
          source: sourceId,
          target: getChannelConfig().abcCoinSymbol,
          provider,
          sourceAmount: payBalance,
          targetAmount: abcBalance,
        }),
      );
    } else if (convertF2CCheckOtpStatus === Status.ERROR) {
      setPromptLoadingOpen(false);
      const errorMessage = getErrorMessageRetiresLeft(
        convertF2CCheckOtpError?.statusCode,
        convertF2CCheckOtp?.retriesLeft,
        convertF2CCheckOtpError?.errorMessage?.error,
      );
      if (errorMessage) {
        setErrorMessage(errorMessage);
        setCurrentRetriesLeft(convertF2CCheckOtp?.retriesLeft);
        if (convertF2CCheckOtpError?.errorMessage?.error === OtpExpiredCode) {
          setForceDisableButton(true);
        }
        if (convertF2CCheckOtp?.retriesLeft === 0) {
          trackTMNForceOTPResend();
          setForceDisableButton(true);
          if (
            getDiffCurrentTime(convertF2CCheckOtp?.validUntil) >
            maximumResendMilisecondIndicator
          ) {
            setNextActiveResendTime(convertF2CCheckOtp?.validUntil);
          }
        } else {
          trackTMNOTPAttempt(
            convertF2CCheckOtp?.retriesMax -
              convertF2CCheckOtp?.retriesLeft +
              1,
          );
        }
      } else {
        setPromptError({
          title: "Oops, there was an issue",
          subtitle: "Please try again",
          buttonText: "OK",
          onClose: () => {
            setPromptErrorOpen(false);
            handleGoBackToEnterAccountPage();
          },
        });
        setPromptErrorOpen(true);
      }
    }
  }, [
    convertF2CCheckOtpStatus,
    convertF2CCheckOtp,
    convertF2CCheckOtpError,
    abcBalance,
    payBalance,
    mobileNumber,
    getErrorMessageRetiresLeft,
    dispatch,
    handleGoBackToEnterAccountPage,
  ]);

  useEffect(() => {
    if (convertF2CCreateTransactionStatus === Status.LOADED) {
      trackTMNOTPSuccess();
      setPromptLoadingOpen(false);
      navigate("/wallet/buy-coins/complete", {
        state: {
          getAmount: convertF2CCreateTransaction?.sourceAmount,
          payAmount: convertF2CCreateTransaction?.targetAmount,
          mobileNumber,
          dateTime: convertF2CCreateTransaction?.created_at,
          transactionId: convertF2CCreateTransaction?.sourceTxId,
        },
      });
    } else if (convertF2CCreateTransactionStatus === Status.ERROR) {
      setPromptLoadingOpen(false);
      if (
        convertF2CCreateTransactionError?.errorMessage?.error ===
        "funds_insufficient"
      ) {
        trackFailedBuyCoinWithTMNInsufficientFund(abcBalance);
        setPromptError({
          title: "Insufficient Balance",
          subtitle:
            "Your balance is not enough Please top up the wallet or change the payment method",
          buttonText: "OK",
          onClose: () => {
            setPromptErrorOpen(false);
            handleGoBackToEnterAccountPage();
          },
        });
      } else {
        trackFailedBuyCoinWithTMNOtherCase(
          convertF2CCreateTransactionError?.errorMessage?.error,
        );
        setPromptError({
          title: "Something went wrong",
          subtitle:
            "Please check your transaction history and TrueMoney balance before trying again",
          buttonText: "OK",
          onClose: () => {
            setPromptErrorOpen(false);
            handleGoBackToEnterAccountPage();
          },
        });
      }
      setPromptErrorOpen(true);
    }
  }, [
    convertF2CCreateTransactionStatus,
    convertF2CCreateTransaction,
    convertF2CCreateTransactionError,
    mobileNumber,
    abcBalance,
    dispatch,
    navigate,
    handleGoBackToEnterAccountPage,
  ]);

  useEffect(() => {
    if (convertF2CSendOtpStatus === Status.LOADED) {
      setCurrentOtpRef(convertF2CSendOtp?.otpRef);
      setCurrentRetriesLeft(convertF2CSendOtp?.retriesLeft);
      setNextActiveResendTime(convertF2CSendOtp?.validUntil);
    } else if (convertF2CSendOtpStatus === Status.ERROR) {
      setPromptError({
        title: "Oops, there was an issue",
        subtitle: "Please try again",
        buttonText: "OK",
        onClose: () => setPromptErrorOpen(false),
      });
      setPromptErrorOpen(true);
    }
  }, [convertF2CSendOtpStatus, convertF2CSendOtp, dispatch]);

  const isDisableButton = () => {
    return (
      !otpNo || otpNo.length !== 6 || !currentRetriesLeft || forceDisableButton
    );
  };

  const isInvalidEmptyInput = () => {
    return !otpNo;
  };

  const isInvalidInput = () => {
    return isInvalidEmptyInput();
  };

  const handleClick = () => {
    if (isInvalidInput()) return;

    setPromptLoadingOpen(true);
    dispatch(fetchAsyncConvertF2CCheckOtp({ otp: otpNo }));
  };

  const handleClickCancel = () => {
    navigate("/wallet");
  };

  const handleClickChangeNumber = () => {
    handleGoBackToEnterAccountPage();
  };

  const handleClickResendCode = () => {
    if (convertF2CSendOtpStatus === Status.LOADING) return;
    setErrorMessage("");
    setForceDisableButton(false);
    dispatch(fetchAsyncConvertF2CSendOtp({ mobileNumber }));
  };

  const renderBuyDetail = () => {
    return (
      <>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: "16px",
          }}
        >
          <Typography
            id="lbl_buy"
            variant="body2"
            sx={{ color: "grey.accent2", lineHeight: "24px" }}
          >
            {t("You buy_confirmPurchase")}
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box
              id="img_buy-value"
              alt="Coins"
              component="img"
              src={getChannelConfig().showCoin ? Coins : ABCPointIcon}
              sx={{
                objectFit: "cover",
                height: "24px",
                width: "24px",
                mr: "8px",
              }}
            />
            <Typography id="lbl_buy-value" variant="body2">
              {getChannelConfig().showCoin ? "ABC Coin" : "ABCP"}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: "16px",
          }}
        >
          <Typography
            id="lbl_transactionId"
            variant="body2"
            sx={{ color: "grey.accent2", lineHeight: "24px" }}
          >
            {t("Transaction ID")}
          </Typography>
          <Typography id="lbl_transactionId-value" variant="body2">
            {paymentRef}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: "16px",
          }}
        >
          <Typography
            id="lbl_get"
            variant="body2"
            sx={{ color: "grey.accent2", lineHeight: "24px" }}
          >
            {t("You get_confirmPurchase")}
          </Typography>
          <Typography id="lbl_get-value" variant="body2">
            {coinFormatter(abcBalance?.toString())}{" "}
            {getChannelConfig().showCoin ? "ABC" : "ABCP"}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            id="lbl_pay"
            variant="body2"
            sx={{ color: "grey.accent2", lineHeight: "24px" }}
          >
            {t("You pay_confirmPurchase")}
          </Typography>
          <Typography id="lbl_pay-value" variant="body2">
            {toDecimal(payBalance)} THB
          </Typography>
        </Box>
      </>
    );
  };

  const renderFooter = () => {
    return (
      <Box>
        <Button
          id="btn_confirm"
          variant="contained"
          fullWidth
          sx={{ height: "48px", borderRadius: "24px", mb: "8px" }}
          disabled={isDisableButton()}
          onClick={handleClick}
        >
          <Typography variant="button">{t("CONFIRM")}</Typography>
        </Button>
        <Button
          id="btn_cancel"
          variant="text"
          fullWidth
          sx={{ height: "48px", borderRadius: "24px" }}
          onClick={handleClickCancel}
        >
          <Typography variant="button">{t("CANCEL")}</Typography>
        </Button>
      </Box>
    );
  };

  return (
    <>
      <Helmet>
        <title>{t("Confirm purchase")}</title>
      </Helmet>
      <Box
        sx={{
          py: "24px",
          px: "16px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
        data-testid="confirm-purchase"
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            mb: "24px",
          }}
        >
          <Typography id="lbl_sixDigitCode" variant="body1">
            {t("We sent a 6-digit code to")}
          </Typography>
          <Typography id="lbl_mobileNumber" variant="body1">
            {`${mobileNumber} `}
            <Typography
              id="btn_changeNo"
              variant="subtitle1"
              component={"a"}
              onClick={handleClickChangeNumber}
              sx={{ color: "primary.main", cursor: "pointer" }}
            >
              {t("Change No.")}
            </Typography>
          </Typography>
        </Box>
        <Box sx={{ width: "100%", mb: "24px" }}>{renderBuyDetail()}</Box>
        <Box sx={{ mb: "18px" }}>
          <Typography
            id="lbl_refCode"
            variant="body2"
            sx={{ color: "grey.accent2" }}
          >
            {`${t("Ref code")} - ${currentOtpRef}`}
          </Typography>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            mb: "24px",
          }}
        >
          <Box sx={{ mb: "18px" }}>
            <OtpField
              id="txt_otpField"
              value={otpNo}
              setValue={setOtpNo}
              isError={!!errorMessage}
            ></OtpField>
          </Box>
          {!!errorMessage && (
            <Typography
              id="lbl_errorTooManyAttempts"
              variant="helpingText"
              sx={{ color: "primary.main", textAlign: "center" }}
            >
              {errorMessage}
            </Typography>
          )}
        </Box>
        <Box sx={{ mb: "24px" }}>
          <Typography
            id="lbl_receiveCode"
            variant="body2"
            sx={{ color: "grey.accent2" }}
          >
            {`${t("Didn't receive a code?")} `}
            {!!countdownTime || convertF2CSendOtpStatus === Status.LOADING ? (
              <Typography
                id="lbl_resendCodeCountdown"
                variant="subtitle1"
                component={"span"}
              >
                {t("Resend Code")}
                {!!countdownTime &&
                  ` (${dayjs.utc(countdownTime).format("mm:ss")})`}
              </Typography>
            ) : (
              <Typography
                id="btn_resendCode"
                variant="subtitle1"
                component={"a"}
                onClick={handleClickResendCode}
                sx={{ color: "primary.main", cursor: "pointer" }}
              >
                {t("Resend Code")}
              </Typography>
            )}
          </Typography>
        </Box>
        <Box sx={{ width: "100%" }}>{renderFooter()}</Box>
      </Box>
      <LoadingPrompt
        isVisible={promptLoadingOpen}
        title={"Transaction processing..."}
      />
      <Prompt
        isVisible={promptErrorOpen}
        icon={"error"}
        onClose={promptError.onClose}
        title={promptError.title}
        subTitle={promptError.subtitle}
        buttonText={promptError.buttonText}
        keyPrefix={"BuyCoins"}
      />
    </>
  );
};

export default ConfirmPurchase;
