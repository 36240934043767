import React from "react";
import { Avatar, Box, Typography } from "@mui/material";
import useTranslation from "../../../hooks/useTranslation";
import { ReactComponent as BlankProfileIcon } from "../../../assets/icons/home/blank-profile-picture.svg";

const ProfileSection = ({ user }) => {
  const { t } = useTranslation("translation", { keyPrefix: "homeScreen" });
  let language = localStorage.getItem("lang");
  language === "en-US" && (language = "en");

  const nameTH = user?.firstNameTH ? user?.firstNameTH : user?.firstNameEN;
  const nameEN = user?.firstNameEN ? user?.firstNameEN : user?.firstNameTH;

  return (
    <Box sx={{ display: "flex", flexDirection: "row", mt: "24px", mx: "16px" }}>
      {user?.profilePicture ? (
        <Avatar
          src={user.profilePicture}
          sx={{ width: "60px", height: "60px", mr: "16px" }}
        />
      ) : (
        <Avatar
          sx={{ width: "60px", height: "60px", mr: "16px", background: "none" }}
        >
          <BlankProfileIcon />
        </Avatar>
      )}

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          overflow: "hidden",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            textRendering: "optimizeLegibility",
            // letterSpacing: '-1px'
          }}
          color="grey.accent1"
          noWrap
        >
          {`${t("Hello")} ${language === "th" ? nameTH : nameEN}`}
        </Typography>
        <Typography variant="body2" color="grey.accent1">
          {t("Special deal for you")}
        </Typography>
      </Box>
    </Box>
  );
};

export default ProfileSection;
