export const getPublicChainCollection = abcCollection => {
  return JSON.parse(process.env.REACT_APP_BRIDGE_COLLECTION_PUBLIC)[
    JSON.parse(process.env.REACT_APP_BRIDGE_COLLECTION_ABC).indexOf(
      abcCollection,
    )
  ];
};

export const getResourceIdCollection = abcCollection => {
  return JSON.parse(process.env.REACT_APP_BRIDGE_RESOURCE_ID)[
    JSON.parse(process.env.REACT_APP_BRIDGE_COLLECTION_ABC).indexOf(
      abcCollection,
    )
  ];
};

export const getCollectionFromResourceId = resourceId => {
  return JSON.parse(process.env.REACT_APP_BRIDGE_COLLECTION_ABC)[
    JSON.parse(process.env.REACT_APP_BRIDGE_RESOURCE_ID).indexOf(resourceId)
  ];
};
