import React, { useEffect, useState, useRef, useMemo } from "react";
import { Helmet } from "react-helmet-async";
import { Box, Typography, Button } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import useNavigationHook from "hooks/useNavigation";
import { useDispatch, useSelector } from "redux/store/configureStore";
import useTranslation from "hooks/useTranslation";
import TmnWalletLogo from "assets/icons/company-logos/tmn-wallet-logo.svg";
import { toDecimal } from "utils/coinFormatter";
import ConvertCompleteLayout, {
  DetailItemType,
} from "features/wallet/components/convertCoins/convertCompleteLayout/ConvertCompleteLayout";
import { formatDateTime } from "utils/dateTimeFormatter";
import {
  fetchAsyncCoins,
  resetCoins,
} from "features/wallet/redux/slices/coins/Coins";
import useWindowDimensions from "hooks/useWindowDimensions";
import useNavbarHeight from "hooks/useNavbarHeight";
import { trackCompletedBuyCoinWithTMN } from "mixpanel/buyCoins";

const ConfirmPurchase = () => {
  const { height } = useWindowDimensions();
  const navbarHeight = useNavbarHeight();
  const [footerHeight, setFooterHeight] = useState(0);
  const footerRef = useRef(null);
  useEffect(() => {
    setFooterHeight(footerRef.current.clientHeight);
  }, []);

  const { getAmount, payAmount, mobileNumber, dateTime, transactionId } =
    useLocation().state || {};
  const setActiveMenu = useNavigationHook();
  const { t } = useTranslation("translation", {
    keyPrefix: "BuyCoins",
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { coins, status: coinsStatus } = useSelector(state => state.coins);

  useEffect(() => {
    if (!getAmount) {
      navigate("/wallet");
    } else {
      trackCompletedBuyCoinWithTMN(getAmount);
    }
  }, [navigate, getAmount]);

  useEffect(() => {
    setActiveMenu(true);
  }, [setActiveMenu]);

  useEffect(() => {
    if (!getAmount) return;
    dispatch(fetchAsyncCoins());

    return () => {
      dispatch(resetCoins());
    };
  }, [dispatch, getAmount]);

  const handleClick = () => {
    navigate("/wallet");
  };

  const details = useMemo(() => {
    return [
      {
        title: t("Source of fund"),
        value: (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box
              id="img_sourceLogo"
              alt="logo-image"
              component="img"
              src={TmnWalletLogo}
              sx={{
                objectFit: "cover",
                height: "24px",
                width: "24px",
                mr: "8px",
              }}
            />
            <Typography id="lbl_sourceName" variant="body2">
              {t("TrueMoney Wallet")}
            </Typography>
          </Box>
        ),
      },
      {
        title: t("Source account"),
        value: mobileNumber,
        type: DetailItemType.TEXT,
      },
      {
        title: t("Date & Time"),
        value: formatDateTime(dateTime, "DD MMM YYYY, HH:mm"),
        type: DetailItemType.TEXT,
      },
      {
        title: t("Transaction ID."),
        value: transactionId,
        type: DetailItemType.TEXT,
      },
      {
        title: t("Paid amount"),
        value: `-${toDecimal(payAmount)} ${t("THB")}`,
        type: DetailItemType.TEXT,
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mobileNumber, dateTime, transactionId, payAmount]);

  return (
    <>
      <Helmet>
        <title>{t("Complete")}</title>
      </Helmet>
      <Box
        sx={{
          p: "32px 16px",
          overflow: "auto",
          height: `calc(${height}px - ${navbarHeight}px - ${footerHeight}px - 1px)`,
        }}
        data-testid="buy-coins-complete"
      >
        <ConvertCompleteLayout
          successTitle={t("Successful Purchase")}
          amount={payAmount}
          details={details}
          coinsStatus={coinsStatus}
          coinsBalance={coins?.balance}
          totalBalanceText={t("Total balance")}
        />
      </Box>
      <Box
        ref={footerRef}
        sx={{
          position: "sticky",
          bottom: 0,
          width: "100%",
          padding: "16px 16px 32px 16px",
        }}
      >
        <Button
          id="btn_done"
          variant="contained"
          sx={{ height: "48px", borderRadius: "24px" }}
          fullWidth
          onClick={handleClick}
        >
          {t("Done")}
        </Button>
      </Box>
    </>
  );
};

export default ConfirmPurchase;
