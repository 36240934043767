import React from "react";
import { Box, Typography, Grid } from "@mui/material";
const DealSection = ({ children, dealType }) => {
  return (
    <React.Fragment>
      <Box sx={{ height: "8px", background: "#F5F5F4" }} />
      <Box
        sx={{
          padding: "32px 16px",
        }}
      >
        <Typography
          data-testid={`text_deal-type-${dealType}`}
          variant="h6"
          sx={{ marginBottom: "16px" }}
        >
          {dealType}
        </Typography>
        <Grid
          item
          sx={{
            display: "flex",
            flexDirection: "row",
            overflowY: "auto",
            gap: "16px",
            msOverflowStyle: "none",
            scrollbarWidth: "none",
          }}
        >
          {children}
        </Grid>
      </Box>
    </React.Fragment>
  );
};

export default DealSection;
