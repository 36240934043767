import { IconButton, useMediaQuery, useTheme } from "@mui/material";
import useDrawerMenu from "hooks/useDrawerMenu";
import React from "react";
import { MenuIcon } from "../../assets/icons/menu-icons/MenuIcons";
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";

function showIcon(isNavbar, matchDownMD, activeDrawerMenu) {
  if (isNavbar && matchDownMD && activeDrawerMenu) {
    return <CloseIcon data-testid="close-icon" />;
  }
  return <MenuIcon data-testid="menu-icon" />;
}

const SettingBtn = ({ activeDrawerMenu, isNavbar }) => {
  const setActiveDrawerMenu = useDrawerMenu();
  const utheme = useTheme();
  const matchDownMD = useMediaQuery(utheme.breakpoints.down("md"));

  const handleOnMenuClick = e => {
    e.preventDefault();
    setActiveDrawerMenu(!activeDrawerMenu);
  };

  return (
    <>
      <IconButton
        data-testid="setting-btn"
        sx={{
          borderRadius: 24,
        }}
        onClick={handleOnMenuClick}
      >
        {showIcon(isNavbar, matchDownMD, activeDrawerMenu)}
      </IconButton>
    </>
  );
};

export default SettingBtn;
