import React from "react";
import { Skeleton } from "@mui/material";

const SkeletonMainConvertButton = () => {
  return (
    <Skeleton
      data-testid="btn-convert-skeleton"
      variant="rectangular"
      sx={{
        mx: "20px",
        mt: "16px",
        height: "78px",
        borderRadius: "16px",
      }}
    />
  );
};

export default SkeletonMainConvertButton;
