import { AppBar, Box, Button, Typography } from "@mui/material";
import parser from "html-react-parser";
import FusionBanner from "../../assets/images/OnBoarding/1_1 ABC POINT - Hero.png";

const FusionOnBoarding = ({ t, handleSignup }) => {
  return (
    <>
      <Box
        data-testid="fusion-comp"
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "background.paper",
          pb: "100px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
          }}
        >
          <Box
            sx={{
              backgroundImage: "background.paper",
              display: "grid",
              justifyContent: "center",
              alignItems: "center",
              minHeight: 400,
            }}
          >
            <Box component="img" src={FusionBanner} maxHeight="320px" />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              m: "0 16px",
            }}
          >
            <Box sx={{ m: "16px 0" }}>
              <Typography variant="h6">{parser(t("fusion_title"))}</Typography>
            </Box>
            <Typography variant="pharagraph" sx={{ marginBottom: "16px" }}>
              {parser(t("fusion_desc"))}
            </Typography>
          </Box>
        </Box>
      </Box>
      <AppBar
        position="fixed"
        sx={{
          width: "100%",
          boxShadow: 2,
          top: "auto",
          bottom: 0,
          p: "16px 16px 32px",
          backgroundColor: "background.paper",
        }}
      >
        <Button
          id="terms-btn"
          variant="contained"
          fullWidth
          sx={{ height: 48, width: "100%", borderRadius: "24px" }}
          onClick={handleSignup}
        >
          <Typography variant="button" color="background.paper">
            {t("fusion_sign_up")}
          </Typography>
        </Button>
      </AppBar>
    </>
  );
};

export default FusionOnBoarding;
