import { Avatar, Box, Typography } from "@mui/material";
import { coinFormatter } from "../../../../../utils/coinFormatter";

const ConvertDetail = ({ testid, title, details, unit }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minWidth: "90px",
      }}
    >
      <Box
        sx={{
          mb: "16px",
        }}
      >
        <Typography data-testid={`lbl-title-${testid}`} variant="subtitle">
          {title}
        </Typography>
      </Box>
      <Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Avatar
            data-testid={`img-${testid}`}
            sx={{
              height: "40px",
              width: "40px",
            }}
            src={details?.logo}
          />
          <Box sx={{ ml: "8px" }}>
            <Typography
              data-testid={`lbl-name-${testid}`}
              variant="body1"
              sx={{ mb: "8px" }}
            >
              {details?.name}
            </Typography>
            <Typography
              data-testid={`lbl-balance-${testid}`}
              variant="body1"
              sx={{ mb: "8px" }}
            >
              {`${coinFormatter(details?.balance)} ${unit}`}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ConvertDetail;
