import { Box, Button, Container, Typography } from "@mui/material";
import LoadingPrompt from "components/loadingPrompt/LoadingPrompt";
import PhoneNumberField from "components/PhoneNumberField/PhoneNumberField";
import Prompt from "components/Prompt/Prompt";
import SplitTopBottomLayout from "components/SplitTopBottomLayout/SplitTopBottomLayout";
import { fetchAsyncOtpGenerate } from "features/wallet/redux/slices/accountSetting/editMobileNumber";
import useAuth from "hooks/useAuth";
import useTranslation from "hooks/useTranslation";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { Status } from "redux/constant";
import { useDispatch, useSelector } from "redux/store/configureStore";

export const transformMobile = value =>
  value?.split(" ")?.slice(1)?.join(" ").replace(/\s/g, "").startsWith("0")
    ? value?.split(" ")[0] +
      value?.split(" ")?.slice(1)?.join(" ").replace(/\s/g, "").substring(1)
    : value.replace(/\s/g, "");

const EditMobileNumber = () => {
  const { t } = useTranslation("translation", {
    keyPrefix: "editMobileNumber",
  });
  const { user } = useAuth();
  const navigate = useNavigate();
  const [showOopModal, setShowOopModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [mobileValidate, setIsMobileValidate] = useState({
    isError: false,
    isShow: false,
    message: "",
  });
  const { data, status, errorMessage } = useSelector(
    state => state.editMobileNumber,
  );
  const dispatch = useDispatch();

  const handleOopModalClose = e => {
    e.preventDefault();
    setShowOopModal(false);
  };

  const handleErrorModalClose = e => {
    e.preventDefault();
    setShowErrorModal(false);
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (transformMobile(mobileNumber).length !== 12) {
      setIsMobileValidate({
        isError: true,
        isShow: true,
        message: t("Invalid mobile number"),
      });
      return;
    } else {
      setIsMobileValidate({
        isError: false,
        isShow: false,
        message: "",
      });
    }
    dispatch(
      fetchAsyncOtpGenerate({
        mobileNumber: transformMobile(mobileNumber),
        userid: user.id,
      }),
    );
  };

  useEffect(() => {
    if (data && !errorMessage && status === Status.LOADED) {
      navigate("/settings/account-settings/send-otp", {
        state: {
          mobileNumber,
        },
      });
      return;
    }
    if (
      status === Status.ERROR &&
      errorMessage &&
      errorMessage.statusCode === 400 &&
      errorMessage.message === "this_number_is_already_existed"
    ) {
      setShowOopModal(true);
      return;
    }
    if (status === Status.ERROR && errorMessage) {
      setShowErrorModal(errorMessage.message);
      return;
    }
  }, [data, errorMessage, status]);

  useEffect(() => {
    if (transformMobile(mobileNumber).length === 12) {
      setIsMobileValidate(prev => ({
        ...prev,
        isError: false,
        isShow: false,
        message: "",
      }));
    }
  }, [mobileNumber]);

  return (
    <SplitTopBottomLayout>
      <Helmet>
        <title>{t("Change Mobile Number")}</title>
      </Helmet>
      <Container>
        <Typography variant="subtitle1" sx={{ marginTop: 5, marginBottom: 1 }}>
          {t("Mobile number")}
        </Typography>
        <Box>
          <PhoneNumberField
            data-testid="phonenumber-field"
            value={mobileNumber}
            setValue={setMobileNumber}
            mobileValidate={mobileValidate}
          />
        </Box>
        <Box sx={{ marginTop: 2 }}>
          <Typography variant="body2" sx={{ textAlign: "center" }}>
            {t("We'll SMS you a 6-digit code to this number for verification")}
          </Typography>
        </Box>
      </Container>

      <Container
        id="btn-container"
        sx={{
          marginTop: 10,
          backgroundColor: "background.paper",
          width: "100%",
          padding: "0 16px 32px 16px",
        }}
      >
        <Button
          data-testid="next-btn"
          id="next-btn"
          variant="contained"
          fullWidth
          sx={{ height: 48, width: "100%", borderRadius: "24px" }}
          onClick={handleSubmit}
        >
          <Typography variant="button" color="background.paper">
            {t("NEXT")}
          </Typography>
        </Button>
      </Container>
      {/* Loading Promp */}
      <LoadingPrompt
        isVisible={status === Status.LOADING}
        title={"Processing"}
        keyPrefix={"editMobileNumber"}
      />
      {/* Oop Modal */}
      {showOopModal && (
        <Prompt
          data-testid="oop-modal"
          isVisible
          keyPrefix="editMobileNumber"
          title="Oops!"
          subTitle="Mobile no. already exists.Try another number."
          buttonText="GOT IT"
          onClose={handleOopModalClose}
        />
      )}
      {showErrorModal && (
        <Prompt
          data-testid="error-modal"
          isVisible
          keyPrefix="editMobileNumber"
          rawTitle="Oops!"
          rawSubTitle="Something went wrong"
          buttonText="GOT IT"
          onClose={handleErrorModalClose}
        />
      )}
    </SplitTopBottomLayout>
  );
};

export default EditMobileNumber;
