import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import MainAPI from "apiConfig/MainAPI";
import { serviceEndpoint } from "config";
import { Status } from "../../../../../redux/constant";

const initialState = {
  convertF2CCheckOtp: {},
  status: Status.INITIAL,
  error: null,
};

export const fetchAsyncConvertF2CCheckOtp = createAsyncThunk(
  "convertF2CCheckOtp/fetchAsyncConvertF2CCheckOtp",
  async ({ otp }, { rejectWithValue }) => {
    try {
      const response = await MainAPI.post(
        `${serviceEndpoint.f2c}/providers/tmn-otp/auth/otp`,
        { otp },
      );
      return response.data;
    } catch (err) {
      if (err.response) {
        return rejectWithValue({
          status: err.response.status,
          data: err.response.data,
        });
      }
      return rejectWithValue({
        status: "",
        data: err.message,
      });
    }
  },
);

const ConvertF2CCheckOtpSlice = createSlice({
  name: "convertF2CCheckOtp",
  initialState,
  reducers: {
    resetConvertF2CCheckOtp: state => {
      state.convertF2CCheckOtp = {};
      state.status = Status.INITIAL;
      state.error = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchAsyncConvertF2CCheckOtp.pending, state => {
        state.error = null;
        state.status = Status.LOADING;
      })
      .addCase(fetchAsyncConvertF2CCheckOtp.fulfilled, (state, { payload }) => {
        state.convertF2CCheckOtp = payload;
        state.status = Status.LOADED;
      })
      .addCase(fetchAsyncConvertF2CCheckOtp.rejected, (state, { payload }) => {
        if (payload === undefined) {
          state.status = Status.ERROR;
          return;
        }
        state.convertF2CCheckOtp = payload.data?.info;
        state.error = {
          statusCode: payload.status,
          errorMessage: payload.data,
        };
        state.status = Status.ERROR;
      });
  },
});

export const { resetConvertF2CCheckOtp } = ConvertF2CCheckOtpSlice.actions;

export default ConvertF2CCheckOtpSlice.reducer;
