import React from "react";
import { Box, Typography, Modal, Slide } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import NftAction from "../nftAction";
import NftSaveCard from "../nftSaveCard";
import { styled } from "@mui/system";
import { getFeatureFlags } from "../../../../../config";
import { ReactComponent as SaveNftToken } from "../../../../../assets/icons/save-nft-token.svg";
import { ReactComponent as SaveNft } from "../../../../../assets/icons/save-nft.svg";
import LoadingAnimation from "../../../../../assets/icons/loading.gif";
import SaveIcon from "assets/icons/Save.svg";
import SendIcon from "assets/icons/Send_Base.svg";
import BridgeIcon from "assets/icons/Bridge.svg";
import {
  trackSaveNft,
  trackSaveNftWithId,
  trackTapNftBridge,
} from "mixpanel/nft";
import useTranslation from "hooks/useTranslation";
import { saveImgOnly, saveWithToken } from "./drawCanvas";
import { getPublicChainCollection } from "smartcontract/utils/mapContractToPublic";
import { isMobile } from "react-device-detect";

const NftBox = styled(Box)({
  width: "100%",
  backgroundColor: "#ffffff",
  position: "absolute",
  padding: "16px",
  borderTopLeftRadius: "16px",
  borderTopRightRadius: "16px",
  gap: "16px",
  display: "flex",
  flexDirection: "column",
});

const NftActionModal = ({
  open,
  handleClose,
  collection,
  token,
  imgFileType,
  imgColor,
}) => {
  const [openSheetModal, setOpenSheetModal] = useState(false);
  const [openSaveModal, setOpenSaveModal] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (open) {
      setOpenSheetModal(true);
      setOpenSaveModal(false);
    }
  }, [open]);

  const closeModal = async () => {
    handleClose();
  };

  const handleOnClickSend = () => {
    navigate("/wallet/nfts/my-collection/send-nft", {
      state: {
        collection,
        token,
      },
    });
  };

  const handleOnClickBridge = () => {
    trackTapNftBridge();
    navigate("/wallet/nfts/my-collection/bridge-nft", {
      state: {
        collection,
        token,
      },
    });
  };

  const handleOpenSaveNftModal = () => {
    setOpenSheetModal(false);
    setOpenSaveModal(true);
  };

  const handleClickSaveImage = async () => {
    trackSaveNft(token?.tokenID);
    saveImgOnly(token?.metadata?.image, imgFileType);
  };

  const handleClickSaveWithIdImage = async () => {
    trackSaveNftWithId(token?.tokenID);
    saveWithToken(
      token?.metadata?.image,
      token?.tokenID,
      imgColor,
      imgFileType,
    );
  };

  return (
    <Modal
      data-testid="modal-nft-action"
      open={open}
      disableAutoFocus={true}
      sx={{ display: "flex", alignItems: "flex-end", outline: "none" }}
      BackdropProps={{
        timeout: 450,
      }}
    >
      <Box
        height="375px"
        width="100%"
        border="none"
        borderColor="transparent"
        position="relative"
      >
        <NftSheetModal
          isLoading={imgFileType === ""}
          open={openSheetModal}
          handleCloseAll={closeModal}
          openSaveNftModal={handleOpenSaveNftModal}
          handleOnClickSend={handleOnClickSend}
          handleOnClickBridge={handleOnClickBridge}
          isDisableSave={
            token?.metadata?.animation_url || imgFileType === "image/gif"
          }
          isDisableBridge={
            !getPublicChainCollection(collection.address) ||
            !getFeatureFlags().enableNftBridge || isMobile
          }
        />
        <NftSaveModal
          open={openSaveModal}
          handleCloseAll={closeModal}
          handleSaveImage={handleClickSaveImage}
          handleSaveWithIdImage={handleClickSaveWithIdImage}
        />
      </Box>
    </Modal>
  );
};

const NftSaveModal = ({
  open,
  handleCloseAll,
  handleSaveImage,
  handleSaveWithIdImage,
}) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "nftMyCollection",
  });
  return (
    <Slide direction="up" in={open} timeout={450} bottom="0">
      <NftBox data-testid="modal-nft-save" height="375px">
        <Box textAlign="end">
          <CloseIcon sx={{ cursor: "pointer" }} onClick={handleCloseAll} />
        </Box>
        <Box>
          <Typography variant="h6" mb="8px">
            {t("Save")}
          </Typography>
          <Typography variant="body2" color="grey.accent2">
            {t("How would you like your NFT to display?")}
          </Typography>
        </Box>
        <Box display="flex" sx={{ gap: "15px" }}>
          <NftSaveCard
            id="nft-save-image-only"
            title={t("Image only")}
            icon={<SaveNft />}
            handleAction={handleSaveImage}
          />
          <NftSaveCard
            id="nft-save-image-token"
            title={t("Image with Token ID")}
            icon={<SaveNftToken />}
            handleAction={handleSaveWithIdImage}
          />
        </Box>
      </NftBox>
    </Slide>
  );
};

const NftSheetModal = ({
  isLoading,
  open,
  handleCloseAll,
  openSaveNftModal,
  handleOnClickSend,
  handleOnClickBridge,
  isDisableSave,
  isDisableBridge,
}) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "nftMyCollection",
  });
  return (
    <Slide direction="up" in={open} timeout={450} bottom="0">
      <NftBox data-testid="modal-nft-sheet" height="254px">
        <Box textAlign="end">
          <CloseIcon sx={{ cursor: "pointer" }} onClick={handleCloseAll} />
        </Box>
        <Typography variant="h6">{t("Want to")}</Typography>

        {isLoading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
          >
            <Box
              data-testid="nft-loading-action"
              component="img"
              height="25px"
              src={LoadingAnimation}
            />
          </Box>
        ) : (
          <Box display="flex" justifyContent="space-between">
            <NftAction
              id="nft-action-send"
              title={t("Send")}
              icon={<Box component="img" src={SendIcon} />}
              handleAction={handleOnClickSend}
            />
            <NftAction
              id="nft-action-save"
              icon={<Box component="img" src={SaveIcon} />}
              title={t("Save")}
              handleAction={openSaveNftModal}
              disabled={isDisableSave}
            />
            <NftAction
              id="nft-action-bridge"
              icon={<Box component="img" src={BridgeIcon} />}
              title={t("Bridge")}
              handleAction={handleOnClickBridge}
              disabled={isDisableBridge}
            />
          </Box>
        )}
      </NftBox>
    </Slide>
  );
};

export default NftActionModal;
