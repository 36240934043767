import React from "react";
import { Box, Button, styled, Typography } from "@mui/material";
import { ReactComponent as ConvertIcon } from "../../../../../../assets/icons/convert-button-icon.svg";

const Circle = styled(Box)`
  display: flex;
  justify-content: center;
  align-self: center;
  box-sizing: border-box;
  position: absolute;
  background-color: hsl(0deg 0% 100% / 70%);
  border-radius: 50%;
  overflow: hidden;
  height: 111px;
  right: -8px;
  padding: 12px;
  border-top: none;
  border-right: none;
  border-bottom: none;
`;

const MainConvertButton = ({ onClick, text, logo, logoSx = {} }) => {
  return (
    <Box
      sx={{
        mx: "20px",
        mt: "16px",
      }}
    >
      <Button
        data-testid="convert-loyalty-point-btn"
        onClick={onClick}
        sx={{
          background:
            "radial-gradient(237.96% 674.61% at -19.24% -78.67%, #FB7029 22.24%, #FFCA4B 100%)",
          width: "100%",
          borderRadius: "16px",
          display: "flex",
          px: "16px",
          py: "0px",
          justifyContent: "flex-start",
        }}
      >
        <Box
          sx={{
            padding: "12px",
            borderRadius: "50px",
            backgroundColor: "background.paper",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ConvertIcon />
        </Box>
        <Typography
          variant="subtitle"
          sx={{ ml: "8px", my: "25px", color: "grey.accent5" }}
        >
          {text}
        </Typography>
        <Circle>
          <Box
            sx={{
              width: "87px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              component="img"
              src={logo}
              sx={{
                height: "60px",
                width: "60px",
                ...logoSx,
              }}
            />
          </Box>
        </Circle>
      </Button>
    </Box>
  );
};

export default MainConvertButton;
