import React, { useEffect } from "react";
import {
  Box,
  Typography,
  List,
  ListItemText,
  ListItemIcon,
  Divider,
  ListItemButton,
  Button,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import useTranslation from "../../hooks/useTranslation";
import { Helmet } from "react-helmet-async";

import { ReactComponent as SettingsIcon } from "../../assets/icons/settings-tnc.svg";
import { ReactComponent as PrivacyIcon } from "../../assets/icons/settings-privacy.svg";
import { ReactComponent as HelpIcon } from "../../assets/icons/settings-help.svg";
import { ReactComponent as ContactUsIcon } from "../../assets/icons/settings-contact-us.svg";
import { ReactComponent as PrivacySettingIcon } from "../../assets/icons/privacy-setting.svg";
import { ReactComponent as AccountSettingIcon } from "../../assets/icons/account-settings-icon/account_setting.svg";
import LinkIcon from "@mui/icons-material/Link";
import { useNavigate } from "react-router-dom";
import { CollectionPoint } from "../../constants/consent";
import useKeycloakSwitch from "channel/useKeycloakSwitch";
import AuthContextService from "../../contexts/authContext/AuthContextServices";
import { trackLogOut, trackManageLinkedAccount } from "../../mixpanel";
import useNavigationHook from "../../hooks/useNavigation";
import { getChannelConfig, getFeatureFlags } from "../../config";
import useDrawerMenu from "hooks/useDrawerMenu";
import CloseIcon from "@mui/icons-material/Close";
import { menuList } from "components/navigation/Menu";
import { styled } from "@mui/system";
import { paletteLight } from "theme";

const BigDivinder = styled(Divider)`
  border-width: 4px;
  border-color: ${paletteLight.grey.accent4};
  margin: 32px 0px;
`;

const { faqLink, customerSupport } =
  getChannelConfig();

export const openFAQsLink = () => {
  window.open(faqLink, "_blank", "width=400,height=600");
};

const Settings = ({ level }) => {
  const navigate = useNavigate();
  const setActiveMenu = useNavigationHook();
  const setActiveDrawerMenu = useDrawerMenu();
  const utheme = useTheme();
  const matchDownMD = useMediaQuery(utheme.breakpoints.down("md"));
  const { t } = useTranslation("translation", { keyPrefix: "menu" });
  const { keycloak } = useKeycloakSwitch();

  const { enableTeslaSprint16_08_09_2021, enableAccountSetting } = getFeatureFlags()

  useEffect(() => {
    setActiveMenu(true);
  }, []);

  const settingMenuItems = [];

  if (enableAccountSetting) {
    settingMenuItems.push({
      id: "account-settings",
      icon: <AccountSettingIcon />,
      onClick: () => {
        setActiveDrawerMenu(false);
        navigate("/settings/account-settings");
      },
      label: t("Account"),
    });
  }

  if (enableTeslaSprint16_08_09_2021) {
    settingMenuItems.push({
      id: "linked-rewards-account",
      icon: <LinkIcon sx={{ color: "black" }} />,
      onClick: () => {
        setActiveDrawerMenu(false);
        trackManageLinkedAccount(window.location.href);
        navigate("/settings/linked-rewards");
      },
      label: t("Linked rewards account"),
    });
  }

  settingMenuItems.push({
    id: "privacy-settings",
    icon: <PrivacySettingIcon />,
    onClick: () => {
      setActiveDrawerMenu(false);
      navigate("/settings/privacy-settings", {
        state: { from: CollectionPoint.PRIVACY_SETTING },
      });
    },
    label: t("Privacy settings"),
  });

  const menuItems = [
    {
      id: "settings-terms",
      icon: <SettingsIcon />,
      onClick: () => {
        setActiveDrawerMenu(false);
        navigate("/settings/terms-and-conditions");
      },
      label: t("Terms of service"),
    },
    {
      id: "settings-privacy",
      icon: <PrivacyIcon />,
      onClick: () => {
        setActiveDrawerMenu(false);
        navigate("/settings/privacy-policy");
      },
      label: t("Privacy notice"),
    },
    {
      id: "settings-help",
      icon: <HelpIcon />,
      onClick: e => {
        e.preventDefault();
        setActiveDrawerMenu(false);
        openFAQsLink();
      },
      label: t("Help"),
    },
    {
      id: "settings-contact",
      icon: <ContactUsIcon />,
      onClick: () => {
        setActiveDrawerMenu(false);
      },
      label: t("Contact Us"),
      text: customerSupport,
      listItemButtonProps: {
        component: "a",
        target: "_blank",
        href: `mailto:${customerSupport}`,
      },
    },
  ];

  const handleDrawerMenuCloseClick = e => {
    e.preventDefault();
    setActiveDrawerMenu(false);
  };

  const handleLogoutClick = e => {
    e.preventDefault();
    trackLogOut();
    keycloak.logout();
    AuthContextService.clearTokenFromLocalStorage();
  };

  const handleServiceLinkClick = href => {
    setActiveDrawerMenu(false);
    navigate(href);
  };

  const renderMenuListItem = (item, isLast) => {
    return (
      <Box data-testid={item.id} key={item.id}>
        <ListItemButton
          id={item.id}
          TouchRippleProps={{ sx: { color: "primary.main" } }}
          sx={{ p: "16px 15px" }}
          onClick={item.onClick}
          {...item.listItemButtonProps}
        >
          <ListItemIcon sx={{ mr: "11px" }}>{item.icon}</ListItemIcon>
          <ListItemText
            primary={item.label}
            primaryTypographyProps={{
              variant: "body2",
              color: "grey.accent1",
            }}
            sx={{}}
          />

          {item.text ? (
            <Typography variant="body2">{item.text}</Typography>
          ) : (
            <ListItemIcon sx={{ m: "0px" }}>
              <ArrowForwardIosIcon
                sx={{
                  height: "20px",
                  width: "20px",
                  color: "grey.accent1",
                }}
              />
            </ListItemIcon>
          )}
        </ListItemButton>
        {!isLast ? <Divider /> : null}
      </Box>
    );
  };

  return (
    <>
      <Helmet>
        <title>{t("Menu")}</title>
      </Helmet>
      {matchDownMD && (
        <>
          <Box>
            <Typography
              variant="h5"
              sx={{ marginTop: "32px", paddingLeft: "16px" }}
            >
              {t("Services")}
            </Typography>
            <List>
              {menuList.map(({ name, src, href }, index) => {
                return (
                  <ListItemButton
                    key={`${name}-${index}`}
                    onClick={e => {
                      e.preventDefault();
                      handleServiceLinkClick(href);
                    }}
                  >
                    <ListItemIcon sx={{ mr: "11px" }}>{src}</ListItemIcon>
                    <ListItemText
                      primary={t(name)}
                      primaryTypographyProps={{
                        variant: "body2",
                        color: "grey.accent1",
                      }}
                    />
                  </ListItemButton>
                );
              })}
            </List>
          </Box>
          <BigDivinder />
        </>
      )}

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        {!matchDownMD && (
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <IconButton onClick={handleDrawerMenuCloseClick}>
              <CloseIcon />
            </IconButton>
          </Box>
        )}

        <Box
          sx={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            backgroundColor: "background.paper",
            p: "8px 16px 0px 16px",
          }}
        >
          <Box>
            <Typography variant="h6" color="grey.accent1">
              {t("Setting")}
            </Typography>
            <Box
              sx={{
                display: "flex",
                mt: "8px",
                backgroundColor: "background.paper",
                p: "0px",
                borderRadius: "16px",
                border: "1px solid",
                borderColor: "grey.accent3",
                overflow: "hidden",
              }}
            >
              <List sx={{ width: "100%", p: "0px" }}>
                {settingMenuItems.map((item, index) =>
                  renderMenuListItem(item, index >= menuItems.length - 1),
                )}
              </List>
            </Box>
          </Box>
        </Box>
        <BigDivinder />
        <Box
          sx={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            backgroundColor: "background.paper",
            p: "0 16px 32px 16px",
          }}
        >
          <Typography variant="h6" color="grey.accent1">
            {t("Help & Policies")}
          </Typography>
          <Box
            sx={{
              display: "flex",
              mt: "8px",
              backgroundColor: "background.paper",
              p: "0px",
              borderRadius: "16px",
              border: "1px solid",
              borderColor: "grey.accent3",
              overflow: "hidden",
            }}
          >
            <List sx={{ width: "100%", p: "0px" }}>
              {menuItems.map((item, index) =>
                renderMenuListItem(item, index >= menuItems.length - 1),
              )}
            </List>
          </Box>
          {AuthContextService.getTmwTokenRefFromLocalStorage() ? (
            ""
          ) : (
            <Box marginTop={4}>
              <Button
                data-testid="logout-btn"
                fullWidth
                variant="outlined"
                onClick={handleLogoutClick}
                sx={{ borderRadius: "24px" }}
              >
                {t("LOG OUT")}
              </Button>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export default Settings;
