import React from "react";
import { Box, Typography, Skeleton, Button } from "@mui/material";
import { coinFormatter } from "../../../utils/coinFormatter";
import { ReactComponent as CoinIcon } from "../../../assets/icons/coins.svg";
import { ReactComponent as ABCPointIcon } from "../../../assets/icons/ABCPoint.svg";
import useTranslation from "../../../hooks/useTranslation";
import { useNavigate } from "react-router-dom";
import { getChannelConfig } from "config";

const BalanceCard = ({ isLoading, isError, balance }) => {
  const { t } = useTranslation("translation", { keyPrefix: "homeScreen" });
  const navigate = useNavigate();
  return (
    <Button
      id="home-balance-btn"
      onClick={() => navigate("/wallet")}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        borderRadius: "16px",
        border: "1px solid",
        borderColor: "grey.accent3",
        p: "16px",
        mt: "32px",
        mx: "16px",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        {getChannelConfig().showCoin ? <CoinIcon /> : <ABCPointIcon />}
        <Typography sx={{ ml: "12px" }} variant="subtitle" color="grey.accent1">
          {getChannelConfig().showCoin ? t("ABC Coin") : t("ABC Point")}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "flex-end",
        }}
      >
        {isLoading ? (
          <Skeleton animation="wave" width="40%" height="40px" />
        ) : isError ? (
          <Typography variant="body1" color="grey.accent2" sx={{ mb: "32px" }}>
            {t("Unable to retrieve balance")}
          </Typography>
        ) : (
          <>
            <Typography variant="h5" color="grey.accent1">
              {coinFormatter(balance)}
            </Typography>
            <Typography variant="body2" color="grey.accent2">
              {t("Balance")}
            </Typography>
          </>
        )}
      </Box>
    </Button>
  );
};

export default BalanceCard;
