import {
  Typography,
  Box,
  DialogTitle,
  DialogContent,
  Dialog,
  Link,
  IconButton,
  Grid,
  Button,
} from "@mui/material";
import React from "react";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import CloseIcon from "@mui/icons-material/Close";
import { useWeb3React, UnsupportedChainIdError } from "@web3-react/core";
import { injected } from "smartcontract/utils/connector";
import { isMobile } from "react-device-detect";
import MetaMaskIcon from "assets/icons/metamask_icon.svg";

const generateMobileMetamask = () =>
  `https://metamask.app.link/dapp/` +
  window.location.href.replace("https://", "");

const ConnectWalletModal = ({ open, handleClose }) => {
  const { activate, setError } = useWeb3React();

  const connectWalletHandler = async () => {
    if (window.ethereum) {
      await activate(injected, async error => {
        if (error instanceof UnsupportedChainIdError) {
          try {
            setError(error);
            await window.ethereum.request({
              method: "wallet_switchEthereumChain",
              params: [
                {
                  chainId: `0x${parseInt(
                    process.env.REACT_APP_CHAIN_ID || "1",
                    10,
                  ).toString(16)}`,
                },
              ],
            });
            activate(injected);
          } catch (error) {}
        }
      });
      handleClose();
    } else {
      if (isMobile) {
        window.open(generateMobileMetamask());
      } else {
        window.open("https://metamask.io/download/", "_blank");
      }
    }
  };

  return (
    <Dialog
      data-testid="dialog_connect-wallet"
      open={open}
      onClose={handleClose}
      PaperProps={{
        style: { borderRadius: "16px" },
      }}
    >
      <DialogTitle>
        <Grid container direction="row" justifyContent="space-between">
          <Box display="flex" alignItems="center" sx={{ gap: "8px" }}>
            <AccountBalanceWalletIcon />
            <Typography variant="h6">Select a Wallet</Typography>
          </Box>
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          Please select a wallet to connect to this dapp:
        </Typography>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          mt="1rem"
          mb="1rem"
          p="1rem"
          sx={{ gap: "8px" }}
        >
          <Box component="img" src={MetaMaskIcon} height="48px" />
          <Typography variant="h5">MetaMask</Typography>
        </Box>
        <Box display="flex" justifyContent="center">
          <Button
            data-testid="btn_connect-metamask"
            variant="contained"
            sx={{ my: 2, textTransform: "none", borderRadius: "24px" }}
            onClick={connectWalletHandler}
          >
            <Typography variant="button">CONNECT WALLET</Typography>
          </Button>
        </Box>
        <Typography mb="1rem" variant="body1">
          What is wallet?
        </Typography>
        <Typography variant="helpingText" color="grey.accent1">
          Wallet are used to send, receive, and store digital assets like Ether.
          Wallets come in many forms. They are either built into your browser,
          an extension added to your browser, a piece of hardware plugged into
          your computer or even an app on your phone. For more information about
          the wallets,&nbsp;
          <Link
            underline="hover"
            href="https://docs.ethhub.io/using-ethereum/wallets/intro-to-ethereum-wallets/"
            target="_blank"
            color="#000000"
          >
            see this explanation
          </Link>
          .
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

export default ConnectWalletModal;
