import React, { useEffect, useState } from "react";
import { List, ListItem, Skeleton, Typography, Box } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import useTranslation from "../../../../hooks/useTranslation";
import { useWeb3React } from "@web3-react/core";
import { trackTapConnectWallet } from "mixpanel/nft";
import {
  walletconnect,
  resetWalletConnector,
} from "smartcontract/utils/connector";
import { isMobile } from "react-device-detect";
import ConnectWalletModal from "components/connectWalletModal/ConnectWalletModal";
import { fetchAsyncNftBridgePending } from "features/wallet/redux/slices/nftMyCollectionBridge/nftMyCollectionBridge";
import { useDispatch, useSelector } from "react-redux";
import {
  getCollectionFromResourceId,
  getPublicChainCollection,
} from "smartcontract/utils/mapContractToPublic";
import { status as NftMyCollectionBridgeStatus } from "features/wallet/redux/slices/nftMyCollectionBridge/nftMyCollectionBridge";
import GalleryServices from "smartcontract/services/galleryService";
import PublicAPI from "apiConfig/PublicAPI";
import { ReactComponent as DefaultImage } from "assets/images/default-image.svg";
import LoadingPrompt from "components/loadingPrompt/LoadingPrompt";

function NftPendingBridge() {
  const { t } = useTranslation("translation", {
    keyPrefix: "bridgeNft",
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { statusPendingBridge, pendingBridge } = useSelector(
    state => state.nftMyCollectionBridge,
  );
  const { activate, active, deactivate, chainId } = useWeb3React();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isClearConnected, setIsClearConnected] = useState(false);
  const [tokenSelected, setTokenSelected] = useState();
  const [collectionSelected, setCollectionSelected] = useState();

  useEffect(() => {
    dispatch(fetchAsyncNftBridgePending());
  }, [dispatch]);

  useEffect(() => {
    if (
      active &&
      isClearConnected &&
      chainId == process.env.REACT_APP_CHAIN_ID
    ) {
      navigate("/wallet/nfts/my-collection/bridge-review-nft", {
        state: {
          collection: collectionSelected,
          token: tokenSelected,
          isPending: true,
        },
      });
    } else {
      setIsClearConnected(true);
      deactivate();
    }
  }, [active]);

  const CreatorItemSkeleton = () => {
    return (
      <ListItem
        data-testid="skeleton-list-item"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box sx={{ ml: "8px" }}>
            <Skeleton variant="text" width={"100px"} />
          </Box>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Skeleton variant="text" width={"40px"} />
        </Box>
      </ListItem>
    );
  };

  const CreatorItem = ({ id, data }) => {
    const { tokenId, collection, status } = data;
    return (
      <ListItem
        data-testid={`list_item-${id}`}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
        onClick={() => {
          if (status === "READY_WITHDRAW" && !isMobile)
            handleOnClickPendingToken(tokenId, collection);
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box sx={{ ml: "8px" }}>
            <Typography data-testid={`text_token-${id}`} variant="subtitle">
              {`${t("Token ID")} #${tokenId.toString().padStart(3, "0")}`}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box
            bgcolor={
              status === "READY_WITHDRAW"
                ? "secondary.green.main"
                : "secondary.yellow.main"
            }
            borderRadius="8px"
            p="8px"
          >
            <Typography data-testid={`text_status-${id}`} variant="body2">
              {status === "READY_WITHDRAW"
                ? t("Ready to Withdraw")
                : t("Pending")}
            </Typography>
          </Box>
        </Box>
      </ListItem>
    );
  };

  const handleOnClickPendingToken = async (
    selectedToken,
    selectedCollection,
  ) => {
    try {
      setLoading(true);
      const contract = new GalleryServices(
        getPublicChainCollection(selectedCollection),
      );
      const tokenUriresponse = await contract.getTokenURI(
        parseInt(selectedToken),
      );

      const nftInfo = await PublicAPI.get(tokenUriresponse);
      setLoading(false);
      setTokenSelected({
        tokenID: selectedToken,
        metadata: {
          name: nftInfo?.data?.name,
          description: nftInfo?.data?.description,
          image: nftInfo?.data?.image,
        },
      });
      setCollectionSelected({
        address: selectedCollection,
      });
      handleConnectWalet();
    } catch (error) {
      setLoading(false);
    }
  };

  const handleConnectWalet = async () => {
    trackTapConnectWallet();
    if (isMobile) {
      await resetWalletConnector(walletconnect);
      await activate(walletconnect);
    } else {
      setOpen(true);
    }
  };

  return (
    <>
      <Helmet>
        <title>{t("NFT")}</title>
      </Helmet>
      <Box>
        <List
          sx={{ width: "100%", bgcolor: "background.paper" }}
          component="nav"
          aria-labelledby="nft-list"
        >
          <>
            {statusPendingBridge === NftMyCollectionBridgeStatus.LOADING ? (
              <>
                <CreatorItemSkeleton />
                <CreatorItemSkeleton />
                <CreatorItemSkeleton />
              </>
            ) : pendingBridge?.length === 0 ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Box sx={{ mb: "24px" }}>
                  <DefaultImage />
                </Box>
                <Box sx={{ mb: "8px" }}>
                  <Typography variant="h6">
                    {t("You have no pending transaction")}
                  </Typography>
                </Box>
              </Box>
            ) : (
              pendingBridge?.map(token => {
                return (
                  <CreatorItem
                    key={`pending-${token?.id}`}
                    id={`pending-${token?.id}`}
                    data={{
                      tokenId: token?.tokenId,
                      status: token?.status,
                      collection: getCollectionFromResourceId(
                        token?.resourceId,
                      ),
                    }}
                  />
                );
              })
            )}
          </>
        </List>
      </Box>
      <ConnectWalletModal open={open} handleClose={() => setOpen(false)} />
      <LoadingPrompt rawTitle={t("Bridging...")} isVisible={loading} />
    </>
  );
}

export default NftPendingBridge;
