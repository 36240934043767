import {
  AppBar,
  Box,
  Button,
  Container,
  FormControl,
  FormHelperText,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import SplitTopBottomLayout from "components/SplitTopBottomLayout/SplitTopBottomLayout";
import {
  fetchAsyncEditUsername,
  resetEditUsername,
} from "features/wallet/redux/slices/accountSetting/editUsername";
import useAuth from "hooks/useAuth";
import useTranslation from "hooks/useTranslation";
import React, { useState } from "react";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { Status } from "redux/constant";
import { useDispatch, useSelector } from "redux/store/configureStore";
import getLanguage from "utils/getLanguage";
import { getUsername } from "./AccountSettings";

const CssTextField = styled(TextField)({
  "& ::placeholder": {
    fontSize: "14px",
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: "16px",
    input: {
      padding: "16px",
      fontSize: "14px",
    },
  },
});

const regEx = new RegExp("^([a-zA-Zก-์_-])+$", "i");

const EditUsername = () => {
  const dispatch = useDispatch();
  const { user, updateUsername } = useAuth();
  const lang = getLanguage();
  const username = getUsername(user, lang);
  const navigate = useNavigate();
  const [userState, setUserState] = useState(username);
  const [errorMessage, setErrorMessage] = useState({
    firstname: "",
    lastname: "",
  });
  const { t } = useTranslation("translation", { keyPrefix: "editUsername" });
  const { data: editUsernameResponse, status: editUsernameStatus } =
    useSelector(state => state.editUsername);

  const handleCancelClick = () => {
    navigate(-1);
  };

  const handleInputChange = e => {
    const { name, value } = e.target;
    setUserState(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = e => {
    e.preventDefault();
    const { firstname, lastname } = userState;
    let firstnameError = "";
    let lastnameError = "";

    if (!regEx.test(firstname)) {
      firstnameError = t("enter_alphabet");
    }
    if (!regEx.test(lastname)) {
      lastnameError = t("enter_alphabet");
    }

    if (!firstname) {
      firstnameError = t("empty_firstname");
    }
    if (!lastname) {
      lastnameError = t("empty_lastname");
    }
    if (!firstnameError && !lastnameError) {
      dispatch(
        fetchAsyncEditUsername({ userId: user.id, firstname, lastname }),
      );
    }
    setErrorMessage({ firstname: firstnameError, lastname: lastnameError });
  };

  useEffect(() => {
    if (editUsernameStatus === Status.LOADED) {
      updateUsername(editUsernameResponse);
      navigate(-1);
    }
  }, [editUsernameStatus, navigate, editUsernameResponse]);

  useEffect(() => {
    return () => {
      dispatch(resetEditUsername());
    };
  }, []);

  return (
    <SplitTopBottomLayout>
      <Container id="form-container" sx={{ marginTop: 5 }}>
        <Helmet>
          <title>{t("Edit Name")}</title>
        </Helmet>
        <FormControl disabled error variant="standard" fullWidth>
          <Typography sx={{ marginBottom: 1 }} variant="subtitle1">
            {t("First name")}
          </Typography>
          <CssTextField
            disabled={editUsernameStatus === Status.LOADING}
            fullWidth
            variant="outlined"
            placeholder={t("First name")}
            name="firstname"
            value={userState.firstname}
            onChange={handleInputChange}
            inputProps={{ maxLength: 35 }}
          />
          <FormHelperText>{errorMessage.firstname}</FormHelperText>
        </FormControl>
        <FormControl error variant="standard" fullWidth sx={{ marginTop: 2 }}>
          <Typography sx={{ marginBottom: 1 }} variant="subtitle1">
            {t("Last name")}
          </Typography>
          <CssTextField
            disabled={editUsernameStatus === Status.LOADING}
            fullWidth
            variant="outlined"
            placeholder={t("Last name")}
            name="lastname"
            value={userState.lastname}
            onChange={handleInputChange}
            inputProps={{ maxLength: 35 }}
          />
          <FormHelperText>{errorMessage.lastname}</FormHelperText>
        </FormControl>
      </Container>
      <Container
        id="btn-container"
        sx={{
          marginTop: 10,
          backgroundColor: "background.paper",
          width: "100%",
          padding: "0 16px 32px 16px",
        }}
      >
        <Button
          disabled={editUsernameStatus === Status.LOADING}
          id="save-btn"
          variant="contained"
          fullWidth
          sx={{ height: 48, width: "100%", borderRadius: "24px" }}
          onClick={handleSubmit}
        >
          <Typography variant="button" color="background.paper">
            {t("SAVE")}
          </Typography>
        </Button>
        <Button
          disabled={editUsernameStatus === Status.LOADING}
          id="cancel-btn"
          variant="text"
          fullWidth
          sx={{
            height: 48,
            width: "100%",
            borderRadius: "24px",
            marginTop: 2,
          }}
          onClick={handleCancelClick}
        >
          <Typography variant="button" color="background.red">
            {t("CANCEL")}
          </Typography>
        </Button>
      </Container>
    </SplitTopBottomLayout>
  );
};

export default EditUsername;
