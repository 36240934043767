import { useMemo } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import LinkIcon from "@mui/icons-material/Link";
import { Status } from "../../../../redux/constant";
import { coinFormatter } from "../../../../utils/coinFormatter";

const PartnerButton = ({
  partnerId,
  partnerName,
  partnerNameSx,
  partnerImage,
  onClick,
  isLinked,
  havePoint,
  pointBalance,
  pointBalanceStatus,
  pointUnit,
  onlyUI,
  id = partnerId,
}) => {
  const renderPoint = () => {
    return (
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <Typography
          id={`lbl_pointBalance_${id}`}
          variant="body2"
          sx={{ color: "grey.accent2" }}
        >
          {pointBalanceStatus === Status.LOADED
            ? coinFormatter(pointBalance?.toString())
            : "-"}
        </Typography>
        <Typography
          id={`lbl_pointUnit_${id}`}
          variant="body2"
          sx={{ color: "grey.accent2", ml: "4px" }}
        >
          {pointUnit || ""}
        </Typography>
      </Box>
    );
  };

  const renderLinkedPartner = () => {
    return (
      <Box
        id={`icon_linked_${id}`}
        sx={{
          position: "absolute",
          width: "24px",
          height: "24px",
          top: "-8px",
          right: "-8px",
          bgcolor: "success.main",
          borderRadius: "50%",
        }}
        data-testid="linked-icon"
      >
        <LinkIcon fontSize="small" sx={{ color: "text.primary" }} />
      </Box>
    );
  };

  const ContainerComp = useMemo(() => (onlyUI ? Box : IconButton), [onlyUI]);

  return (
    <ContainerComp
      id={`btn_${id}`}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        p: "0px",
      }}
      onClick={onClick}
      data-testid="partner-button"
    >
      <Box sx={{ position: "relative" }}>
        <Box
          id={`img_btn_${id}`}
          component="img"
          src={partnerImage}
          alt={`${partnerId}-icon`}
          sx={{
            height: "56px",
            width: "56px",
          }}
        />
        {isLinked && renderLinkedPartner()}
      </Box>
      <Typography
        id={`lbl_btn_${id}`}
        variant="body2"
        sx={{ marginTop: "8px", color: "text.primary", ...partnerNameSx }}
      >
        {partnerName}
      </Typography>
      {havePoint && renderPoint()}
    </ContainerComp>
  );
};

export default PartnerButton;
