import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import KeycloakAPI from "apiConfig/KeycloakAPI";
import { Status } from "../../../../../redux/constant";
import { chain } from "lodash";

const initialState = {
  data: null,
  status: Status.INITIAL,
  errorMessage: null,
};

export const fetchKeycloakLinkedAccount = createAsyncThunk(
  "keycloak/fetchKeycloakLinkedAccount",
  async (_, { rejectWithValue }) => {
    try {
      const response = await KeycloakAPI.get("/account/linked-accounts");
      return response.data;
    } catch (err) {
      if (err.response) {
        return rejectWithValue({
          status: err.response.status,
          data: err.response.data,
        });
      }
      return rejectWithValue({
        status: "",
        data: err.message,
      });
    }
  },
);

const KeycloakLinkedAccountSlice = createSlice({
  name: "keycloakLinkedAccount",
  initialState,
  reducers: {
    resetKeycloakLinkedAccount: state => {
      state.data = null;
      state.status = Status.INITIAL;
      state.errorMessage = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchKeycloakLinkedAccount.pending, state => {
        state.errorMessage = null;
        state.status = Status.LOADING;
      })
      .addCase(fetchKeycloakLinkedAccount.fulfilled, (state, { payload }) => {
        state.data = chain(payload)
          .filter(
            ({ providerName }) =>
              providerName === "line" || providerName === "google",
          )
          .groupBy("providerName")
          .value();
        state.status = Status.LOADED;
      })
      .addCase(fetchKeycloakLinkedAccount.rejected, (state, { payload }) => {
        if (payload === undefined) {
          state.status = Status.ERROR;
          return;
        }
        state.errorMessage = payload.data;
        state.status = Status.ERROR;
      });
  },
});

export const { resetKeycloakLinkedAccount } =
  KeycloakLinkedAccountSlice.actions;
export default KeycloakLinkedAccountSlice.reducer;
