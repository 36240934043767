import { Box, ListItem, Typography } from "@mui/material";
import useTranslation from "hooks/useTranslation";

const BuyCoinsInstruction = () => {
  const { t } = useTranslation("translation", {
    keyPrefix: "BuyCoins",
  });
  return (
    <Box sx={{ color: "grey.accent2" }} data-testid="buy-coins-instruction">
      <Typography id="lbl_desc" variant="body2">
        {t("To complete payment you will need to")}
      </Typography>
      <ListItem id="lbl_desc-1" sx={{ display: "list-item", py: "0px" }}>
        <Typography variant="body2" component={"span"}>
          {t("Enter TrueMoney Wallet number")}
        </Typography>
      </ListItem>
      <ListItem id="lbl_desc-2" sx={{ display: "list-item", py: "0px" }}>
        <Typography variant="body2" component={"span"}>
          {t("Verify with OTP")}
        </Typography>
      </ListItem>
      <ListItem id="lbl_desc-3" sx={{ display: "list-item", py: "0px" }}>
        <Typography variant="body2" component={"span"}>
          {t("Make sure you have enough balance")}
        </Typography>
      </ListItem>
    </Box>
  );
};

export default BuyCoinsInstruction;
