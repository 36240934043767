import {
  Box,
  Modal,
  IconButton,
  Typography,
  useMediaQuery,
  ListItem,
} from "@mui/material";
import useWindowDimensions from "hooks/useWindowDimensions";
import { ReactComponent as CloseIcon } from "assets/icons/close.svg";

const BulletContent = ({ children }) => {
  return (
    <ListItem sx={{ alignItems: "flex-start", p: 0 }}>
      <Typography component="span" sx={{ float: "left", mr: "8px" }}>
        &#x2022;
      </Typography>
      {children}
    </ListItem>
  );
};

const GsbTncModal = ({ isVisible, onClose }) => {
  const { height, width } = useWindowDimensions();
  const isDesktop = useMediaQuery(theme => theme.breakpoints.up("md"));

  const renderThaiTnc = () => {
    return (
      <>
        <Box>
          <Typography
            data-testid="lbl-title-tnc"
            variant="body1"
            sx={{ mb: "8px" }}
          >
            {"หลักเกณฑ์และเงื่อนไข การแลก GSB Reward Point"}
          </Typography>
          <Box data-testid="lbl-tnc">
            <BulletContent>
              {
                "ใช้คะแนนสะสม GSB Reward Point 1,000 คะแนน แลกรับ ABC Point 100 คะแนน ขั้นต่ำ 1,000 คะแนน เพิ่มได้ทุก 1,000 คะแนน ผ่าน ABC Point Program ภายใน ทรูมันนี่ วอลเล็ท ตั้งแต่วันนี้ - 31 ธันวาคม 2566"
              }
            </BulletContent>
            <BulletContent>
              <span>
                {
                  "ผู้ถือบัตรสามารถนำคะแนนสะสม GSB Reward Point แลกเปลี่ยนเป็น ABC Point ผ่านช่องทาง GSB Credit Card Call Center สำหรับผู้ถือบัตรเครดิตธนาคารออมสิน โทร."
                }
                <Typography
                  data-testid="link-tel-1"
                  component="a"
                  href="tel:02-299-8888"
                  sx={{
                    color: "secondary.blue.accent1",
                    textDecoration: "none",
                  }}
                >
                  {" 02-299-8888 "}
                </Typography>
                {
                  "และ GSB Infinite Banking Call Center สำหรับผู้ถือบัตรเครดิต Prestige และ World Elite โทร."
                }
                <Typography
                  data-testid="link-tel-2"
                  component="a"
                  href="tel:02-299-9999"
                  sx={{
                    color: "secondary.blue.accent1",
                    textDecoration: "none",
                  }}
                >
                  {" 02-299-9999"}
                </Typography>
              </span>
            </BulletContent>
            <BulletContent>
              {
                "สิทธิพิเศษสำหรับผู้ถือบัตรเครดิตธนาคารออมสินทุกประเภท เฉพาะบัตรหลักเท่านั้น"
              }
            </BulletContent>
            <BulletContent>
              {
                "ผู้ถือบัตรไม่สามารถนำคะแนนสะสม GSB Reward Point ของตนไปแลกเป็น ABC Point ให้กับบุคคลอื่นได้ และไม่สามารถนำคะแนนสะสม GSB Reward Point ของบุคคลอื่นมาแลกเป็น ABC Point ให้กับตนเองได้เช่นเดียวกัน"
              }
            </BulletContent>
            <BulletContent>
              {
                "กำหนดอัตราการแลกคะแนนสะสม GSB Reward Point จำนวน 1,000 คะแนน มีมูลค่าเท่ากับ ABC Point จำนวน 100 คะแนน โดย ABC Point 1 คะแนน มีมูลค่าเท่ากับ 1 บาท"
              }
            </BulletContent>
            <BulletContent>
              {
                "ผู้ถือบัตรจะต้องมีสถานะการชำระเงินเป็นปกติ ไม่ผิดนัดชำระ และต้องมีคะแนนสะสม GSB Reward Point เพียงพอ ณ วันที่แจ้งความประสงค์จะขอแลกคะแนนสะสม GSB Reward Point เป็น ABC Point"
              }
            </BulletContent>
            <BulletContent>
              {
                "การแลกคะแนนสะสม GSB Reward Point ขั้นต่ำ 1,000 คะแนน เพิ่มได้ทุก 1,000 คะแนน"
              }
            </BulletContent>
            <BulletContent>
              {"ผู้ถือบัตรเครดิตจะต้องเป็นสมาชิก ABC Point"}
            </BulletContent>
            <BulletContent>
              {
                "ABC Point จะโอนเข้าบัญชีสมาชิกภายใน 30 วัน นับถัดจากวันที่แจ้งความประสงค์แลกคะแนนสะสม"
              }
            </BulletContent>
            <BulletContent>
              {
                "ณ วันที่แจ้งความประสงค์แลกคะแนนสะสม GSB Reward Point ผู้ถือบัตรจะต้องคงสภาพสถานะสมาชิกผู้ถือบัตรและไม่ผิดนัดชำระ ทั้งนี้ ธนาคารมีสิทธิ์ระงับการเข้าร่วมกิจกรรมส่งเสริมการตลาดข้างต้นในกรณีตรวจพบว่าผู้ถือบัตรดังกล่าวมีคุณสมบัติไม่ถูกต้องครบถ้วนหรือไม่ปฏิบัติตามระเบียบข้อบังคับ หรือเงื่อนไขที่ธนาคารกำหนด"
              }
            </BulletContent>
            <BulletContent>
              {
                "คะแนนสะสม GSB Reward Point ที่แลกเป็น ABC Pointแล้ว ไม่สามารถแลกเปลี่ยนหรือทอนเป็นเงินสด หรือขอคืนกลับเป็นคะแนนสะสม GSB Reward Point ได้ ไม่ว่ากรณีใดๆ ทั้งสิ้น"
              }
            </BulletContent>
            <BulletContent>
              {
                "ธนาคารออมสินมีสิทธิระงับการเข้าร่วมกิจกรรมส่งเสริมการตลาดข้างต้น ในกรณีตรวจพบว่าผู้ถือบัตรดังกล่าวมีคุณสมบัติไม่ถูกต้องครบถ้วน หรือไม่ปฏิบัติตามระเบียบข้อบังคับ หรือเงื่อนไขที่ธนาคารออมสินกำหนดหรือมีพฤติการณ์เข้าข่ายเป็นที่ต้องสงสัยหรือผิดปกติ"
              }
            </BulletContent>
            <BulletContent>
              {
                "ธนาคารออมสินมิได้มีส่วนรับผิดชอบในข้อบกพร่องเกี่ยวกับการแลกรับ ABC Point ของบริษัท"
              }
            </BulletContent>
            <BulletContent>
              {
                "เมื่อผู้ถือบัตรได้ทำการแลกคะแนนสะสม GSB Reward Point เป็น ABC Point เสร็จสมบูรณ์แล้ว การที่ผู้ถือบัตรนำ ABC Point ไปใช้เป็นส่วนลดในการแลกรับสินค้า และ/หรือบริการ ตลอดจนการแลกรับสิทธิประโยชน์ต่าง ๆ ให้เป็นไปตามเงื่อนไขที่ บริษัทกำหนด"
              }
            </BulletContent>
            <BulletContent>
              {
                "ธนาคารขอสงวนสิทธิ์ในการเปลี่ยนแปลงหลักเกณฑ์และเงื่อนไขที่ได้ประกาศไว้ โดยไม่จำเป็นต้องแจ้งให้ทราบล่วงหน้า และกรณีมีข้อพิพาทเกิดขึ้นให้ถือว่าหลักฐานและคำตัดสินของธนาคารเป็นอันสิ้นสุด"
              }
            </BulletContent>
          </Box>
        </Box>
      </>
    );
  };

  return (
    <Modal
      open={isVisible}
      onClose={onClose}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        alignContent: "center",
        m: 0,
      }}
      data-testid="gsb-tnc-modal"
    >
      <Box
        sx={[
          {
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            m: "16px",
            pb: "16px",
            borderRadius: "16px",
            bgcolor: "background.paper",
            maxWidth: isDesktop ? "60%" : `calc(${width}px - 32px)`,
            minHeight: "144px",
            maxHeight: `calc(${height}px - 64px)`,
          },
          () => ({
            "&:focus-visible": {
              outline: "none",
            },
          }),
        ]}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
            pt: "24px",
            pr: "24px",
          }}
        >
          <IconButton data-testid="btn-close-top-right" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box
          sx={{ overflowY: "auto", px: "24px", pt: "6px" }}
          data-testid="tnc-content"
        >
          {renderThaiTnc()}
        </Box>
      </Box>
    </Modal>
  );
};

export default GsbTncModal;
