import React, { createContext } from "react";

const DrawerMenuContext = createContext();

export const DrawerMenuProvider = ({ children, value }) => {
  return (
    <DrawerMenuContext.Provider value={value}>
      {children}
    </DrawerMenuContext.Provider>
  );
};

export default DrawerMenuContext;
