import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import NftMyCollectionAPI from "apiConfig/NftMyCollectionAPI";

export const status = {
  INITIAL: "INITIAL",
  LOADING: "LOADING",
  LOADED: "LOADED",
  EMPTY: "EMPTY",
  ERROR: "ERROR",
};

const bridgeStatus = {
  PENDING: "PENDING",
  READY_WITHDRAW: "READY_WITHDRAW",
  WITHDRAW: "WITHDRAW",
};

const initialState = {
  status: status.INITIAL,
  errorMessage: null,
  statusApprove: status.INITIAL,
  errorMessageApprove: null,
  statusPendingBridge: status.INITIAL,
  pendingBridge: [],
  errorMessagePendingBridge: null,
  step: 0,
};

//Thunks
export const fetchAsyncNftMyCollectionBridge = createAsyncThunk(
  "nfts/fetchAsyncNftMyCollectionBridge",
  async (postData, { rejectWithValue }) => {
    try {
      const response = await NftMyCollectionAPI.post(
        `/collections/deposit`,
        postData,
      );
      return response.data;
    } catch (err) {
      if (err.response) {
        return rejectWithValue({
          status: err.response.status,
          data: err.response.data,
        });
      }
      return rejectWithValue({
        status: "",
        data: err.message,
      });
    }
  },
);

export const fetchAsyncApproveBridge = createAsyncThunk(
  "nfts/fetchAsyncApproveBridge",
  async ({ tokenId, collectionAddress }, { rejectWithValue }) => {
    try {
      const response = await NftMyCollectionAPI.post(
        `/collections/${collectionAddress}/approve`,
        {
          tokenId,
          srcErc721Handler: process.env.REACT_APP_SRC_ERC_721_HANDLER,
        },
      );
      return response.data;
    } catch (err) {
      if (err.response) {
        return rejectWithValue({
          status: err.response.status,
          data: err.response.data,
        });
      }
      return rejectWithValue({
        status: "",
        data: err.message,
      });
    }
  },
);

export const fetchAsyncNftBridgePending = createAsyncThunk(
  "nfts/fetchAsyncNftBridgePending",
  async (_, { rejectWithValue }) => {
    try {
      const response = await NftMyCollectionAPI.get(
        `/bridges/history?status=${bridgeStatus.PENDING}&status=${bridgeStatus.READY_WITHDRAW}`,
      );
      return response.data.sort((prev, next) =>
        next.status.localeCompare(prev.status),
      );
    } catch (err) {
      if (err.response) {
        return rejectWithValue({
          status: err.response.status,
          data: err.response.data,
        });
      }
      return rejectWithValue({
        status: "",
        data: err.message,
      });
    }
  },
);

//Slices
const NftMyCollectionBridgeSlice = createSlice({
  name: "nftMyCollectionBridge",
  initialState,
  reducers: {
    resetNftMyCollectionBridge: state => {
      state.status = status.INITIAL;
      state.errorMessage = null;
      state.statusApprove = status.INITIAL;
      state.errorMessageApprove = null;
      state.step = 0;
    },
    updateStep: (state, action) => {
      state.step = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchAsyncNftMyCollectionBridge.pending, state => {
        state.errorMessage = null;
        state.status = status.LOADING;
      })
      .addCase(
        fetchAsyncNftMyCollectionBridge.fulfilled,
        (state, { payload }) => {
          state.errorMessage = null;
          state.status = status.LOADED;
        },
      )
      .addCase(
        fetchAsyncNftMyCollectionBridge.rejected,
        (state, { payload }) => {
          if (payload === undefined) {
            state.status = status.ERROR;
            return;
          }
          state.errorMessage = payload.data;
          state.status = status.ERROR;
        },
      )
      .addCase(fetchAsyncApproveBridge.pending, state => {
        state.errorMessageApprove = null;
        state.statusApprove = status.LOADING;
      })
      .addCase(fetchAsyncApproveBridge.fulfilled, (state, { payload }) => {
        state.errorMessageApprove = null;
        state.statusApprove = status.LOADED;
      })
      .addCase(fetchAsyncApproveBridge.rejected, (state, { payload }) => {
        if (payload === undefined) {
          state.statusApprove = status.ERROR;
          return;
        }
        state.errorMessageApprove = payload.data;
        state.statusApprove = status.ERROR;
      })
      .addCase(fetchAsyncNftBridgePending.pending, state => {
        state.errorMessagePendingBridge = null;
        state.statusPendingBridge = status.LOADING;
      })
      .addCase(fetchAsyncNftBridgePending.fulfilled, (state, { payload }) => {
        state.pendingBridge = payload;
        state.errorMessagePendingBridge = null;
        state.statusPendingBridge = status.LOADED;
      })
      .addCase(fetchAsyncNftBridgePending.rejected, (state, { payload }) => {
        if (payload === undefined) {
          state.statusPendingBridge = status.ERROR;
          return;
        }
        state.errorMessagePendingBridge = payload.data;
        state.statusPendingBridge = status.ERROR;
      });
  },
});

export const { resetNftMyCollectionBridge, updateStep } =
  NftMyCollectionBridgeSlice.actions;

export default NftMyCollectionBridgeSlice.reducer;
