import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ProfileAPI from "apiConfig/ProfileAPI";
import { Status } from "../../../../../redux/constant";

const initialState = {
  profilePhoto: null,
  status: Status.INITIAL,
  errorMessage: null,
};

export const uploadProfilePhoto = createAsyncThunk(
  "profile/uploadProfilePhoto",
  async ({ userId, formData }, { rejectWithValue }) => {
    try {
      const response = await ProfileAPI.patch(
        `/profiles/profile-picture/${userId}`,
        formData,
      );
      return response.data;
    } catch (err) {
      if (err.response) {
        return rejectWithValue({
          status: err.response.status,
          data: err.response.data,
        });
      }
      return rejectWithValue({
        status: "",
        data: err.message,
      });
    }
  },
);

const UploadProfilePhotoSlice = createSlice({
  name: "uploadProfilePhoto",
  initialState,
  reducers: {
    resetUploadProfilePhoto: state => {
      state.profilePhoto = null;
      state.status = Status.INITIAL;
      state.errorMessage = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(uploadProfilePhoto.pending, state => {
        state.errorMessage = null;
        state.status = Status.LOADING;
      })
      .addCase(uploadProfilePhoto.fulfilled, (state, { payload }) => {
        state.profilePhoto = payload;
        state.status = Status.LOADED;
      })
      .addCase(uploadProfilePhoto.rejected, (state, { payload }) => {
        if (payload === undefined) {
          state.status = Status.ERROR;
          return;
        }
        state.errorMessage = payload.data;
        state.status = Status.ERROR;
      });
  },
});

export const { resetUploadProfilePhoto } = UploadProfilePhotoSlice.actions;
export default UploadProfilePhotoSlice.reducer;
