import { getGalleryContract } from "../utils/contractHelper";
import { callTransaction } from "../utils/serviceHelper";

class GalleryServices {
  nftContract;

  constructor(contract) {
    this.nftContract = getGalleryContract(contract);
  }

  getTokenURI = tokenId => {
    return callTransaction(this.nftContract, "tokenURI", [tokenId]);
  };
}

export default GalleryServices;
