import React from "react";
import { Box, Typography } from "@mui/material";

const NftSaveCard = ({ id, title, icon, handleAction, disabled = false }) => {
  return (
    <Box
      flex="1"
      bgcolor="grey.accent4"
      height="160px"
      borderRadius="16px"
      pt="16px"
    >
      <Box
        data-testid={id}
        display="flex"
        justifyContent="center"
        alignItems="center"
        mb="16px"
        sx={{ cursor: disabled ? "normal" : "pointer" }}
        onClick={() => (disabled ? () => null : handleAction())}
      >
        {icon}
      </Box>
      <Typography variant="body2" textAlign="center">
        {title}
      </Typography>
    </Box>
  );
};

export default NftSaveCard;
