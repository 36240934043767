import React, { useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  TextareaAutosize,
  Button,
  Collapse,
} from "@mui/material";
import useTranslation from "hooks/useTranslation";
import VotePartnerIcon from "../../../../../assets/icons/votePartner.svg";
import { ReactComponent as CloseIcon } from "../../../../../assets/icons/close.svg";

import { trackExpandVotePartner, trackSubmitVotePartner } from "mixpanel";

const VotePartner = ({ setOpenSnackbar }) => {
  const [expanded, setExpanded] = useState(false);
  const [input, setInput] = useState("");
  const { t } = useTranslation("translation", {
    keyPrefix: "PartnerConvertIn",
  });

  const onExpanded = () => {
    trackExpandVotePartner();
    setExpanded(true);
  };

  const onSubmit = () => {
    if (input) {
      trackSubmitVotePartner(input);
      setInput("");
    }
    setExpanded(false);
    setOpenSnackbar(true);
  };
  return (
    <Box
      data-testid="vote-partner"
      onClick={expanded ? undefined : () => onExpanded()}
      sx={{
        bgcolor: "secondary.blue.accent4",
        borderRadius: "16px",
        width: "100%",
        px: 2,
        py: expanded ? 3 : 2,
        mb: 4,
        cursor: !expanded ? "pointer" : "auto",
      }}
    >
      <Collapse in={expanded}>
        <Box sx={{ width: "100%", textAlign: "end" }}>
          <IconButton
            id="vote-partner-close-button"
            data-testid="vote-partner-close-button"
            onClick={() => setExpanded(false)}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </Collapse>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box
          component="img"
          src={VotePartnerIcon}
          alt="question"
          sx={{
            height: "56px",
            width: "56px",
            mr: 1,
          }}
        />
        <Box>
          <Typography id="vote-partner-title" variant="body2" color="#0E162C">
            {t(
              "Can’t find the partner you want to use? Let us know your favorite partner!",
            )}
          </Typography>
        </Box>
      </Box>
      <Collapse in={expanded}>
        <>
          <Box sx={{ mt: 2, mb: "20px" }}>
            <TextareaAutosize
              id="vote-partner-textarea"
              data-testid="vote-partner-textarea"
              style={{
                borderRadius: 16,
                width: "100%",
                resize: "none",
                padding: 12,
              }}
              minRows={4}
              value={input}
              onChange={e => setInput(e.target.value)}
            />
          </Box>
          <Button
            id="vote-partner-submit-button"
            data-testid="vote-partner-submit-button"
            variant="contained"
            sx={{ borderRadius: "24px", width: "100%" }}
            onClick={onSubmit}
          >
            <Typography variant="subtitle1">{t("Submit")}</Typography>
          </Button>
        </>
      </Collapse>
    </Box>
  );
};

export default VotePartner;
