import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import MainAPI from "apiConfig/MainAPI";
import { serviceEndpoint } from "config";
import { Status } from "../../../../../redux/constant";

const initialState = {
  convertF2CCreateTransaction: {},
  status: Status.INITIAL,
  error: null,
};

export const fetchAsyncConvertF2CCreateTransaction = createAsyncThunk(
  "convertF2CCreateTransaction/fetchAsyncConvertF2CCreateTransaction",
  async (
    { source, target, provider, sourceAmount, targetAmount },
    { rejectWithValue },
  ) => {
    try {
      const response = await MainAPI.post(
        `${serviceEndpoint.f2c}/transactions`,
        {
          provider,
          source,
          sourceAmount,
          target,
          targetAmount,
        },
      );
      return response.data;
    } catch (err) {
      if (err.response) {
        return rejectWithValue({
          status: err.response.status,
          data: err.response.data,
        });
      }
      return rejectWithValue({
        status: "",
        data: err.message,
      });
    }
  },
);

const ConvertF2CCreateTransactionSlice = createSlice({
  name: "convertF2CCreateTransaction",
  initialState,
  reducers: {
    resetConvertF2CCreateTransaction: state => {
      state.convertF2CCreateTransaction = {};
      state.status = Status.INITIAL;
      state.error = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchAsyncConvertF2CCreateTransaction.pending, state => {
        state.error = null;
        state.status = Status.LOADING;
      })
      .addCase(
        fetchAsyncConvertF2CCreateTransaction.fulfilled,
        (state, { payload }) => {
          state.convertF2CCreateTransaction = payload;
          state.status = Status.LOADED;
        },
      )
      .addCase(
        fetchAsyncConvertF2CCreateTransaction.rejected,
        (state, { payload }) => {
          if (payload === undefined) {
            state.status = Status.ERROR;
            return;
          }
          state.convertF2CCreateTransaction = payload.data;
          state.error = {
            statusCode: payload.status,
            errorMessage: payload.data,
          };
          state.status = Status.ERROR;
        },
      );
  },
});

export const { resetConvertF2CCreateTransaction } =
  ConvertF2CCreateTransactionSlice.actions;

export default ConvertF2CCreateTransactionSlice.reducer;
