import BigNumber from "bignumber.js";

export const coinFormatter = amount => {
  if (amount === "0" || !amount) {
    return "0";
  }
  if (amount.includes(".")) {
    let parsedAmount = new BigNumber(amount);
    let formattedAmount = parsedAmount.decimalPlaces(8).toString();
    let nonDecimal = (formattedAmount + "").split(".")[0];
    let decimal = (formattedAmount + "").split(".")[1];
    let formattedNonDecimal = new BigNumber(nonDecimal);
    if (!decimal) {
      return formattedNonDecimal.toFormat();
    } else {
      return formattedNonDecimal.toFormat() + "." + decimal;
    }
  } else {
    let parsedAmount = new BigNumber(amount);
    return parsedAmount.toFormat();
  }
};

export const twoDecimalCoin = price => {
  if (price === 0 || !price) {
    return "0";
  }
  if (price.toString().includes(".")) {
    let parsedPrice = new BigNumber(price);
    let formattedPrice = parsedPrice.decimalPlaces(2).toString();
    let nonDecimal = (formattedPrice + "").split(".")[0];
    let decimal = (formattedPrice + "").split(".")[1];
    let formattedNonDecimal = new BigNumber(nonDecimal);
    if (!decimal) {
      return formattedNonDecimal.toFormat();
    } else {
      return formattedNonDecimal.toFormat() + "." + decimal;
    }
  } else {
    let parsedPrice = new BigNumber(price);
    return parsedPrice.toFormat();
  }
};

export const toDecimal = (value, decimalDigit = 2) => {
  if (!value) {
    return "0.".padEnd(2 + decimalDigit, "0");
  }
  return parseFloat(value).toLocaleString(undefined, {
    minimumFractionDigits: decimalDigit,
    maximumFractionDigits: decimalDigit,
  });
};
