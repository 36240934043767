import React, { useCallback, useEffect } from "react";
import { Box, Typography, ButtonBase } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import useTranslation from "../../../../hooks/useTranslation";
import useNavigationHook from "../../../../hooks/useNavigation";
import NftAsset from "../../components/nftAsset/NftAsset";
import { trackNftView, trackCopyNftUrl } from "../../../../mixpanel/nft";
import useAuth from "../../../../hooks/useAuth";
import { fullnameFormatterWithLang } from "../../../../utils/nameFormatter";
import phoneNumberFormatter from "../../../../utils/phoneNumberFormatter";
import toast, { Toaster, ToastBar } from "react-hot-toast";
import { ReactComponent as CopyIcon } from "../../../../assets/icons/copy.svg";
import { apiEndpoint } from "../../../../config";

const NftDetail = () => {
  const { collection, token, allTokens } = useLocation().state;
  const { t } = useTranslation("translation", {
    keyPrefix: "nftMyCollection",
  });
  const { user } = useAuth();
  const setActiveMenu = useNavigationHook();

  const getUserFullname = useCallback(() => {
    return fullnameFormatterWithLang({
      firstNameEN: user?.firstNameEN,
      middleNameEN: user?.middleNameEN,
      lastNameEN: user?.lastNameEN,
      firstNameTH: user?.firstNameTH,
      middleNameTH: user?.middleNameTH,
      lastNameTH: user?.lastNameTH,
    });
  }, [user]);

  useEffect(() => {
    setActiveMenu(true);
  }, [setActiveMenu]);

  useEffect(() => {
    let nftBalance = 0;
    Object.keys(allTokens).forEach(key => {
      nftBalance = nftBalance + allTokens[key].length;
    });
    trackNftView(nftBalance, collection.name);
  }, [collection, allTokens]);

  const getCreator = () => {
    const creator = collection?.metadata?.attributes?.find(
      item => item.trait_type === "creator",
    );
    return creator?.value || "";
  };

  const toaster = () => {
    toast.remove();
    toast(t("URL Copied"), {
      style: {
        textAlign: "start",
        padding: "12px, 16px, 12px, 16px",
        color: "#FFFFFF",
        backgroundColor: "#141312",
        width: "100%",
        borderRadius: "16px",
      },
    });
  };

  return (
    <>
      <Helmet>
        <title>{t("My Collection")}</title>
      </Helmet>

      <Box>
        <NftAsset token={token} />
      </Box>
      <Box sx={{ mt: "32px", mx: "16px", mb: "44px" }}>
        <Box sx={{ mb: "24px" }}>
          <Typography data-testid="text-nft-name" variant="h6">
            {token.metadata?.name}
          </Typography>
          <Typography data-testid="text-nft-creator" variant="body1">{`${t(
            "by",
          )} ${getCreator()}`}</Typography>
        </Box>
        <Box sx={{ mb: "24px" }}>
          <Typography variant="h6" data-testid="text-token-id-title">
            {t("Token ID")}
          </Typography>
          <Typography variant="body1" data-testid="text-nft-id">
            {token.tokenID}
          </Typography>
        </Box>
        <Box sx={{ mb: "24px" }}>
          <Typography variant="h6" data-testid="text-desc-title">
            {t("Description")}
          </Typography>
          <Typography variant="body1" data-testid="text-nft-desc">
            {token.metadata?.description}
          </Typography>
        </Box>
        <Box sx={{ mb: "24px" }}>
          <Typography variant="h6" data-testid="text-collection-name-title">
            {t("Collection name")}
          </Typography>
          <Typography variant="body1" data-testid="text-collection-name">
            {collection.name}
          </Typography>
        </Box>
        <Box
          sx={{
            mb: "24px",
          }}
        >
          <Typography variant="h6" data-testid="text-owner-title">
            {t("Owner")}
          </Typography>
          <Typography variant="body1" data-testid="text-nft-owner">
            {getUserFullname()}
          </Typography>
          <Typography variant="body1" data-testid="text-nft-owner-mobile">
            {phoneNumberFormatter(user?.mobileNumber)}
          </Typography>
        </Box>

        <Box sx={{ mb: "48px" }}>
          <Typography variant="h6" data-testid="text-url-title">
            {t("URL")}
          </Typography>
          <ButtonBase
            id="copy-url"
            data-testid="copy-url"
            className="btn"
            sx={{
              p: "8px 16px",
              borderRadius: "16px",
              backgroundColor: "grey.accent4",
              width: "100%",
            }}
            data-clipboard-text={`${apiEndpoint.visitNFT}/assets/${collection.address}/${token.tokenID}`}
            onClick={() => {
              trackCopyNftUrl(
                `${apiEndpoint.visitNFT}/assets/${collection.address}/${token.tokenID}`,
              );
              toaster();
            }}
          >
            <Box component="div" textAlign="start" width="90%">
              <Typography
                data-testid="text-nft-url"
                variant="body1"
                sx={{ textOverflow: "ellipsis", overflow: "hidden" }}
              >
                {`${apiEndpoint.visitNFT}/assets/${collection.address}/${token.tokenID}`}{" "}
              </Typography>
            </Box>
            <Box textAlign="end" width="10%">
              <CopyIcon style={{ width: "24px", height: "24px" }} />
            </Box>
          </ButtonBase>
        </Box>

        <Toaster position="bottom-center" reverseOrder={false}>
          {t => (
            <ToastBar toast={t}>
              {({ message }) => (
                <>
                  <div>{message}</div>
                </>
              )}
            </ToastBar>
          )}
        </Toaster>
      </Box>
    </>
  );
};

export default NftDetail;
