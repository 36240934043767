import axios from "axios";
import { apiEndpoint } from "../config";
import refreshInterceptor from "./refreshInterceptor/refreshInterceptor";

const MainAPI = axios.create({
  baseURL: apiEndpoint.baseUrl,
  headers: {
    "content-type": "application/json",
  },
});

MainAPI.interceptors.request.use(refreshInterceptor);
export default MainAPI;
