import crypto from "crypto-browserify";

export const encrypt = data => {
  const iv = crypto.randomBytes(16);
  const cipher = crypto.createCipheriv(
    "aes-256-cbc",
    new Buffer(process.env.REACT_APP_KEY_NFT_QR),
    iv,
  );
  return Buffer.concat([cipher.update(data), cipher.final(), iv]).toString(
    "hex",
  );
};

export const decrypt = data => {
  const binaryData = new Buffer(data, "hex");
  const iv = binaryData.slice(-16);
  const encryptedData = binaryData.slice(0, binaryData.length - 16);
  const decipher = crypto.createDecipheriv(
    "aes-256-cbc",
    new Buffer(process.env.REACT_APP_KEY_NFT_QR),
    iv,
  );
  return Buffer.concat([
    decipher.update(encryptedData),
    decipher.final(),
  ]).toString();
};
