import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Box, Button, Container, Typography } from "@mui/material";
import GenericErrorImg from "../../assets/images/GenericErrorImg.svg";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.log("Error:", error);
    console.log("Error Info:", errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <>
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              minHeight: "calc(100vh - 134px)",
            }}
          >
            <Container maxWidth="lg">
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  pb: "30px",
                }}
              >
                <Box
                  alt="Error img not found"
                  component="img"
                  src={GenericErrorImg}
                  sx={{
                    height: "60px",
                    width: "60px",
                  }}
                />
              </Box>
              <Typography
                variant="h4"
                align="center"
                color="textPrimary"
                sx={{ pb: "25px" }}
              >
                {"Something Went Wrong"}
              </Typography>
              <Box
                sx={{
                  width: "100%",
                  marginTop: "32px",
                  p: "16px 16px 32px",
                  backgroundColor: "background.paper",
                }}
              >
                <Button
                  color="primary"
                  component={RouterLink}
                  to="/"
                  variant="contained"
                  sx={{ borderRadius: "24px", width: "100%", height: "48px" }}
                  fullWidth
                >
                  <Typography variant="button">{"Back to Home"}</Typography>
                </Button>
              </Box>
            </Container>
          </Box>
        </>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
