import { useState, useEffect } from "react";
import { Typography, Box, Button, Avatar } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import useTranslation from "../../../../hooks/useTranslation";
import useLoadingHook from "../../../../hooks/useLoadingHook";
import { coinFormatter } from "../../../../utils/coinFormatter";
import {
  useDispatch,
  useSelector,
} from "../../../../redux/store/configureStore";
import useNavigationHook from "../../../../hooks/useNavigation";
import Prompt from "../../../../components/Prompt/Prompt";
import {
  fetchAsyncConvertPartnerSwap,
  resetConvertPartnerSwap,
} from "../../redux/slices/convertPartnerCoins/convertPartnerSwap";
import { Status } from "../../../../redux/constant";
import ConvertDetail from "../../components/reviewConvert/convertDetail/ConvertDetail";
import ConvertSeperation from "../../components/reviewConvert/convertSeperation/ConvertSeperation";
import ConvertRate from "../../components/reviewConvert/convertRate/ConvertRate";
import { resetConvertPartnerBalance } from "../../redux/slices/convertPartnerCoins/convertPartnerBalance";
import {
  trackErrorPointConversion,
  trackReviewPointConversion,
} from "../../../../mixpanel";
import { getValueByLanguage } from "../../../../utils/getLanguage";
import { getPartnerImageByLang } from "utils/imageAsset";
import { abcLogo, abcTokenName, abcUnit } from "features/wallet/utils/abc";

const PartnerReviewConvert = () => {
  const { partnerId, targetId, partner, rate, convertPoints, convertedCoins } =
    useLocation().state || {};
  const { partnerId: urlPartnerId } = useParams();
  const setActiveMenu = useNavigationHook();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    convertPartnerSwap,
    status: convertPartnerSwapStatus,
    errorMessage: convertPartnerSwapErrorMessage,
  } = useSelector(state => state.convertPartnerSwap);
  const { t } = useTranslation("translation", {
    keyPrefix: "ReviewConvert",
  });
  const progressScreen = useLoadingHook();
  const [promptOpen, setPromptOpen] = useState(false);
  const [promptError, setPromptError] = useState({
    title: null,
    subtitle: null,
    rawTitle: null,
    rawSubTitle: null,
    buttonText: null,
    onClose: () => null,
  });

  useEffect(() => {
    if (!partnerId) {
      navigate(`/convert/${urlPartnerId}`, { replace: true });
    }
  }, [navigate, partnerId, urlPartnerId]);

  useEffect(() => {
    if (partnerId && convertPoints && convertedCoins) {
      trackReviewPointConversion({
        partnerName: partnerId,
        targetName: abcTokenName,
        sourceAmount: convertPoints,
        targetAmount: convertedCoins,
      });
    }
  }, [partnerId, convertPoints, convertedCoins]);

  useEffect(() => {
    setActiveMenu(true);
  }, [setActiveMenu]);

  useEffect(() => {
    return () => {
      dispatch(resetConvertPartnerSwap());
      dispatch(resetConvertPartnerBalance());
    };
  }, [dispatch]);

  const transactionLoading = () => {
    progressScreen.setOpen(true);
  };

  const transactionError = errorMessage => {
    let promptTitle = null;
    let subtitle = null;
    let rawTitle = null;
    let rawSubTitle = null;
    let apiErrorCode = null;
    let apiErrorMessage = null;

    progressScreen.setOpen(false);
    if (errorMessage?.error) {
      const { code, title, description } = errorMessage.error;
      apiErrorCode = code;
      if (title && description) {
        rawTitle = title;
        rawSubTitle = description;
        apiErrorMessage = title;
      } else {
        promptTitle = "Unable to proceed";
        rawSubTitle = code;
      }
    } else {
      promptTitle = "Unable to proceed";
      subtitle = "Please try again";
    }

    trackErrorPointConversion({
      partnerName: partnerId,
      targetName: abcTokenName,
      sourceAmount: convertPoints,
      targetAmount: convertedCoins,
      errorCode: apiErrorCode || "",
      errorMessage: apiErrorMessage || "",
    });

    setPromptError({
      title: promptTitle,
      subtitle,
      rawTitle,
      rawSubTitle,
      buttonText: "Back to ABC Wallet",
      onClose: () =>
        navigate(`/partner-convert/${partnerId}`, {
          state: {
            partnerId,
          },
        }),
    });
    setPromptOpen(true);
  };

  const transactionLoaded = state => {
    progressScreen.setOpen(false);
    navigate("/partner-convert-complete", {
      state,
    });
  };

  useEffect(() => {
    if (convertPartnerSwapStatus === Status.LOADING) {
      transactionLoading();
    } else if (convertPartnerSwapStatus === Status.ERROR) {
      transactionError(convertPartnerSwapErrorMessage);
    } else if (convertPartnerSwapStatus === Status.LOADED) {
      const param = {
        partnerId,
        amount: convertedCoins,
        fromName: partner.name,
        fromImageIconUrl: getPartnerImageByLang(partnerId),
        dateTime: convertPartnerSwap.createdAt,
        transactionId: convertPartnerSwap.targetTxId,
        convertPoints,
        convertedCoins,
      };
      transactionLoaded(param);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [convertPartnerSwapStatus]);

  const handleClick = () => {
    dispatch(
      fetchAsyncConvertPartnerSwap({
        sourceId: partnerId,
        targetId,
        sourceAmount: parseInt(convertPoints, 10),
        targetAmount: parseFloat(convertedCoins, 10),
      }),
    );
  };

  if (!partnerId) {
    return null;
  }

  return (
    <Box>
      <Helmet>
        <title>{t("Review")}</title>
      </Helmet>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          p: "32px 16px 0",
          backgroundColor: "background.paper",
          height: "calc(100vh - 64px)",
        }}
        data-testid="partner-review-convert"
      >
        <Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              border: 1,
              borderColor: "grey.accent3",
              borderRadius: "16px",
              mb: "24px",
              p: "16px",
            }}
          >
            <ConvertDetail
              testid="source"
              title={t("You convert")}
              details={{
                logo: getPartnerImageByLang(partnerId),
                name: getValueByLanguage(partner.name),
                balance: convertPoints,
              }}
              unit={getValueByLanguage(rate.fromUnit)}
            />
            <ConvertSeperation />
            <ConvertDetail
              testid="target"
              title={t("You get")}
              details={{
                logo: abcLogo,
                name: abcUnit,
                balance: convertedCoins,
              }}
              unit={abcUnit}
            />
          </Box>
          <ConvertRate
            convertText={t("Conversion rate")}
            rate={rate.rate}
            from={getValueByLanguage(rate.fromUnit)}
            to={rate.toUnit}
          />
        </Box>
        <Box
          sx={{
            boxShadow: "0px 1px 6px 1px rgba(0, 0, 0, 0.15)",
            m: "0px -16px",
            p: "16px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              pb: "16px",
              px: "8px",
            }}
          >
            <Typography variant="subtitle">{t("You get_2")}</Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Avatar
                data-testid="img-summary-coin"
                sx={{
                  height: "24px",
                  width: "24px",
                  mr: "8px",
                }}
                src={abcLogo}
              />
              <Typography
                data-testid="lbl-balance-summary-coin"
                variant="subtitle"
              >
                {`${coinFormatter(convertedCoins)} ${abcUnit}`}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ pb: "16px" }}>
            <Button
              data-testid="review"
              variant="contained"
              fullWidth
              sx={{ height: 48, width: "100%", borderRadius: "24px" }}
              disabled={convertPartnerSwapStatus === Status.LOADING}
              onClick={handleClick}
            >
              <Typography variant="button" color="background.paper">
                {t("Convert")}
              </Typography>
            </Button>
          </Box>
        </Box>
      </Box>
      <Prompt
        isVisible={promptOpen}
        icon={"error"}
        onClose={promptError.onClose}
        title={promptError.title}
        subTitle={promptError.subtitle}
        rawTitle={promptError.rawTitle}
        rawSubTitle={promptError.rawSubTitle}
        buttonText={promptError.buttonText}
        keyPrefix={"ReviewConvert"}
      />
    </Box>
  );
};
export default PartnerReviewConvert;
