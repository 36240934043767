import { useMemo } from "react";
import { Box, CardActionArea, Chip, Skeleton, Typography } from "@mui/material";
import useTranslation from "hooks/useTranslation";
import { coinFormatter } from "utils/coinFormatter";
import { getPartnerImageByLang } from "utils/imageAsset";

const ManageLinkedRewardsCard = ({
  partnerId,
  partnerName,
  sourceBalance,
  sourceUnit,
  targetUnit,
  rate,
  isEdit,
  isLoading,
  isLoadingBalance,
  isLoadingRates,
  onClickUnlink,
  onClickCard = () => null,
}) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "ManageLinkedRewards",
  });

  const CustomCardActionArea = useMemo(
    () => (isEdit ? Box : CardActionArea),
    [isEdit],
  );

  return (
    <Box data-testid="manage-linked-rewards-cards">
      <CustomCardActionArea
        id={`btn_manage-linked-rewards-cards-${partnerId}"`}
        sx={{ borderRadius: "16px" }}
        onClick={() => (!isEdit ? onClickCard(partnerId) : null)}
      >
        <Box
          sx={{
            display: "flex",
            p: "16px",
            border: "1px solid",
            borderColor: "grey.accent3",
            borderRadius: "16px",
          }}
        >
          <Box sx={{ width: "40px" }}>
            {!isLoading ? (
              <Box
                id={`img_partner-icon-${partnerId}"`}
                alt="partner-icon"
                component="img"
                src={getPartnerImageByLang(partnerId)}
                sx={{
                  height: "40px",
                  width: "40px",
                }}
              />
            ) : (
              <Skeleton
                variant="circular"
                width={40}
                height={40}
                data-testid="manage-linked-rewards-cards-skeleton"
              />
            )}
          </Box>
          <Box sx={{ flex: 1, ml: "8px" }}>
            <Box sx={{ mb: "8px" }}>
              {!isLoading ? (
                <Typography
                  id={`lbl_partner-name-${partnerId}"`}
                  variant="body2"
                >
                  {partnerName}
                </Typography>
              ) : (
                <Skeleton variant="text" width={"70px"} />
              )}
            </Box>
            <Box sx={{ mb: "8px" }}>
              {!isLoading ? (
                <Typography
                  id={`lbl_coin-balance-${partnerId}"`}
                  variant="subtitle1"
                >
                  <>
                    {!isLoadingBalance
                      ? `${coinFormatter(sourceBalance?.toString())}`
                      : "-"}
                    <Typography
                      id={`lbl_coin-balance-unit-${partnerId}"`}
                      variant="span"
                      sx={{ ml: "4px" }}
                    >
                      {sourceUnit}
                    </Typography>
                  </>
                </Typography>
              ) : (
                <Skeleton variant="text" width={"100px"} />
              )}
            </Box>
            <Box>
              {!isLoading && !isLoadingRates ? (
                <Typography
                  id={`lbl_rates-${partnerId}"`}
                  variant="body2"
                  sx={{ color: "grey.accent2" }}
                >
                  {`${rate} ${sourceUnit} = 1 ${targetUnit}`}
                </Typography>
              ) : (
                <Skeleton
                  variant="text"
                  width={"100px"}
                  data-testid="rate-skeleton-card"
                />
              )}
            </Box>
          </Box>
          <Box sx={{ ml: "8px" }}>
            {!isLoading ? (
              <>
                {!isEdit ? (
                  <Chip
                    id={`lbl_linked-${partnerId}"`}
                    label={t("LINKED")}
                    sx={{
                      px: "4px",
                      fontSize: "0.875rem",
                      bgcolor: "success.main",
                    }}
                  />
                ) : (
                  <Chip
                    id={`btn_unlinked-${partnerId}"`}
                    label={t("UNLINK")}
                    onClick={() => {
                      onClickUnlink(partnerId);
                    }}
                    variant="outlined"
                    sx={{
                      px: "4px",
                      fontSize: "0.875rem",
                      fontWeight: 500,
                      borderColor: "primary.main",
                      color: "primary.main",
                    }}
                  />
                )}
              </>
            ) : (
              <Skeleton variant="text" width={"80px"} />
            )}
          </Box>
        </Box>
      </CustomCardActionArea>
    </Box>
  );
};

export default ManageLinkedRewardsCard;
