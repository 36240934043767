import React from "react";
import Carousel from "react-material-ui-carousel";
import { Box } from "@mui/material";
import Pilot1 from "../../../../assets/images/Marketplace/banner/KV_ABC_Top banner_1440x575pix_V3-01-20220607-115411.jpg";
import Pilot2 from "../../../../assets/images/Marketplace/banner/KV_ABC_Top banner_1440x575pix_V3-02-20220607-115220.jpg";
import { paletteLight } from "../../../../theme";
import { isFusion, S3Endpoint } from "config";

const fusionItems = [
  {
    id: "FusionPilot1",
    src: `${S3Endpoint.marketplaceBanner}/banner-fusion-1.jpg`,
  },
  {
    id: "FusionPilot2",
    src: `${S3Endpoint.marketplaceBanner}/banner-fusion-2.jpg`,
  },
  {
    id: "FusionPilot3",
    src: `${S3Endpoint.marketplaceBanner}/banner-fusion-3.jpg`,
  },
  {
    id: "FusionPilot4",
    src: `${S3Endpoint.marketplaceBanner}/banner-fusion-4.jpg`,
  },
  {
    id: "FusionPilot5",
    src: `${S3Endpoint.marketplaceBanner}/banner-fusion-5.jpg`,
  },
];

const earthItems = [
  {
    id: "Pilot1",
    src: Pilot1,
  },
  {
    id: "Pilot2",
    src: Pilot2,
  },
];

const CarouselBanner = () => {
  var items = isFusion ? fusionItems : earthItems;

  return (
    <Carousel
      sx={{
        height: { xs: "154px", sm: "308px" },
      }}
      navButtonsAlwaysInvisible={true}
      indicators={true}
      stopAutoPlayOnHover={false}
      animation="slide"
      interval={5000}
      duration={1000}
      indicatorContainerProps={{
        style: {
          position: "absolute",
          bottom: "8px",
          zIndex: "1",
        },
      }}
      activeIndicatorIconButtonProps={{
        style: {
          color: paletteLight.background.red,
        },
      }}
      indicatorIconButtonProps={{
        style: {
          color: paletteLight.grey.accent3,
        },
      }}
    >
      {items.map((item, i) => (
        <Box
          data-testid={item.id}
          key={item.id}
          sx={{
            backgroundImage: `url("${item.src}")`,
            backgroundSize: "contain",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            height: { xs: "154px", sm: "308px" },
            padding: "8px",
            width: "100%",
            borderRadius: "16px",
            display: "flex",
          }}
        />
      ))}
    </Carousel>
  );
};

export default CarouselBanner;
