import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import useTranslation from "../../../../../hooks/useTranslation";
import { useSelector } from "../../../../../redux/store/configureStore";
import PartnerButton from "../../partnerButton/PartnerButton";
import { getValueByLanguage } from "../../../../../utils/getLanguage";
import { Status } from "../../../../../redux/constant";
import SkeletonPartnerButton from "../../partnerButton/SkeletonPartnerButton";
import {
  trackClickGSBIcon,
  trackVisitConvertPartnerListingPage,
} from "../../../../../mixpanel";
import { getPartnerImageByLang } from "utils/imageAsset";
import GSB from "assets/icons/GSB.svg";
import { gsb } from "features/wallet/pages/partnerConvertCoinsStatic/PartnerConvertCoinsStatic";
import { getFeatureFlags } from "config";

const PartnerList = () => {
  const { enablePartnerGSB } = getFeatureFlags();
  const navigate = useNavigate();
  const { partnerList, status: partnerListStatus } = useSelector(
    state => state.partnerList,
  );
  const { linkedPartner } = useSelector(state => state.linkedPartner);

  useEffect(() => {
    trackVisitConvertPartnerListingPage();
  }, []);

  const { t } = useTranslation("translation", {
    keyPrefix: "PartnerConvert",
  });

  const handleClickPartnerButton = _partnerId => {
    navigate(`/convert/${_partnerId}`);
  };

  const handleClickStaticPartner = _partnerId => {
    trackClickGSBIcon();
    navigate(`/wallet/partner-contact/${_partnerId}`);
  };

  const getIsLinkedPartner = partnerId => {
    return linkedPartner?.find(
      linkedItem => linkedItem.providerName === partnerId,
    )?.connected;
  };

  const renderPartnerList = () => {
    if (partnerListStatus === Status.LOADED) {
      return (
        <>
          {partnerList?.items.map(item => (
            <Box
              key={item.id}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                py: "16px",
              }}
            >
              <PartnerButton
                partnerId={item.id}
                partnerName={getValueByLanguage(item.name)}
                partnerImage={getPartnerImageByLang(item.id)}
                onClick={() => handleClickPartnerButton(item.id)}
                isLinked={getIsLinkedPartner(item.id)}
              />
            </Box>
          ))}
        </>
      );
    }

    return (
      <>
        {[...Array(4)].map((_, index) => (
          <Box key={index} sx={{ px: "24px", py: "16px" }}>
            <SkeletonPartnerButton />
          </Box>
        ))}
      </>
    );
  };

  const renderGSB = () => {
    if (partnerListStatus === Status.LOADED) {
      return (
        <Box
          key={gsb.id}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            py: "16px",
          }}
        >
          <PartnerButton
            partnerId={gsb.id}
            partnerName={getValueByLanguage(gsb.name)}
            partnerImage={GSB}
            onClick={() => handleClickStaticPartner(gsb.id)}
            isLinked={getIsLinkedPartner(gsb.id)}
          />
        </Box>
      );
    }
  };

  return (
    <>
      <Typography id="lbl_title_partnerList" variant="h6">
        {t("Pick your reward to convert now!")}
      </Typography>
      <Box
        sx={{
          display: "grid",
          columnGap: "16px",
          rowGap: "16px",
          gridTemplateColumns: "repeat(auto-fill, minmax(104px, 1fr))",
          mt: "24px",
        }}
        data-testid="partner-list"
      >
        {renderPartnerList()}
        {!!enablePartnerGSB && renderGSB()}
      </Box>
    </>
  );
};

export default PartnerList;
