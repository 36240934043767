import React from "react";
import { styled } from "@mui/system";
import TabsUnstyled from "@mui/base/TabsUnstyled";
import TabPanelUnstyled from "@mui/base/TabPanelUnstyled";
import TabsListUnstyled from "@mui/base/TabsListUnstyled";
import TabUnstyled, { tabUnstyledClasses } from "@mui/base/TabUnstyled";
import { Typography } from "@mui/material";
import { paletteLight } from "../../../../theme/index";
import useTranslation from "../../../../hooks/useTranslation";
import { useNavigate } from "react-router-dom";
import { getFeatureFlags } from "../../../../config";

const NftTab = ({ onChange, activeTab }) => {
  const navigate = useNavigate();
  const { t } = useTranslation("translation", {
    keyPrefix: "nftTab",
  });
  const { enableNftBridge, enableNftOtherCollection } = getFeatureFlags();
  const tabLinks = [
    "/wallet/nfts",
    enableNftOtherCollection ? "/wallet/nfts/other-collectibles" : null,
    enableNftBridge ? "/wallet/nfts/my-pending" : null,
  ].filter(v => v !== null);

  const Tab = styled(TabUnstyled)`
    min-height: 32px;
    color: ${paletteLight.grey.accent1};
    cursor: pointer;
    background-color: transparent;
    margin: 4px 4px;
    border: none;
    border-radius: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 16px;
    white-space: nowrap;

    &:hover {
      background-color: ${paletteLight.primary.accent4};
    }

    &:focus {
      color: ${paletteLight.grey.accent1};
    }

    &.${tabUnstyledClasses.root} {
      background-color: ${paletteLight.grey.accent4};
    }

    &.${tabUnstyledClasses.selected} {
      background-color: ${paletteLight.grey.accent1};
      color: ${paletteLight.primary.contrastText};
    }
  `;

  const TabsList = styled(TabsListUnstyled)({
    minHeight: "40px",
    display: "flex",
    alignItems: "center",
  });

  const TabPanel = styled(TabPanelUnstyled)({
    width: "100%",
  });

  const TypographyWithFont = styled(Typography)({
    fontFamily:
      localStorage.getItem("lang") === "th"
        ? '"Anuyart", "Noto Serif Thai", "sans-serif"'
        : "Roboto",
  });

  const handleOnChange = (_, selectedIndex) => {
    navigate(tabLinks[selectedIndex]);
    onChange(selectedIndex);
  };
  return (
    <TabsUnstyled
      id="wallet-tabs"
      onChange={handleOnChange}
      value={activeTab}
      variant="fullWidth"
    >
      <TabsList
        sx={{
          m: "0px 20px 20px 20px",
          display: "flex",
          flexDirection: "row",
          overflow: "auto",
        }}
      >
        <Tab>
          <TypographyWithFont variant="subtitle">
            {t("My Collection")}
          </TypographyWithFont>
        </Tab>
        {enableNftOtherCollection && (
          <Tab>
            <TypographyWithFont variant="subtitle">
              {t("Other Network")}
            </TypographyWithFont>
          </Tab>
        )}
        {enableNftBridge && (
          <Tab>
            <TypographyWithFont variant="subtitle">
              {t("My Pending")}
            </TypographyWithFont>
          </Tab>
        )}
      </TabsList>
      <TabPanel value={0}></TabPanel>
      {enableNftOtherCollection && <TabPanel value={1} />}
      {enableNftBridge && <TabPanel value={2}></TabPanel>}
    </TabsUnstyled>
  );
};

export default NftTab;
