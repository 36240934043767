import React from "react";
import { Box } from "@mui/material";
import CardComponent from "../card/CardComponent";
import DealSection from "../DealSection";
import {
  useDispatch,
  useSelector,
} from "../../../../redux/store/configureStore";
import {
  fetchAsyncDeals,
  status as DealsStatus,
} from "../../redux/slices/deals/NewDeals";
import DealsSkeleton from "../../skeletonLoading/DealsSkeleton";
import EmptyDeals from "../emptyDeals/EmptyDeals";
import GenericError from "../../../../pages/genericError/GenericError";

const DealError = () => {
  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        flexGrow: 1,
        alignContent: "center",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <GenericError
        id="deals-error"
        title="Service is not available"
        body="Service is not available at the moment. You may be able to try again."
      />
    </Box>
  );
};

const AllDeals = () => {
  const dispatch = useDispatch();
  const { deals, status } = useSelector(state => state.deals);

  React.useEffect(() => {
    dispatch(fetchAsyncDeals());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderDealSection = (dealType, deals) => {
    return (
      <DealSection key={`deal-type-${dealType}`} dealType={dealType}>
        {deals?.map(deal => (
          <CardComponent
            deal={deal}
            key={deal.id}
          />
        ))}
      </DealSection>
    );
  };

  const renderDeals = () => {
    if (status === DealsStatus.LOADING)
      return <DealsSkeleton id="deals-loading" />;
    if (status === DealsStatus.ERROR) return <DealError />;
    if (status === DealsStatus.EMPTY)
      return (
        <Box sx={{ m: "32px" }}>
          <EmptyDeals
            id="deals-empty"
            title="No Available Voucher"
            subtitle="Please check back soon for exciting new offers"
          />
        </Box>
      );
    if (deals && status !== DealsStatus.INITIAL)
      return (
        <Box id="deals-scroll">
          {deals?.map((value, index) =>
            renderDealSection(value.name, value.deals),
          )}
        </Box>
      );
    return null;
  };
  return <>{renderDeals()}</>;
};

export default AllDeals;
