import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import useTranslation from "../../../../../hooks/useTranslation";
import { useSelector } from "../../../../../redux/store/configureStore";
import { Box, Typography, Button } from "@mui/material";
import PartnerRewardButton from "../../partnerButton/partnerRewardButton/PartnerRewardButton";
import { getPartnerImageByLang } from "utils/imageAsset";
import { getValueByLanguage } from "utils/getLanguage";
import { Status } from "redux/constant";

const PartnerYourReward = ({ partnersBalance }) => {
  const navigate = useNavigate();
  const { partnerList } = useSelector(state => state.partnerList);
  const { linkedPartner } = useSelector(state => state.linkedPartner);

  const { t } = useTranslation("translation", {
    keyPrefix: "PartnerConvert",
  });

  const filteredLinkedPartner = useMemo(
    () =>
      linkedPartner?.filter(
        linkedItem => linkedItem.connected && !linkedItem.social,
      ),
    [linkedPartner],
  );

  const handleClickSeeAll = () => {
    navigate("/wallet/partner-all-your-reward");
  };

  const handleClickPartnerButton = _partnerId => {
    navigate(`/convert/${_partnerId}`);
  };

  const getPartnerDetail = partnerId => {
    return partnerList?.items?.find(item => item.id === partnerId);
  };

  const renderRewardButton = partnerId => {
    return (
      <PartnerRewardButton
        partnerId={partnerId}
        partnerName={getValueByLanguage(getPartnerDetail(partnerId)?.name)}
        partnerImage={getPartnerImageByLang(partnerId)}
        onClick={() => handleClickPartnerButton(partnerId)}
        isLinked
        havePoint
        pointBalance={partnersBalance[partnerId]}
        pointBalanceStatus={
          partnersBalance[partnerId] !== undefined
            ? Status.LOADED
            : Status.LOADING
        }
        pointUnit={getValueByLanguage(getPartnerDetail(partnerId)?.unitName)}
      />
    );
  };

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography id="lbl_title_partnerList" variant="h6">
          {t("Your rewards")}
        </Typography>
        <Button variant="text" onClick={handleClickSeeAll}>
          <Typography
            id="btn_seeAll_partnerList"
            variant="body1"
            sx={{ color: "primary.main" }}
          >
            {t("See all")}
          </Typography>
        </Button>
      </Box>
      <Box
        sx={{
          display: "grid",
          columnGap: "23px",
          gridTemplateColumns: "repeat(2, minmax(130px, 1fr))",
          mt: "16px",
        }}
        data-testid="partner-your-reward"
      >
        {filteredLinkedPartner?.[0] &&
          renderRewardButton(filteredLinkedPartner[0].providerName)}
        {filteredLinkedPartner?.[1] &&
          renderRewardButton(filteredLinkedPartner[1].providerName)}
      </Box>
    </>
  );
};

export default PartnerYourReward;
