import React, { useEffect, useMemo, useRef, useState } from "react";
import { Box, Button, Typography, Paper, useMediaQuery } from "@mui/material";
import { styled } from "@mui/system";
import { Helmet } from "react-helmet-async";
import { useLocation, useNavigate } from "react-router-dom";
import useTranslation from "hooks/useTranslation";
import useNavigationHook from "hooks/useNavigation";
import { useWeb3React } from "@web3-react/core";
import BridgeInstruction from "./bridgeInstruction/BridgeInstruction";
import { ReactComponent as EthIcon } from "assets/icons/eth_icon.svg";
import { trackTapReviewBridge } from "mixpanel/nft";

const BoxMainInfo = styled(Box)(({ theme }) => ({
  padding: "16px",
  borderRadius: "16px",
  backgroundColor: theme.palette.grey.accent4,
}));

const BridgeDetail = () => {
  const { token, collection } = useLocation().state;
  const { t } = useTranslation("translation", {
    keyPrefix: "bridgeNft",
  });
  const { active, account } = useWeb3React();
  const setActiveMenu = useNavigationHook();
  const navigate = useNavigate();
  const isDesktopSize = useMediaQuery(theme => theme.breakpoints.up("md"));
  const [footerHeight, setFooterHeight] = useState(0);
  const footerRef = useRef(null);
  useEffect(() => {
    setFooterHeight(footerRef.current.clientHeight);
  }, []);

  useEffect(() => {
    setActiveMenu(true);
  }, [setActiveMenu]);

  useEffect(() => {
    if (!active) handleOnClickCancel();
  }, [active]);

  const handleOnClickReview = () => {
    trackTapReviewBridge();
    navigate("/wallet/nfts/my-collection/bridge-review-nft", {
      state: {
        collection,
        token,
      },
    });
  };

  const handleOnClickCancel = () =>
    navigate("/wallet/nfts/my-collection/bridge-nft", {
      state: {
        collection,
        token,
      },
    });

  const details = useMemo(
    () => [
      {
        id: "nft-name",
        title: t("NFT name"),
        value: token.metadata?.name,
      },
      {
        id: "nft-collection-name",
        title: t("Collection name"),
        value: collection.name,
      },
      {
        id: "nft-id",
        title: t("Token ID"),
        value: "#" + token.tokenID.toString().padStart(3, "0"),
      },
      {
        id: "nft-source",
        title: t("Source"),
        value: "ABC Chain",
      },
    ],
    [token, collection],
  );

  return (
    <>
      <Helmet>
        <title>{t("NFT")}</title>
      </Helmet>
      <Box maxWidth="552px" m="auto" mb={!isDesktopSize && `${footerHeight}px`}>
        <Box
          sx={{
            gap: "16px",
          }}
          p="16px"
          display="flex"
          flexDirection="column"
        >
          <Typography variant="subtitle">{t("You send")}</Typography>
          <Box
            data-testid="img_nft-image"
            component="img"
            src={token?.metadata?.image}
            height="80px"
            width="80px"
            borderRadius="16px"
            m="auto"
          />
          {details?.map(item => {
            return (
              <Box key={item.id} display="flex" justifyContent="space-between">
                <Typography
                  data-testid={`text_title-${item.id}`}
                  variant="body2"
                  sx={{ color: "grey.accent2" }}
                >
                  {item.title}
                </Typography>
                <Typography
                  data-testid={`text_detail-${item.id}`}
                  variant="body2"
                >
                  {item.value}
                </Typography>
              </Box>
            );
          })}
          <Typography variant="subtitle">{t("To")}</Typography>
          <Typography variant="subtitle">{t("Destination chain")}</Typography>
          <BoxMainInfo display="flex" alignItems="center" sx={{ gap: "8px" }}>
            <EthIcon />
            <Typography data-testid="text_chain-name" variant="body2">
              {t("Ethereum")}
            </Typography>
          </BoxMainInfo>
          <Typography variant="subtitle">{t("Wallet address")}</Typography>
          <BoxMainInfo sx={{ wordWrap: "break-word" }}>
            <Typography data-testid="text_wallet-address" variant="body2">
              {active ? account : "-"}
            </Typography>
          </BoxMainInfo>
          <BridgeInstruction />
        </Box>
      </Box>
      <Paper
        sx={{
          position: isDesktopSize ? "sticky" : "fixed",
          bottom: 0,
          left: 0,
          right: 0,
        }}
        elevation={0}
      >
        <Box
          ref={footerRef}
          p="16px"
          m="auto"
          width="360px"
          display="flex"
          flexDirection="column"
          sx={{ padding: "16px 16px 32px 16px", gap: "16px" }}
        >
          <Button
            data-testid="btn_review"
            variant="contained"
            sx={{ height: "48px", borderRadius: "24px" }}
            fullWidth
            display="flex"
            onClick={handleOnClickReview}
          >
            {t("Review")}
          </Button>
          <Button
            data-testid="btn_cancel"
            variant="text"
            fullWidth
            sx={{ height: "48px", borderRadius: "24px" }}
            onClick={handleOnClickCancel}
          >
            <Typography variant="button">{t("Cancel")}</Typography>
          </Button>
        </Box>
      </Paper>
    </>
  );
};

export default BridgeDetail;
