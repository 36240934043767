import React, { useState } from "react";
import useTranslation from "../../hooks/useTranslation";
import { Helmet } from "react-helmet-async";
import { Box } from "@mui/material";
import useNavigationHook from "../../hooks/useNavigation";
import { Outlet, useLocation } from "react-router-dom";
import MarketplaceTab from "features/wallet/components/marketPlaceTabs/MarketplaceTab";

const Marketplace = () => {
  const { t } = useTranslation("translation", { keyPrefix: "marketplace" });
  const location = useLocation();
  const setActiveMenu = useNavigationHook();
  const [activeTab, setActiveTab] = useState(
    location.pathname === "/marketplace/my-vouchers" ? 1 : 0,
  );

  React.useEffect(() => {
    setActiveMenu(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title>{t("Marketplace")}</title>
      </Helmet>
      <Box
        sx={{
          pt: "32px",
        }}
      >
        <MarketplaceTab
          activeTab={activeTab}
          onChange={index => setActiveTab(index)}
        />
        <Outlet />
      </Box>
    </>
  );
};

export default Marketplace;
