import React, { useState, useEffect, useRef } from "react";
import { Typography, Box, Button, Divider } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { getValueByLanguage } from "utils/getLanguage";
import { abcUnit } from "features/wallet/utils/abc";
import { coinFormatter } from "utils/coinFormatter";
import useWindowDimensions from "hooks/useWindowDimensions";
import useNavbarHeight from "hooks/useNavbarHeight";
import useMenuHeight from "hooks/useMenuHeight";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import GSB from "assets/icons/GSB.svg";
import GSBBanner from "assets/images/GSB-banner.png";
import { trackClickGSBCallCenter } from "mixpanel";
import GsbTncModal from "features/wallet/components/convertCoins/partnerGsb/GsbTncModal";

export const gsb = {
  id: "gsb",
  name: {
    en: "GSB Reward point",
    th: "GSB Reward point",
  },
  shortName: {
    en: "GSB",
    th: "GSB",
  },
  symbol: "GSB",
  unitName: {
    en: "GSB Reward Points",
    th: "GSB Reward Points",
  },
};

const PartnerConvertCoins = () => {
  const { width, height } = useWindowDimensions();
  const navbarHeight = useNavbarHeight();
  const menuHeight = useMenuHeight();

  const [footerHeight, setFooterHeight] = useState(0);
  const footerRef = useRef(null);
  useEffect(() => {
    setFooterHeight(footerRef.current.clientHeight);
  }, []);

  const [showTncModal, setShowTncModal] = useState(false);

  const handleClickReadMore = () => {
    setShowTncModal(true);
  };

  const handleClickCallCenter = () => {
    trackClickGSBCallCenter();
    window.open("tel:02-299-8888", "_self");
  };

  const renderRateCard = () => {
    return (
      <Box
        sx={{
          pt: "24px",
          pb: "20px",
          px: "16px",
          display: "grid",
          gap: "16px",
          bgcolor: "white",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box
            data-testid="img-partner"
            component="img"
            src={GSB}
            sx={{ width: "24px", height: "24px" }}
          />
          <Typography
            data-testid="lbl-partner-name"
            variant="body2"
            sx={{ ml: "8px" }}
          >
            {getValueByLanguage(gsb.shortName)}
          </Typography>
        </Box>
        <Box>
          <Typography data-testid="lbl-rate" variant="body1">
            {`${coinFormatter("1000")} ${getValueByLanguage(
              gsb.unitName,
            )} = ${coinFormatter("100")} ${abcUnit}`}
          </Typography>
        </Box>
        <Divider />
        <Box
          sx={{
            display: "flex",
            alignItems: width > 425 ? "center" : "flex-start",
          }}
        >
          <ErrorOutlineIcon
            sx={{ fontSize: "1.5rem", color: "grey.accent2" }}
          />
          <Typography
            data-testid="lbl-convert-desc"
            variant="body2"
            sx={{ ml: "8px", color: "grey.accent2" }}
          >
            {"ABC points will be added to your account within 5 - 7 biz days."}
          </Typography>
        </Box>
      </Box>
    );
  };

  const renderTnC = () => {
    return (
      <Box sx={{ p: "16px", bgcolor: "white" }}>
        <Typography data-testid="lbl-tile-tnc" variant="subtitle1">
          {"หลักเกณฑ์และเงื่อนไข การแลก GSB Reward Point"}
        </Typography>
        <Typography
          data-testid="lbl-tnc"
          variant="body2"
          sx={{ mt: "8px", color: "grey.accent2", lineHeight: "24px" }}
        >
          {
            "สิทธิพิเศษ สำหรับผู้ถือบัตรเครดิตธนาคารออมสิน สามารถใช้คะแนน GSB Reward Point 1,000 คะแนนแลกรับ 100 ABC Point เพื่อใช้แลกรับสินค้าหรือบริการต่างๆ ตามที่ ABC กำหนด ผ่าน ABC Point Program ภายใน ทรูมันนี่ วอลเล็ท ตั้งแต่วันนี้ – 31 ธันวาคม 2566 โดยแจ้งความประสงค์ผ่านช่องทาง GSB Credit Card Call Center โทร"
          }
          <Typography
            data-testid="link-tnc-tel-1"
            component="a"
            href="tel:02-299-8888"
            sx={{ color: "secondary.blue.accent1", textDecoration: "none" }}
          >
            {" 02-299-8888 "}
          </Typography>
          {
            "และ GSB Infinite Banking Call Center สำหรับผู้ถือบัตรเครดิต Prestige และ World Elite โทร"
          }
          <Typography
            data-testid="link-tnc-tel-2"
            component="a"
            href="tel:02-299-9999"
            sx={{ color: "secondary.blue.accent1", textDecoration: "none" }}
          >
            {" 02-299-9999"}
          </Typography>
          <Typography
            data-testid="link-tnc-readmore"
            component="a"
            sx={{
              color: "secondary.blue.accent1",
              textDecoration: "none",
              ml: "8px",
            }}
            onClick={handleClickReadMore}
          >
            {"Read more"}
          </Typography>
        </Typography>
      </Box>
    );
  };

  const renderFooter = () => {
    return (
      <Box
        ref={footerRef}
        sx={{
          position: "sticky",
          bottom: 0,
          backgroundColor: "background.paper",
          width: "100%",
          padding: "16px 16px 32px 16px",
        }}
      >
        <Button
          data-testid="btn-call-center"
          variant="contained"
          fullWidth
          sx={{ height: 48, borderRadius: "24px" }}
          onClick={handleClickCallCenter}
        >
          <Typography variant="button">{"GSB CALL CENTER"}</Typography>
        </Button>
      </Box>
    );
  };

  return (
    <>
      <Helmet>
        <title>{"Convert"}</title>
      </Helmet>
      <Box
        data-testid="partner-convert-coins-static"
        sx={{ bgcolor: "grey.accent4" }}
      >
        <Box
          sx={{
            overflow: "auto",
            height: `calc(${height}px - ${navbarHeight}px - ${footerHeight}px - ${menuHeight}px)`,
          }}
        >
          <Box
            sx={{ display: "flex", justifyContent: "center", bgcolor: "white" }}
          >
            <Box
              data-testid="img-banner"
              component="img"
              src={GSBBanner}
              sx={{ width: "100%", maxWidth: "820px" }}
            />
          </Box>
          {renderRateCard()}
          <Box sx={{ mt: "8px" }}>{renderTnC()}</Box>
        </Box>
        {renderFooter()}
      </Box>
      <GsbTncModal
        isVisible={showTncModal}
        onClose={() => setShowTncModal(false)}
      />
    </>
  );
};
export default PartnerConvertCoins;
