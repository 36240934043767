import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import useTranslation from "../../../../hooks/useTranslation";
import { Box } from "@mui/material";
// import useDebounce from "../../hooks/useDebounce";
import CardComponent from "../../components/card/CardComponent";
import DealsSkeleton from "../../skeletonLoading/DealsSkeleton";
import InfiniteScroll from "react-infinite-scroll-component";
import Grid from "@mui/material/Grid";
import DealsSearchBar from "./DealsSearchBar";
import EmptyDeals from "../../components/emptyDeals/EmptyDeals";
import Prompt from "../../../../components/Prompt/Prompt";
import {
  useDispatch,
  useSelector,
} from "../../../../redux/store/configureStore";
import useNavigationHook from "../../../../hooks/useNavigation";
import {
  clearDeals,
  nextPage,
  fetchAsyncDealsSearch,
  status as dealsSearchStatus,
} from "../../redux/slices/deals/DealsSearch";
import ProgressLoad from "../../../../components/progressLoad/ProgressLoad";

const SearchDeals = props => {
  const { t } = useTranslation("translation", { keyPrefix: "searchVoucher" });

  const setActiveMenu = useNavigationHook();
  const [term, setTerm] = useState("");
  const [isErrorModal, setIsErrorModal] = useState(false);
  // const [debouncedTerm] = useDebounce(term, 500);

  const dispatch = useDispatch();
  const { page, deals, hasMore, status } = useSelector(
    state => state.dealsSearch,
  );
  const limit = 10;

  // useEffect(() => {
  //   if (debouncedTerm) {
  //     if (status !== dealsSearchStatus.LOADING) {
  //       dispatch(clearDeals());
  //       dispatch(fetchAsyncDealsSearch({ page: 0, limit }));
  //     }
  //   }
  // }, [debouncedTerm]);

  useEffect(
    () => {
      if (term) {
        if (status !== dealsSearchStatus.LOADING) {
          return dispatch(fetchAsyncDealsSearch({ page, limit, term }));
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [page],
  );

  useEffect(() => {
    setActiveMenu(true);
  }, []);

  useEffect(() => {
    if (status === dealsSearchStatus.ERROR) {
      setIsErrorModal(true);
    }
  }, [status]);

  useEffect(
    () => {
      return () => dispatch(clearDeals());
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleSearch = () => {
    if (term) {
      if (status !== dealsSearchStatus.LOADING) {
        dispatch(clearDeals());
        dispatch(fetchAsyncDealsSearch({ page: 0, limit, term }));
      }
    }
  };

  const handleSearchChange = e => {
    if (e.target.value.length <= 30) setTerm(e.target.value);
  };
  const handleDeleteSearch = () => setTerm("");
  const handleNextPage = () => dispatch(nextPage(limit));
  const handleModalClose = () => setIsErrorModal(false);

  return (
    <>
      <Helmet>
        <title>{t("Search")}</title>
      </Helmet>
      <Box
        sx={{
          mt: "16px",
          mb: "16px",
          p: "0 20px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <DealsSearchBar
          id="search-bar"
          handleChange={handleSearchChange}
          value={term}
          handleDelete={handleDeleteSearch}
          handleSearch={handleSearch}
        />
        {status === dealsSearchStatus.LOADING ? (
          <DealsSkeleton id="search-loading" />
        ) : status === dealsSearchStatus.EMPTY ? (
          <EmptyDeals
            title={t("No Search Result")}
            subtitle={t("Try other keywords.")}
            id="search-empty"
          />
        ) : deals && status !== dealsSearchStatus.INITIAL ? (
          <>
            <InfiniteScroll
              id="search-scroll"
              dataLength={deals.length}
              next={handleNextPage}
              hasMore={hasMore}
              loader={<ProgressLoad />}
            >
              <Grid container spacing={2}>
                {deals.map(deal => {
                  return (
                    <Grid item xs={6} sm={4} md={3} lg={2} xl={1} key={deal.id}>
                      <CardComponent deal={deal} />
                    </Grid>
                  );
                })}
              </Grid>
            </InfiniteScroll>
          </>
        ) : null}
      </Box>
      <Prompt
        id="search-error"
        icon={"error"}
        isVisible={isErrorModal}
        onClose={handleModalClose}
        title={t("Search Error")}
        subTitle={t("Please try again.")}
        buttonText={t("OK")}
      />
    </>
  );
};

export default SearchDeals;
