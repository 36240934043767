import { mixpanel } from "./mixpanelConfig";
import jshashes from "jshashes";
import { formatDateTime } from "../utils/dateTimeFormatter";
import { getChannelConfig, EARTH_CHANNEL } from "../config";

const TMN_TH = "TMN Th";
const ABC_WALLET = "ABC Wallet";
const TMN = "TMN";

export const channelName =
  getChannelConfig()?.channel === EARTH_CHANNEL ? ABC_WALLET : TMN_TH;

export const trackOnboarding = () => {
  mixpanel.register({ "Channel Used": channelName });
  mixpanel.track("Visit Landing Page");
};

export const trackLoginSignup = user => {
  const hashedUserId = new jshashes.SHA256().hex(user.id.toString());

  mixpanel.identify(hashedUserId);
  mixpanel.register({
    "Channel Used": channelName,
  });
  const { linkedAccountList } = user;
  const loginMethod = linkedAccountList
    .filter(
      ({
        connected,
        displayName, // "Google", "Line"
      }) =>
        (displayName.toLowerCase() === "google" ||
          displayName.toLowerCase() === "line") &&
        connected === true,
    )
    .map(({ displayName }) => displayName);
  const registrationMethod =
    channelName === TMN_TH ? TMN : loginMethod[0] || "";
  mixpanel.people.set_once({
    $user_id: hashedUserId,
    "Registration Method": registrationMethod,
    "Registration Channel": channelName,
    "Registration Date": new Date().toISOString(),
  });
  mixpanel.people.union("Channel Used", channelName);
  mixpanel.track("Log In", { "Login Method": registrationMethod });

  mixpanel.people.union({
    "Login Method": channelName === TMN_TH ? TMN : loginMethod,
  });

  localStorage.setItem("mixpanel-last-user", hashedUserId);
};

export const trackVisitHomePage = coins => {
  mixpanel.people.set({
    "ABC Balance": coins.balance,
  });
  mixpanel.track("Visit ABC Home Page", {
    "ABC Balance": coins.balance,
  });
};

export const QUICK_ACTION_TYPE = {
  "Buy Voucher": "Buy Voucher",
  P2P: "P2P",
  P2C: "P2C",
  F2C: "F2C",
  NFT: "NFT",
  "My Voucher": "My Voucher",
};

export const trackHomeQuickAction = quickActionType => {
  mixpanel.track("Click Quick Action", {
    "Quick Action": quickActionType,
  });
};

export const trackMyWalletQuickAction = quickActionType => {
  mixpanel.track("Click Wallet CTA", {
    Action: quickActionType,
  });
};

export const trackBuyVoucher = (
  dealID,
  dealTitle,
  merchantName,
  price,
  redeem,
  balance,
) => {
  mixpanel.people.set_once(
    "First Buy Voucher Date",
    formatDateTime(redeem.redeemDetails.issuedAt, "DD MMM YYYY HH:mm"),
  );
  mixpanel.people.increment("# of Voucher Purchases", 1);
  mixpanel.people.set({
    "ABC Balance": balance - price,
    "Last Buy Voucher Date": formatDateTime(
      redeem.redeemDetails.issuedAt,
      "DD MMM YYYY HH:mm",
    ),
  });
  mixpanel.track("Buy Voucher", {
    "Merchant Name": merchantName,
    "Deal ID": dealID,
    "Deal Name": dealTitle,
    "Deal Price - ABC": price,
    "Buy Voucher Txn ID": redeem.redeemDetails.reference,
  });
};

/*export const trackLinkTrueId = trueId => {
  const linkedAccount = mixpanel.get_property("Linked Account");
  let newLinkedAccount = linkedAccount ? linkedAccount : [];
  if (!newLinkedAccount.includes("True ID")) {
    newLinkedAccount.push("True ID");
  }
  const trueIdHash = new jshashes.SHA256().hex(trueId);
  mixpanel.register({
    "Linked Account": newLinkedAccount,
  });
  mixpanel.people.set({
    "Linked Account": newLinkedAccount,
    "True ID": trueIdHash,
  });
  mixpanel.track("Link True ID", {
    "True ID": trueIdHash,
  });
};*/

export const trackConvertCoinsComplete = (
  partnerName,
  spendPoint,
  earnCoin,
  exchangeRate,
  accountId,
  abcBalance,
) => {
  const accountIdHash = new jshashes.SHA256().hex(accountId);
  mixpanel.people.increment("# of Buy Coin with Point", 1);
  mixpanel.people.set_once({
    "First Buy Coin with Point Date": new Date().toISOString(),
  });
  mixpanel.people.set({
    "Last Buy Coin with Point Date": new Date().toISOString(),
    "ABC Balance": abcBalance,
  });
  mixpanel.track("Buy ABC Coin with Point", {
    "Currency of the point": partnerName,
    "Spend Point": spendPoint,
    "Earn Coin": earnCoin,
    "P2C Exchange Rate": exchangeRate,
    "3rd Party Account ID": accountIdHash,
    "ABC Balance": abcBalance,
  });
};

export const trackVisitTransferCoindSendPage = () => {
  mixpanel.track("P2P Send Page");
};

export const trackVisitTransferCoindReviewPage = () => {
  mixpanel.track("P2P Review Page");
};

export const trackLogOut = () => {
  mixpanel.track("Log Out");
  if (channelName === TMN_TH) {
    mixpanel.reset();
  }
};

export const trackTransferCoinComplete = (
  sendAmount,
  sendTo,
  transactionId,
) => {
  mixpanel.people.increment("# of P2P", 1);
  mixpanel.people.set_once({
    "First P2P Date": new Date().toISOString(),
  });
  mixpanel.people.set({
    "Last P2P Date": new Date().toISOString(),
  });
  mixpanel.track("P2P Success", {
    "Send Amount": sendAmount,
    "Send To": sendTo,
    "P2P Txn ID": transactionId,
  });
};

export const trackVisitMarketplacePage = () => {
  mixpanel.track("Visit Marketplace Page");
};

export const trackViewVoucher = (
  merchantName,
  merchantCategory,
  dealId,
  dealName,
  dealPrice,
) => {
  mixpanel.track("View Voucher", {
    "Merchant Name": merchantName,
    "Merchant Category": merchantCategory,
    "Deal ID": dealId,
    "Deal Name": dealName,
    "Deal Price - ABC": dealPrice,
  });
};

export const trackVisitMyWalletPage = abcBalance => {
  mixpanel.people.set({
    "ABC Balance": abcBalance,
  });
  mixpanel.track("Visit My Wallet Page", {
    "ABC Balance": abcBalance,
  });
};

export const trackClickLoyaltyBanner = partnerName => {
  mixpanel.track("Click Loyalty Banner", {
    "Partner Name": partnerName,
  });
};

export const trackStartLinkingLoyaltyAccount = partnerName => {
  mixpanel.track("Start Linking Loyalty Account", {
    "Partner Name": partnerName,
  });
};

export const trackStartPointConversion = ({ partnerName, targetName }) => {
  mixpanel.track("Start Point Conversion", {
    Source: partnerName,
    Target: targetName,
  });
};

export const trackReviewPointConversion = ({
  partnerName,
  targetName,
  sourceAmount,
  targetAmount,
}) => {
  mixpanel.track("Review Point Conversion", {
    Source: partnerName,
    Target: targetName,
    "Source Amount": sourceAmount,
    "Target Amount": targetAmount,
  });
};

export const trackErrorPointConversion = ({
  partnerName,
  targetName,
  sourceAmount,
  targetAmount,
  errorCode,
  errorMessage,
}) => {
  mixpanel.track("Error Point Conversion", {
    Source: partnerName,
    Target: targetName,
    "Source Amount": sourceAmount,
    "Target Amount": targetAmount,
    "Error Code": errorCode,
    "Error Message":
      errorMessage?.length > 255
        ? errorMessage.substring(0, 255)
        : errorMessage,
  });
};

export const trackCompletedPointConversion = ({
  partnerName,
  tokenName,
  sourceAmount,
  targetAmount,
}) => {
  mixpanel.track("Completed Point Conversion", {
    Source: partnerName,
    Target: tokenName,
    "Source Amount": sourceAmount,
    "Target Amount": targetAmount,
  });
};

export const trackCompletedLinkingLoyaltyPartner = partnerName => {
  mixpanel.track("Completed Linking Loyalty Partner", {
    "Partner Name": partnerName,
  });
};

export const trackErrorLinkingLoyaltyPartner = ({
  partnerName,
  errorCode,
  errorMessage,
}) => {
  mixpanel.track("Error Linking Loyalty Partner", {
    "Partner Name": partnerName,
    Error: errorCode,
    "Error Description":
      errorMessage?.length > 255
        ? errorMessage.substring(0, 255)
        : errorMessage,
  });
};

export const trackVisitConvertPartnerListingPage = () => {
  mixpanel.track("Visit Convert Partner Listing Page");
};

export const trackVisitAllLinkedPartnersPage = () => {
  mixpanel.track("Visit All Linked Partners Page");
};

export const trackExpandVotePartner = () => {
  mixpanel.track("Expand Vote Partner");
};

export const trackSubmitVotePartner = input => {
  mixpanel.track("Submit Vote Partner", {
    input: input,
  });
};

export const trackChannelUsed = channel => {
  mixpanel.register({
    "Channel Used": channel,
  });
};

export const trackManageLinkedAccount = currentUrl => {
  mixpanel.track("Manage linked account", {
    current_url: currentUrl,
  });
};

export const trackVisitLinkedRewardsSettings = () => {
  mixpanel.track("Visit Linked Rewards Settings");
};

export const trackConfirmPartnerUnlink = ({ partnerName, reason = "" }) => {
  mixpanel.track("Confirm partner unlink", {
    "Partner Name": partnerName,
    Reason: reason,
  });
};

export const trackClickGSBIcon = () => {
  mixpanel.track("GSB icon");
};

export const trackClickGSBCallCenter = () => {
  mixpanel.track("GSB callcenter button");
};

//********************TMN********************

export const trackTMNSignup = (user, isSignup = false) => {
  mixpanel.TMN.identify(user.tmwID);
  isSignup && mixpanel.TMN.track("ABC Wallet - Register Success");
};

export const trackTMNVisitHomePage = coins => {
  mixpanel.TMN.people.set({
    "Last View ABC Wallet": new Date().toISOString(),
    "Last ABC Wallet Balance": coins.balance,
  });
  mixpanel.TMN.track("ABC Wallet - Home Landing", {
    "ABC Balance": coins.balance,
  });
};

export const trackTMNRedeemCoupon = (dealTitle, merchantName, price) => {
  mixpanel.TMN.track("ABC Wallet - Redeem Coupon", {
    "Merchant Name": merchantName,
    "Coupon Name": dealTitle,
    Amount: price,
  });
};
