import { useState } from "react";
import CoinsAPI from "../../../apiConfig/CoinsAPI";
import useAuth from "../../../hooks/useAuth";
import { phoneNumberWithThaiCountryCode } from "../../../utils/phoneNumberFormatter";

const useSendCoin = () => {
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);

  const sendCoin = async (receiver, amount) => {
    setLoading(true);
    try {
      const response = await CoinsAPI.post("/transfers", {
        amount,
        receiver: phoneNumberWithThaiCountryCode(receiver),
      });
      console.log(response);
      setLoading(false);
      setResponse(response);
    } catch (err) {
      console.log(err);
      setLoading(false);
      setResponse(null);
      if (err.response) {
        setError({ message: err.response.data.message });
        return;
      }

      setError({ message: "Network error" });
    }

    // if (phoneNumber === "123") {
    //   setTimeout(() => {
    //     setLoading(false);
    //     setResponse({ status: "success" });
    //     setError(null);
    //   }, 2000);
    //   return;
    // }

    // setTimeout(() => {
    //   setLoading(false);
    //   setResponse(null);
    //   setError({ message: "Failed to send coin" });
    // }, 2000);
  };

  return { response, error, loading, sendCoin };
};

export default useSendCoin;
