import { useState, useEffect, useMemo } from "react";
import { useMediaQuery } from "@mui/material";

export default function useNavbarHeight() {
  const matches = useMediaQuery(theme => theme.breakpoints.up("sm"));
  const [navbarHeight, setNavbarHeight] = useState(0);

  const height = useMemo(() => {
    return matches ? 64 : 57;
  }, [matches]);

  useEffect(() => {
    setNavbarHeight(height);
  }, [height]);

  return navbarHeight;
}
