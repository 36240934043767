import { Box, Divider } from "@mui/material";
import SwapIcon from "../../../../../assets/icons/swap.svg";

const ConvertSeperation = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        mt: "24px",
        mb: "24px",
      }}
    >
      <Box
        alt="Coins"
        component="img"
        src={SwapIcon}
        sx={{
          objectFit: "cover",
          height: "24px",
          width: "24px",
          mr: "16px",
        }}
      />
      <Divider
        light
        sx={{
          flex: 1,
        }}
      />
    </Box>
  );
};

export default ConvertSeperation;
