import axios from "axios";
import { apiEndpoint } from "../config";
import refreshInterceptor from "./refreshInterceptor/refreshInterceptor";

const KeycloakAPI = axios.create({
  baseURL: apiEndpoint.keycloak,
  headers: {
    "content-type": "application/json",
  },
});
KeycloakAPI.interceptors.request.use(refreshInterceptor);

export default KeycloakAPI;
