import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import useTranslation from "../../../../hooks/useTranslation";
import useNavigationHook from "../../../../hooks/useNavigation";
import NftTab from "../../components/nftTab/NftTab";
import { getFeatureFlags } from "../../../../config";
import { trackReVisitNft, trackVisitNft } from "mixpanel/nft";
import { useDispatch, useSelector } from "redux/store/configureStore";
import { increaseVisitNftTimes } from "features/wallet/redux/slices/nftMyCollection/NftMyCollection";

const Nft = () => {
  const { t } = useTranslation("translation", {
    keyPrefix: "walletVoucherTab",
  });
  const setActiveMenu = useNavigationHook();
  const { enableNftMyCollection, enableNftOtherCollection, enableNftBridge } =
    getFeatureFlags();
  const tabLinks = [
    "/wallet/nfts",
    enableNftOtherCollection ? "/wallet/nfts/other-collectibles" : null,
    enableNftBridge ? "/wallet/nfts/my-pending" : null,
  ].filter(v => v !== null);

  const [activeTab, setActiveTab] = useState(
    tabLinks.indexOf(window.location.pathname),
  );
  const dispatch = useDispatch();
  const { visitNftTimes } = useSelector(state => state.nftMyCollection);
  const [isTrackVisitNft, setIsTrackVisitNft] = useState(false);

  useEffect(() => {
    setActiveMenu(true);
  }, [setActiveMenu]);

  useEffect(() => {
    if (isTrackVisitNft) return;
    if (visitNftTimes > 0) {
      trackReVisitNft();
    } else {
      trackVisitNft();
    }
    setIsTrackVisitNft(true);
    dispatch(increaseVisitNftTimes());
  }, [dispatch, visitNftTimes, isTrackVisitNft]);

  return (
    <>
      <Helmet>
        <title>{t("NFT")}</title>
      </Helmet>
      <Box sx={{ mt: "4px" }}>
        {enableNftMyCollection && (
          <NftTab onChange={setActiveTab} activeTab={activeTab} />
        )}
        <Outlet />
      </Box>
    </>
  );
};

export default Nft;
