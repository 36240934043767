import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  OutlinedInput,
  Typography,
} from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { styled } from "@mui/system";
import { Helmet } from "react-helmet-async";
import { useLocation, useNavigate } from "react-router-dom";
import useTranslation from "../../../../hooks/useTranslation";
import useNavigationHook from "../../../../hooks/useNavigation";
import Prompt from "../../../../components/Prompt/Prompt";
import {
  fetchAsyncNftCheckMobileNumber,
  status as NftCheckMobileNumberStatus,
  resetNftCheckMobileNumber,
} from "../../../wallet/redux/slices/nftCheckMobileNumber/NftCheckMobileNumber";
import { phoneNumberFormatter2 } from "../../../../utils/phoneNumberFormatter";
import NftThumbnail from "../../components/nftThumbnail/NftThumbnail";
import { trackFindPeerNFTUnsuccess } from "../../../../mixpanel/nft";

const MobileNumberOutlinedInput = styled(OutlinedInput)(({ theme }) => ({
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: theme.palette.grey.accent3,
  },
  "&.Mui-error .MuiOutlinedInput-notchedOutline": {
    borderColor: theme.palette.error.main,
  },
}));

const regEx = /^\d*$/;

const SendNft = () => {
  const { token, collection } = useLocation().state;
  const { t } = useTranslation("translation", {
    keyPrefix: "nftMyCollection",
  });
  const navigate = useNavigate();
  const setActiveMenu = useNavigationHook();
  const dispatch = useDispatch();
  const {
    destinationProfile,
    status: checkMobileNumberStatus,
    errorMessage: checkMobileNumberErrorMessage,
  } = useSelector(state => state.nftCheckMobileNumber);

  const [promptOpen, setPromptOpen] = useState(false);
  const [promptError, setPromptError] = useState({
    title: null,
    subtitle: null,
    buttonText: null,
    onClose: null,
  });

  const [mobileNumber, setMobileNumber] = useState({
    number: "",
    errorMessage: "",
  });

  useEffect(() => {
    setActiveMenu(true);
  }, [setActiveMenu]);

  useEffect(() => {
    return () => {
      dispatch(resetNftCheckMobileNumber());
    };
  }, [dispatch]);

  useEffect(() => {
    if (checkMobileNumberStatus === NftCheckMobileNumberStatus.ERROR) {
      if (checkMobileNumberErrorMessage === "user not found") {
        trackFindPeerNFTUnsuccess();
        setPromptError({
          title: "Mobile number not found",
          subtitle: "This mobile no. is not registered for ABC Wallet service.",
          buttonText: "OK",
          onClose: () => setPromptOpen(false),
        });
        setPromptOpen(true);
      } else {
        setPromptError({
          title: "Oops! there was an issue",
          subtitle: "Please try again",
          buttonText: "Back to NFT",
          onClose: () => navigate("/wallet/nfts"),
        });
        setPromptOpen(true);
      }
    }
  }, [checkMobileNumberStatus, checkMobileNumberErrorMessage, navigate]);

  useEffect(() => {
    if (checkMobileNumberStatus === NftCheckMobileNumberStatus.LOADED) {
      navigate("/wallet/nfts/my-collection/review-send-nft", {
        state: {
          token,
          collection,
          destinationProfile,
        },
      });
    }
  }, [
    checkMobileNumberStatus,
    token,
    collection,
    destinationProfile,
    navigate,
  ]);

  const handleOnClickReview = () => {
    dispatch(fetchAsyncNftCheckMobileNumber(mobileNumber.number));
  };

  const handleChangeMobileNumber = e => {
    const { value } = e.target;
    const justPhoneString = value.replace(/-/g, "");
    if (!justPhoneString) {
      setMobileNumber({ number: "", errorMessage: "" });
      return;
    }
    if (justPhoneString.length > 10) {
      return;
    }
    if (!regEx.test(justPhoneString)) {
      setMobileNumber({
        number: mobileNumber.number,
        errorMessage: t("Invalid mobile no."),
      });
      return;
    }
    if (justPhoneString[0] !== "0") {
      setMobileNumber({
        number: phoneNumberFormatter2(justPhoneString),
        errorMessage: t("Invalid mobile no."),
      });
      return;
    }
    setMobileNumber({
      number: phoneNumberFormatter2(justPhoneString),
      errorMessage: "",
    });
  };

  const onEnter = e => {
    if (e.key === "Enter") {
      e.target.blur();
    }
  };

  return (
    <>
      <Helmet>
        <title>{t("Send NFT")}</title>
      </Helmet>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          p: "32px 16px 44px",
          backgroundColor: "background.paper",
          height: "calc(100vh - 50px)",
        }}
      >
        <Box>
          <Box sx={{ mb: "16px" }}>
            <Typography variant="subtitle">{t("You send")}</Typography>
          </Box>
          <Box sx={{ mb: "24px" }}>
            <NftThumbnail token={token} />
          </Box>
          <Box sx={{ mb: "16px" }}>
            <Typography variant="subtitle">{t("To")}</Typography>
          </Box>
          <Box sx={{ mb: "8px" }}>
            <FormControl fullWidth error={!!mobileNumber.errorMessage}>
              <MobileNumberOutlinedInput
                value={mobileNumber.number}
                onChange={handleChangeMobileNumber}
                placeholder={t("Enter mobile no.")}
                sx={{
                  borderRadius: "16px",
                }}
                inputProps={{
                  inputMode: "numeric",
                  "data-testid": "input-mobile-no",
                }}
                onKeyUp={onEnter}
              />
              {!!mobileNumber.errorMessage && (
                <FormHelperText sx={{ m: 0, mt: "6px" }}>
                  <Box
                    component="span"
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <ErrorOutlineIcon sx={{ mr: "10px" }} />
                    {mobileNumber.errorMessage}
                  </Box>
                </FormHelperText>
              )}
            </FormControl>
          </Box>
          <Box sx={{ mb: "32px" }}>
            <Typography variant="helpingText" sx={{ color: "grey.accent2" }}>
              {t("You can only send NFT to other ABC Wallet users")}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Button
            data-testid="btn-review"
            color="primary"
            variant="contained"
            sx={{ borderRadius: 16, width: "100%", height: "50px" }}
            disabled={
              checkMobileNumberStatus === NftCheckMobileNumberStatus.LOADING ||
              !!mobileNumber.errorMessage ||
              !mobileNumber.number ||
              mobileNumber.number.replace(/-/g, "").length !== 10
            }
            onClick={handleOnClickReview}
          >
            <Typography variant="button">{t("REVIEW")}</Typography>
          </Button>
        </Box>
      </Box>
      <Prompt
        isVisible={promptOpen}
        icon={"error"}
        onClose={promptError.onClose}
        title={promptError.title}
        subTitle={promptError.subtitle}
        buttonText={promptError.buttonText}
        keyPrefix={"nftMyCollection"}
      />
    </>
  );
};

export default SendNft;
