import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import KeycloakAPI from "apiConfig/KeycloakAPI";
import { Status } from "../../../../../redux/constant";

const initialState = {
  data: null,
  status: Status.INITIAL,
  errorMessage: null,
};

export const fetchKeycloakAccount = createAsyncThunk(
  "keycloak/fetchKeycloakAccount",
  async (_, { rejectWithValue }) => {
    try {
      const response = await KeycloakAPI.get("/account");
      return response.data;
    } catch (err) {
      if (err.response) {
        return rejectWithValue({
          status: err.response.status,
          data: err.response.data,
        });
      }
      return rejectWithValue({
        status: "",
        data: err.message,
      });
    }
  },
);

const KeycloakAccountSlice = createSlice({
  name: "keycloakAccount",
  initialState,
  reducers: {
    resetKeycloakAccount: state => {
      state.data = null;
      state.status = Status.INITIAL;
      state.errorMessage = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchKeycloakAccount.pending, state => {
        state.errorMessage = null;
        state.status = Status.LOADING;
      })
      .addCase(fetchKeycloakAccount.fulfilled, (state, { payload }) => {
        state.data = payload;
        state.status = Status.LOADED;
      })
      .addCase(fetchKeycloakAccount.rejected, (state, { payload }) => {
        if (payload === undefined) {
          state.status = Status.ERROR;
          return;
        }
        state.errorMessage = payload.data;
        state.status = Status.ERROR;
      });
  },
});

export const { resetKeycloakAccount } = KeycloakAccountSlice.actions;
export default KeycloakAccountSlice.reducer;
