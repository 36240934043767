import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import CoinsAPI from "apiConfig/CoinsAPI";
import { phoneNumberWithThaiCountryCode } from "../../../../../utils/phoneNumberFormatter";
import { getChannelConfig } from "config";

export const status = {
  INITIAL: "INITIAL",
  LOADING: "LOADING",
  LOADED: "LOADED",
  EMPTY: "EMPTY",
  ERROR: "ERROR",
};

const initialState = {
  transfer: null,
  status: status.INITIAL,
  errorMessage: null,
};

export const fetchAsyncTransferCoin = createAsyncThunk(
  "transfer/fetchAsyncTransferCoin",
  async ({ amount, receiver }, { rejectWithValue }) => {
    try {
      const abcCoinSymbol = getChannelConfig().abcCoinSymbol;
      const response = await CoinsAPI.post(`/${abcCoinSymbol}/transfers`, {
        amount,
        receiver: phoneNumberWithThaiCountryCode(receiver),
      });
      return {
        status: response.status,
        data: response.data,
      };
    } catch (err) {
      return rejectWithValue({
        status: err.response.status,
        data: err.response.data,
      });
    }
  },
);

const slice = createSlice({
  name: "transferCoin",
  initialState,
  reducers: {
    resetTransferCoin: (state, action) => {
      state.transfer = null;
      state.errorMessage = null;
      state.status = status.INITIAL;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchAsyncTransferCoin.pending, state => {
        state.errorMessage = null;
        state.status = status.LOADING;
      })
      .addCase(fetchAsyncTransferCoin.fulfilled, (state, { payload }) => {
        if (payload.data === undefined) {
          state.status = status.ERROR;
          return;
        }

        state.transfer = payload.data;
        state.errorMessage = null;
        state.status = status.LOADED;
      })
      .addCase(fetchAsyncTransferCoin.rejected, (state, { payload }) => {
        if (payload === undefined) {
          state.status = status.ERROR;
          return;
        }
        state.errorMessage = payload.data;
        state.status = status.ERROR;
      });
  },
});

export const { resetTransferCoin } = slice.actions;

export default slice.reducer;
