import {
  Avatar,
  Badge,
  Box,
  Button,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import {
  fetchUserProfilePhoto,
  resetUserProfilePhoto,
} from "features/wallet/redux/slices/userProfilePhoto/userProfilePhoto";
import useAuth from "hooks/useAuth";
import useTranslation from "hooks/useTranslation";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Status } from "redux/constant";
import { useDispatch, useSelector } from "redux/store/configureStore";
import { ReactComponent as CameraLogo } from "../../../../assets/icons/account-settings-icon/camera.svg";
import { ReactComponent as ProfileLogo } from "../../../../assets/icons/account-settings-icon/profile.svg";

const MAX_SIZE = 2000000; // 2MB
const ProfilePhotoBtn = () => {
  const { user } = useAuth();
  const [isError, setIsError] = useState(false);
  const { t } = useTranslation("translation", {
    keyPrefix: "profilePhotoUpload",
  });
  const { userProfilePhoto, status: userProfilePhotoStatus } = useSelector(
    state => state.userProfilePhoto,
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleGotItButtonClick = () => {
    setIsError(false);
  };

  const handleSelectedImage = e => {
    const file = e.target.files[0];
    if (
      file.size > MAX_SIZE ||
      !["image/jpeg", "image/png"].includes(file.type)
    ) {
      setIsError(true);
      return;
    }
    navigate("/settings/profile-photo", {
      state: { photoFile: file },
    });
  };

  useEffect(() => {
    if (userProfilePhotoStatus === Status.INITIAL) {
      dispatch(fetchUserProfilePhoto({ userId: user.id }));
    }
    return () => {
      dispatch(resetUserProfilePhoto());
    };
  }, []);

  return (
    <IconButton
      id="icon-btn"
      data-testid="icon-btn"
      aria-label="upload picture"
      component="label"
    >
      <input
        id="photo-input"
        data-testid="photo-input"
        hidden
        type="file"
        accept="image/png, image/jpeg"
        name="myImage"
        onChange={handleSelectedImage}
      />
      <Badge
        overlap="circular"
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        badgeContent={<CameraLogo alt="camera" />}
      >
        {userProfilePhotoStatus === Status.LOADED ? (
          <Avatar
            data-testid="avatar-photo"
            alt={userProfilePhoto.firstNameEN}
            src={userProfilePhoto.profilePicture}
            sx={{ height: 80, width: 80 }}
          />
        ) : (
          <Avatar data-testid="avatar-photo" sx={{ height: 80, width: 80 }}>
            <ProfileLogo />
          </Avatar>
        )}
      </Badge>
      <Modal
        open={isError}
        onClose={handleGotItButtonClick}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          alignContent: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            bgcolor: "background.paper",
            maxWidth: "340px",
            m: "16px",
            gap: 2,
            borderRadius: "16px",
            p: "24px",
            overflowY: "auto",
            maxHeight: "100%",
          }}
        >
          <Typography variant="h6" sx={{ textAlign: "center" }}>
            {t("Cannot upload")}
          </Typography>
          <Typography sx={{ textAlign: "center" }}>
            {t(
              "The photo must be jpg or png, and the file size must be less than 2MB. Choose another photo.",
            )}
          </Typography>
          <Button
            onClick={handleGotItButtonClick}
            sx={{ borderRadius: "24px", width: "100%", height: "48px" }}
            fullWidth
            variant="contained"
          >
            {t("GOT IT")}
          </Button>
        </Box>
      </Modal>
    </IconButton>
  );
};

export default ProfilePhotoBtn;
