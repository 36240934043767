import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import PointExchangeAPI from "../../../../../apiConfig/PointExchangeAPI";
import { Status } from "../../../../../redux/constant";

const initialState = {
  convertPartnerSwap: {},
  status: Status.INITIAL,
  errorMessage: null,
};

export const fetchAsyncConvertPartnerSwap = createAsyncThunk(
  "convertPartnerSwap/fetchAsyncConvertPartnerSwap",
  async (
    { sourceId, targetId, sourceAmount, targetAmount },
    { rejectWithValue },
  ) => {
    try {
      const response = await PointExchangeAPI.post("/swaps", {
        sourceId,
        targetId,
        sourceAmount,
        targetAmount,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue({
        status: err.response.status,
        data: err.response.data,
      });
    }
  },
);

const ConvertPartnerSwapSlice = createSlice({
  name: "convertPartnerSwap",
  initialState,
  reducers: {
    resetConvertPartnerSwap: state => {
      state.convertPartnerSwap = [];
      state.status = Status.INITIAL;
      state.errorMessage = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchAsyncConvertPartnerSwap.pending, state => {
        state.errorMessage = null;
        state.status = Status.LOADING;
      })
      .addCase(fetchAsyncConvertPartnerSwap.fulfilled, (state, { payload }) => {
        state.convertPartnerSwap = payload;
        state.status = Status.LOADED;
      })
      .addCase(fetchAsyncConvertPartnerSwap.rejected, (state, { payload }) => {
        if (payload === undefined) {
          state.status = Status.ERROR;
          return;
        }
        state.errorMessage = payload.data;
        state.status = Status.ERROR;
      });
  },
});

export const { resetConvertPartnerSwap } = ConvertPartnerSwapSlice.actions;

export default ConvertPartnerSwapSlice.reducer;
