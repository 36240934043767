import { Box, Typography, Modal, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { styled } from "@mui/system";
import LoadingIcon from "assets/icons/loading.svg";
import useTranslation from "hooks/useTranslation";
import { useDispatch, useSelector } from "react-redux";
import {
  status as NftMintingStatus,
  resetNftMinting,
  fetchAsyncNftMinting,
} from "features/wallet/redux/slices/nftMinting/nftMinting";
import { decrypt } from "utils/cryptoData";
import { useNavigate } from "react-router-dom";

const LoadingAnimationBox = styled(Box)`
  animation-duration: 1.4s;
  animation-timing-function: linear;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-fill-mode: none;
  animation-play-state: running;
  animation-name: animation-61bdi0;
`;

const QrNft = () => {
  const { t } = useTranslation("translation", {
    keyPrefix: "nftMinting",
  });
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { status: nftMintingStatus, errorMessage } = useSelector(
    state => state.nftMinting,
  );
  const { visitedConsentPage } = useSelector(state => state.consent);

  const mintingInitialNft = async () => {
    if (localStorage.getItem("nftCode") && visitedConsentPage) {
      try {
        const collectionAddress = decrypt(localStorage.getItem("nftCode"));
        dispatch(
          fetchAsyncNftMinting({ address: collectionAddress, amount: 1 }),
        );
        localStorage.removeItem("nftCode");
        setOpen(true);
      } catch (error) {
        localStorage.removeItem("nftCode");
      }
    }
  };

  useEffect(() => {
    mintingInitialNft();
    return () => {
      dispatch(resetNftMinting());
    };
  }, [dispatch, visitedConsentPage]);

  if (!open) return null;

  const renderError = status =>
    status === 400
      ? t("Sorry! Your minting limit is reached")
      : t("Sorry! The collection is run out of minting limit");

  return (
    <Modal
      data-testid="modal_minting"
      open={open}
      disableAutoFocus={true}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        alignContent: "center",
        textAlign: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          bgcolor: "background.paper",
          width: "100%",
          maxWidth: "340px",
          minHeight: "144px",
          m: "16px",
          justifyContent: "space-between",
          borderRadius: "16px",
          p: "24px",
          overflowY: "auto",
          maxHeight: "100%",
          alignItems: "center",
        }}
      >
        {nftMintingStatus === NftMintingStatus.LOADING ||
        nftMintingStatus === NftMintingStatus.INITIAL ? (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            sx={{ gap: "8px" }}
          >
            <LoadingAnimationBox
              data-testid="img_loading-icon"
              sx={{
                marginBottom: "24px",
                width: "48px",
                height: "48px",
              }}
            >
              <Box
                id="img_logoPopup"
                component="img"
                src={LoadingIcon}
                sx={{
                  width: "48px",
                  height: "48px",
                }}
              />
            </LoadingAnimationBox>
            <Typography variant="body2">{t("Minting")}</Typography>
          </Box>
        ) : (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            width="100%"
            sx={{ gap: "32px" }}
          >
            <Typography variant="body1">
              {nftMintingStatus === NftMintingStatus.ERROR
                ? renderError(errorMessage?.statusCode)
                : t("Congratulations! Your NFT is Minted")}
            </Typography>
            <Button
              variant="contained"
              sx={{
                height: "48px",
                borderRadius: "24px",
              }}
              fullWidth
              display="flex"
              onClick={() => {
                navigate("/");
                setOpen(false);
              }}
            >
              {t("Ok")}
            </Button>
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default QrNft;
