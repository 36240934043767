import {
  Box,
  Avatar,
  FormControl,
  FormHelperText,
  Input,
  Skeleton,
  Typography,
  Select,
  MenuItem,
} from "@mui/material";
import { styled } from "@mui/system";
import useTranslation from "../../../../../hooks/useTranslation";
import { Status } from "../../../../../redux/constant";
import { coinFormatter } from "../../../../../utils/coinFormatter";

const CustomSelect = styled(Select)({
  "& .MuiSelect-select": {
    backgroundColor: "white",
  },
});

const ConvertInputCard = ({
  partnerBalanceStatus,
  partnerNameStatus,
  partnerName,
  partnerBalance,
  partnerBalanceUnit,
  partnerBalanceErrorMessage,
  logo,
  min,
  max,
  values,
  setValues,
  error,
  setError,
  dropdownOption,
}) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "convertCoins",
  });

  const handleChange = event => {
    const regEx = /^\d*$/;
    if (!values.balance && event.target.value === "0") {
      return;
    }
    if (!regEx.test(event.target.value)) {
      return;
    }
    setValues({ ...values, balance: event.target.value });
    setError({
      hasError: false,
      message: "",
    });
  };

  const inputPlaceholder = () => {
    if (
      min !== null &&
      typeof min !== "undefined" &&
      max !== null &&
      typeof max !== "undefined"
    ) {
      return `${coinFormatter(min.toString())}-${coinFormatter(
        max.toString(),
      )}`;
    }
    return "0";
  };

  const renderHelperText = () => {
    if (partnerBalanceStatus === Status.LOADING) {
      return (
        <Skeleton
          animation="wave"
          height={"20px"}
          width="100px"
          id="balance-loading"
          data-testid="balance-loading-skeleton"
        />
      );
    } else if (partnerBalanceStatus === Status.ERROR) {
      return `ERROR: ${partnerBalanceErrorMessage}`;
    } else if (partnerBalanceStatus === Status.LOADED) {
      if (error.hasError) {
        return error.message;
      }
      return `${t("Partner Balance")} ${coinFormatter(
        partnerBalance?.toString(),
      )} ${partnerBalanceUnit}`;
    }
    return null;
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          backgroundColor: "background.paper",
          border: 1,
          borderColor: "grey.accent3",
          borderRadius: "16px",
          mb: "24px",
          p: "16px",
        }}
        data-testid="convert-input-card"
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            mr: "15px",
            minWidth: "90px",
          }}
        >
          <Typography id="lbl_Section_ConvertLoyaltyPointPage" variant="body1">
            {partnerNameStatus === Status.LOADED ? (
              partnerName
            ) : (
              <Skeleton
                animation="wave"
                height={"30px"}
                width="100px"
                id="partner-loading"
                data-testid="partner-loading-skeleton"
              />
            )}
          </Typography>
          <Box
            sx={{
              display: "flex",
            }}
          >
            <Avatar
              id="img_Logo_ConvertLoyaltyPointPage"
              sx={{
                height: "40px",
                width: "40px",
                mt: "15px",
              }}
              src={logo}
            />
          </Box>
        </Box>
        <FormControl
          variant="standard"
          sx={{
            display: "flex",
            flexGrow: 1,
            mt: 3,
          }}
        >
          {!!dropdownOption && Array.isArray(dropdownOption) ? (
            <CustomSelect
              labelId="txt_ConvertLoyaltyPointPage-label"
              id="txt_ConvertLoyaltyPointPage"
              data-testid="txt_ConvertLoyaltyPointPage-select"
              value={values.balance || 0}
              error={error.hasError}
              onChange={handleChange}
              color={!error.hasError ? "grey" : "primary"}
              sx={{
                height: "55px",
                fontSize: values.balance ? "32px" : "20px",
                fontWeight: 600,
                color: error.hasError ? "#E50914" : "#141312",
              }}
            >
              <MenuItem
                value={0}
                disabled
                sx={{ display: "none", fontSize: "10px" }}
              >
                {t("Select")}
              </MenuItem>
              {dropdownOption.map(item => (
                <MenuItem
                  key={item}
                  value={item}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "20px",
                    fontWeight: 600,
                  }}
                >
                  {coinFormatter(item.toString())}
                </MenuItem>
              ))}
            </CustomSelect>
          ) : (
            <Input
              id="txt_ConvertLoyaltyPointPage"
              error={error.hasError}
              value={values.balance}
              inputProps={{
                inputMode: "numeric",
                style: {
                  color: error.hasError ? "#E50914" : "#141312",
                  fontWeight: 600,
                },
              }}
              color={!error.hasError ? "grey" : undefined}
              placeholder={inputPlaceholder()}
              sx={{ fontSize: "32px" }}
              onChange={handleChange}
            />
          )}
          <FormHelperText sx={{ display: "flex", justifyContent: "flex-end" }}>
            {partnerBalanceStatus ? (
              <Typography
                id="lbl_Balance_ConvertLoyaltyPointPage"
                color={error.hasError ? "error" : "grey.accent2"}
                variant="helpingText"
              >
                {renderHelperText()}
              </Typography>
            ) : (
              <Typography
                id="lbl_Balance_ConvertLoyaltyPointPage"
                color={error.hasError ? "error" : "grey.accent2"}
                variant="helpingText"
                sx={{ minHeight: "20px" }}
              >
                {error.message}
              </Typography>
            )}
          </FormHelperText>
        </FormControl>
      </Box>
    </>
  );
};

export default ConvertInputCard;
