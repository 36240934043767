import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Box } from "@mui/material";
import { useParams, useLocation } from "react-router-dom";
import CardComponent from "../../components/card/CardComponent";
import DealsSkeleton from "../../skeletonLoading/DealsSkeleton";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  useDispatch,
  useSelector,
} from "../../../../redux/store/configureStore";
import useNavigationHook from "../../../../hooks/useNavigation";
import GenericError from "../../../../pages/genericError/GenericError";
import {
  nextPage,
  fetchAsyncMerchantDeals,
  status as merchantDealsStatus,
} from "../../redux/slices/deals/MerchantDeals";
import ProgressLoad from "../../../../components/progressLoad/ProgressLoad";
import EmptyDeals from "../../components/emptyDeals/EmptyDeals";

const MerchantDeals = () => {
  let { id } = useParams();
  const { name } = useLocation()?.state;
  const setActiveMenu = useNavigationHook();
  const dispatch = useDispatch();
  const { page, deals, hasMore, status } = useSelector(
    state => state.merchantDeals,
  );
  const limit = 10;
  useEffect(() => {
    dispatch(fetchAsyncMerchantDeals({ page, limit, id }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, id]);

  useEffect(() => {
    setActiveMenu(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderDeals = () => {
    if (status === merchantDealsStatus.LOADING)
      return (
        <Box sx={{ m: "16px 0" }}>
          <DealsSkeleton id="merchant-loading" />
        </Box>
      );
    if (status === merchantDealsStatus.ERROR)
      return (
        <GenericError
          id="merchant-error"
          title="Service is not available"
          body="Service is not available at the moment. You may be able to try again."
          center={true}
        />
      );
    if (status === merchantDealsStatus.EMPTY)
      return (
        <Box sx={{ m: "32px" }}>
          <EmptyDeals
            id="merchant-empty"
            title="No Available Voucher"
            subtitle="Please check back soon for exciting new offers"
          />
        </Box>
      );

    if (deals && status !== merchantDealsStatus.INITIAL)
      return (
        <Box sx={{ m: "16px 0" }}>
          <InfiniteScroll
            id="merchant-scroll"
            dataLength={deals.length}
            next={() => dispatch(nextPage(limit))}
            hasMore={hasMore}
            loader={<ProgressLoad />}
          >
            <Box display="flex" flexWrap="wrap" gap={2}>
              {deals.map(deal => {
                return <CardComponent key={deal.id} deal={deal} />;
              })}
            </Box>
          </InfiniteScroll>
        </Box>
      );

    return null;
  };

  return (
    <>
      <Helmet>
        <title>{name}</title>
      </Helmet>
      <Box
        sx={{
          p: "0 16px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        {renderDeals()}
      </Box>
    </>
  );
};

export default MerchantDeals;
