import { Box, Skeleton } from "@mui/material";

const SkeletonPartnerButton = () => {
  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      data-testid="skeleton-partner-button"
    >
      <Skeleton
        variant="circular"
        sx={{
          height: "56px",
          width: "56px",
        }}
      />
      <Skeleton
        variant="text"
        sx={{
          color: "#333333",
          marginTop: "8px",
          width: "40px",
        }}
      />
    </Box>
  );
};

export default SkeletonPartnerButton;
