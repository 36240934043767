import { ReactKeycloakProvider } from "@react-keycloak/web";
import { getChannelConfig, keycloak } from "../config";
import SplashScreen from "../components/splashScreen/SplashScreen";

const KeycloakSwitch = ({ children }) => {
  if (getChannelConfig().keycloakLogin) {
    return (
      <>
        <ReactKeycloakProvider
          authClient={keycloak}
          LoadingComponent={<SplashScreen />}
          initOptions={{ checkLoginIframe: false }}
        >
          {children}
        </ReactKeycloakProvider>
      </>
    );
  }
  return <>{children}</>;
};

export default KeycloakSwitch;
