import { Contract } from "@ethersproject/contracts";
import ERC721HandlerAbi from "../abi/ERC721Handler.json";
import GalleryAbi from "../abi/Gallery.json";
import { rpcProvider } from "./staticProvider";

export const getContract = (abi, address, signer) => {
  const signerOrProvider = signer ?? rpcProvider;
  return new Contract(address, abi, signerOrProvider);
};

export const getERC721HandlerContract = (address, signer) => {
  return getContract(ERC721HandlerAbi, address, signer);
};

export const getGalleryContract = (address, signer) => {
  return getContract(GalleryAbi, address, signer);
};
