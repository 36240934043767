import React from "react";
import PhoneInput from "react-phone-input-2";
import { FormHelperText, Typography } from "@mui/material";
import "react-phone-input-2/lib/material.css";

const PhoneNumberField = ({ value, setValue, mobileValidate }) => {
  const handleChangeValue = (value, data, event, formattedValue) => {
    setValue(formattedValue || "");
  };

  return (
    <>
      <PhoneInput
        data-testid="phone-input"
        specialLabel=""
        country={"th"}
        countryCodeEditable={false}
        onlyCountries={["th"]}
        value={value}
        onChange={handleChangeValue}
        inputStyle={{
          borderRadius: "16px",
          fontSize: "16px",
          padding: "15.5px 14px 16.5px 58px",
          width: "100%",
          height: "56px",
          outline: "none",
          boxShadow: "none",
          border:
            mobileValidate.isError && mobileValidate.isShow
              ? "1px solid #DD2803"
              : "1px solid #000000",
        }}
      />
      {mobileValidate.isError && mobileValidate.isShow && (
        <FormHelperText sx={{ m: 0, mt: "6px" }}>
          <Typography
            component="span"
            variant="subtitle2"
            color="error.main"
            sx={{ fontWeight: "400" }}
          >
            {mobileValidate.message}
          </Typography>
        </FormHelperText>
      )}
    </>
  );
};

export default PhoneNumberField;
