import React from "react";
import OtpInput from "react-otp-input";

const OtpField = ({ value, setValue, isError, ...props }) => {
  const handleChange = code => setValue(code);

  return (
    <OtpInput
      value={value}
      onChange={handleChange}
      numInputs={6}
      separator={<span style={{ width: "8px" }}></span>}
      isInputNum={true}
      shouldAutoFocus={true}
      inputStyle={{
        border: "1px solid transparent",
        borderBottom: "1px solid #8C8C8A",
        backgroundColor: "transparent",
        width: "40px",
        height: "36px",
        fontSize: "24px",
        fontWeight: "bold",
      }}
      focusStyle={{
        outline: "none",
      }}
      hasErrored={isError}
      errorStyle={{
        borderBottom: "1px solid #E50914",
      }}
      {...props}
    />
  );
};

export default OtpField;
