import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ProfileAPI from "apiConfig/ProfileAPI";
import { Status } from "../../../../../redux/constant";

const initialState = {
  data: null,
  status: Status.INITIAL,
  errorMessage: null,
};

export const fetchAsyncOtpGenerate = createAsyncThunk(
  "accountSetting/fetchAsyncOtpGenerate",
  async ({ mobileNumber, userid }, { rejectWithValue }) => {
    try {
      const response = await ProfileAPI.post(
        `/profiles/otp/generate/${userid}`,
        {
          mobileNumber,
        },
      );
      return response.data;
    } catch (err) {
      if (err.response) {
        return rejectWithValue({
          status: err.response.status,
          data: err.response.data,
        });
      }
      return rejectWithValue({
        status: "",
        data: err.message,
      });
    }
  },
);

const EditMobileNumberSlice = createSlice({
  name: "editMobileNumber",
  initialState,
  reducers: {
    resetEditMobileNumber: state => {
      state.data = null;
      state.status = Status.INITIAL;
      state.errorMessage = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchAsyncOtpGenerate.pending, state => {
        state.errorMessage = null;
        state.status = Status.LOADING;
      })
      .addCase(fetchAsyncOtpGenerate.fulfilled, (state, { payload }) => {
        state.data = payload;
        state.status = Status.LOADED;
      })
      .addCase(fetchAsyncOtpGenerate.rejected, (state, { payload }) => {
        if (payload === undefined) {
          state.status = Status.ERROR;
          return;
        }
        state.errorMessage = payload.data;
        state.status = Status.ERROR;
      });
  },
});

export const { resetEditMobileNumber } = EditMobileNumberSlice.actions;
export default EditMobileNumberSlice.reducer;
