import React from "react";
import useTranslation from "../../hooks/useTranslation";
import { Outlet } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Box } from "@mui/material";
import useNavigationHook from "../../hooks/useNavigation";
import { isEarth } from "config";

const Wallet = () => {
  const { t } = useTranslation("translation", {
    keyPrefix: "walletVoucherTab",
  });
  const setActiveMenu = useNavigationHook();

  React.useEffect(() => {
    setActiveMenu(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title>{isEarth ? t("ABC Coin") : t("ABC Point")}</title>
      </Helmet>
      <Box
        sx={{
          pt: "32px",
        }}
        data-testid="wallet"
      >
        <Outlet />
      </Box>
    </>
  );
};

export default Wallet;
