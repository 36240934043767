import { Button, Container, Modal, Typography } from "@mui/material";
import { Box } from "@mui/system";
import LoadingPrompt from "components/loadingPrompt/LoadingPrompt";
import {
  resetUploadProfilePhoto,
  uploadProfilePhoto,
} from "features/wallet/redux/slices/profilePhotoUpload/profilePhotoUpload";
import useAuth from "hooks/useAuth";
import useNavigationHook from "hooks/useNavigation";
import useTranslation from "hooks/useTranslation";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { useLocation, useNavigate } from "react-router-dom";
import { Status } from "redux/constant";
import { useDispatch, useSelector } from "redux/store/configureStore";
import ExcludeBG from "../../assets/icons/account-settings-icon/exclude.svg";

const ProfilePhotoUploadScreen = () => {
  const { user, updateUserProfilePhoto } = useAuth();
  const setActiveMenu = useNavigationHook();
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isLoadingModalOpen, setIsLoadingModalOpen] = useState(false);
  const { t } = useTranslation("translation", {
    keyPrefix: "profilePhotoUpload",
  });
  const [previewObj, setPreviewObj] = useState(null);
  const { profilePhoto, status: uploadProfilePhotoStatus } = useSelector(
    state => state.uploadProfilePhoto,
  );
  const navigate = useNavigate();
  const { state } = useLocation();
  const dispatch = useDispatch();

  const handleCancelClick = () => {
    navigate(-1);
  };

  const handleChooseClick = () => {
    const formData = new FormData();
    formData.append("file", state.photoFile);
    dispatch(uploadProfilePhoto({ userId: user.id, formData }));
  };

  const handleGotItClick = () => {
    setIsErrorModalOpen(false);
  };

  useEffect(() => {
    if (uploadProfilePhotoStatus === Status.ERROR) {
      setIsErrorModalOpen(true);
    }
    if (uploadProfilePhotoStatus === Status.LOADING) {
      setIsLoadingModalOpen(true);
    } else {
      setIsLoadingModalOpen(false);
    }
    if (uploadProfilePhotoStatus === Status.LOADED) {
      updateUserProfilePhoto({
        profilePicture: profilePhoto.profilePicture,
      });
      navigate(-1);
    }
  }, [uploadProfilePhotoStatus]);

  useEffect(() => {
    setActiveMenu(true);
    if (!state || !state.photoFile) {
      navigate("/settings/account-settings");
      return;
    }
    setPreviewObj(window.URL.createObjectURL(state.photoFile));
    return () => {
      if (state.photoFile) {
        window.URL.revokeObjectURL(state.photoFile);
      }
      dispatch(resetUploadProfilePhoto());
    };
  }, []);
  return (
    <>
      <Helmet>
        <title>{t("Upload Screen")}</title>
      </Helmet>
      <Container>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "130px 0 130px",
          }}
        >
          <Box
            data-testid="user-photo"
            component="img"
            src={ExcludeBG}
            sx={{
              height: 375,
              width: 375,
              background: `url(${previewObj})`,
              backgroundPosition: "center top",
              backgroundSize: "cover",
            }}
          />
        </Box>
        <Box sx={{ display: "flex", gap: 1 }}>
          <Button
            data-testid="btn_cancel-choose-profile"
            sx={{ borderRadius: "24px", width: "100%", height: "48px" }}
            fullWidth
            variant="outlined"
            onClick={handleCancelClick}
          >
            {t("CANCEL")}
          </Button>
          <Button
            data-testid="btn_choose-profile"
            sx={{ borderRadius: "24px", width: "100%", height: "48px" }}
            fullWidth
            variant="contained"
            onClick={handleChooseClick}
          >
            {t("CHOOSE")}
          </Button>
        </Box>
      </Container>
      <LoadingPrompt
        rawTitle={t("Processing")}
        isVisible={isLoadingModalOpen}
      />
      <Modal
        open={isErrorModalOpen}
        onClose={handleGotItClick}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          alignContent: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            bgcolor: "background.paper",
            maxWidth: "340px",
            m: "16px",
            gap: 2,
            borderRadius: "16px",
            p: "24px",
            overflowY: "auto",
            maxHeight: "100%",
          }}
        >
          <Typography variant="h6" sx={{ textAlign: "center" }}>
            {t("Cannot upload")}
          </Typography>
          <Typography sx={{ textAlign: "center" }}>
            {t("There is an error. Please try again later.")}
          </Typography>
          <Button
            sx={{ borderRadius: "24px", width: "100%", height: "48px" }}
            fullWidth
            variant="contained"
            onClick={handleGotItClick}
          >
            {t("GOT IT")}
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default ProfilePhotoUploadScreen;
