import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import PointExchangeAPI from "../../../../../apiConfig/PointExchangeAPI";
import { Status } from "../../../../../redux/constant";

const initialState = {
  linkedPartner: null,
  status: Status.INITIAL,
  errorMessage: null,
};

export const fetchAsyncLinkedPartner = createAsyncThunk(
  "linkedPartner/fetchAsyncLinkedPartner",
  async (_, { rejectWithValue }) => {
    try {
      const response = await PointExchangeAPI.get("/linked-accounts");
      return response.data;
    } catch (err) {
      if (err.response) {
        return rejectWithValue({
          status: err.response.status,
          data: err.response.data,
        });
      }
      return rejectWithValue({
        status: "",
        data: err.message,
      });
    }
  },
);

const LinkedPartnerSlice = createSlice({
  name: "linkedPartner",
  initialState,
  reducers: {
    resetLinkedPartner: state => {
      state.linkedPartner = null;
      state.status = Status.INITIAL;
      state.errorMessage = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchAsyncLinkedPartner.pending, state => {
        state.errorMessage = null;
        state.status = Status.LOADING;
      })
      .addCase(fetchAsyncLinkedPartner.fulfilled, (state, { payload }) => {
        state.linkedPartner = payload;
        state.status = Status.LOADED;
      })
      .addCase(fetchAsyncLinkedPartner.rejected, (state, { payload }) => {
        if (payload === undefined) {
          state.status = Status.ERROR;
          return;
        }
        state.errorMessage = payload.data;
        state.status = Status.ERROR;
      });
  },
});

export const { resetLinkedPartner } = LinkedPartnerSlice.actions;

export default LinkedPartnerSlice.reducer;
