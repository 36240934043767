import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import PointExchangeAPI from "../../../../../apiConfig/PointExchangeAPI";
import { Status } from "../../../../../redux/constant";

const initialState = {
  convertPartnerDetail: {},
  status: Status.INITIAL,
  errorMessage: null,
};

export const fetchAsyncConvertPartnerDetail = createAsyncThunk(
  "convertPartnerDetail/fetchAsyncConvertPartnerDetail",
  async ({ partnerId }, { rejectWithValue }) => {
    try {
      const response = await PointExchangeAPI.get(`/partners/${partnerId}`);
      return response.data;
    } catch (err) {
      if (err.response) {
        return rejectWithValue({
          status: err.response.status,
          data: err.response.data,
        });
      }
      return rejectWithValue({
        status: "",
        data: err.message,
      });
    }
  },
);

const ConvertPartnerDetailSlice = createSlice({
  name: "convertPartnerDetail",
  initialState,
  reducers: {
    resetConvertPartnerDetail: state => {
      state.convertPartnerDetail = [];
      state.status = Status.INITIAL;
      state.errorMessage = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchAsyncConvertPartnerDetail.pending, state => {
        state.errorMessage = null;
        state.status = Status.LOADING;
      })
      .addCase(
        fetchAsyncConvertPartnerDetail.fulfilled,
        (state, { payload }) => {
          state.convertPartnerDetail = payload;
          state.status = Status.LOADED;
        },
      )
      .addCase(
        fetchAsyncConvertPartnerDetail.rejected,
        (state, { payload }) => {
          if (payload === undefined) {
            state.status = Status.ERROR;
            return;
          }
          state.errorMessage = payload.data;
          state.status = Status.ERROR;
        },
      );
  },
});

export const { resetConvertPartnerDetail } = ConvertPartnerDetailSlice.actions;

export default ConvertPartnerDetailSlice.reducer;
