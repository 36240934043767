import { useState, useEffect, useMemo } from "react";
import { useMediaQuery } from "@mui/material";
import { MENU_HEIGHT } from "components/navigation/Menu";

export default function useMenuHeight() {
  const matches = useMediaQuery(theme => theme.breakpoints.up("sm"));
  const [menuHeight, setMenuHeight] = useState(0);

  const height = useMemo(() => {
    return matches ? 0 : MENU_HEIGHT;
  }, [matches]);

  useEffect(() => {
    setMenuHeight(height);
  }, [height]);

  return menuHeight;
}
