import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Box, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import useNavigationHook from "../../../../hooks/useNavigation";
import {
  useDispatch,
  useSelector,
} from "../../../../redux/store/configureStore";
import { Status } from "redux/constant";
import useTranslation from "hooks/useTranslation";
import ConversionRate from "features/wallet/components/convertCoins/conversionRate/ConversionRate";
import ConvertInputCard from "features/wallet/components/convertCoins/convertInputCard/ConvertInputCard";
import { getChannelConfig } from "config";
import Coins from "assets/icons/coins.svg";
import TmnWalletLogo from "assets/icons/company-logos/tmn-wallet-logo.svg";
import PaymentMethodCard from "features/wallet/components/convertCoins/paymentMethodCard/PaymentMethodCard";
import useWindowDimensions from "hooks/useWindowDimensions";
import useNavbarHeight from "hooks/useNavbarHeight";
import { coinFormatter, toDecimal } from "utils/coinFormatter";
import { trackVisitBuyCoinWithTMNPage } from "mixpanel/buyCoins";
import BuyCoinsInstruction from "features/wallet/components/convertCoins/buyCoinsInstruction/BuyCoinsInstruction";
import {
  fetchAsyncConvertF2CRates,
  resetConvertF2CRates,
} from "features/wallet/redux/slices/convertF2C/convertF2CRates";

const sourceId = "THB";
const targetId = getChannelConfig().abcCoinSymbol;
const provider = "tmn-otp";
const abcUnit = getChannelConfig().abcCoinSymbol;

const BuyCoins = () => {
  const { height } = useWindowDimensions();
  const navbarHeight = useNavbarHeight();
  const [footerHeight, setFooterHeight] = useState(0);
  const footerRef = useRef(null);
  useEffect(() => {
    setFooterHeight(footerRef.current.clientHeight);
  }, []);

  const setActiveMenu = useNavigationHook();
  const { t } = useTranslation("translation", {
    keyPrefix: "BuyCoins",
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { convertF2CRates, status: convertF2CRatesStatus } = useSelector(
    state => state.convertF2CRates,
  );

  const [values, setValues] = useState({
    balance: "",
  });
  const [error, setError] = useState({
    hasError: false,
    message: "",
  });

  useEffect(() => {
    setActiveMenu(true);
  }, [setActiveMenu]);

  useEffect(() => {
    trackVisitBuyCoinWithTMNPage();
  }, []);

  useEffect(() => {
    dispatch(
      fetchAsyncConvertF2CRates({
        source: sourceId,
        target: targetId,
        provider,
      }),
    );

    return () => {
      dispatch(resetConvertF2CRates());
    };
  }, [dispatch]);

  const isDisableButton = () => {
    return error.hasError || convertF2CRatesStatus !== Status.LOADED;
  };

  const getEnterAmountBetweenError = () => {
    return `${t("Please enter amount between")} ${coinFormatter(
      convertF2CRates.sourceMin.toString(),
    )} - ${coinFormatter(convertF2CRates.sourceMax.toString())}`;
  };

  const isInvalidEmptyInput = () => {
    if (!values.balance) {
      setError({
        hasError: true,
        message: getEnterAmountBetweenError(),
      });
      return true;
    }
    return false;
  };

  const isInvalidMinMax = () => {
    if (
      convertF2CRates?.sourceMin === null ||
      typeof convertF2CRates?.sourceMin === "undefined" ||
      convertF2CRates?.sourceMax === null ||
      typeof convertF2CRates?.sourceMax === "undefined"
    ) {
      return false;
    }
    if (
      convertF2CRates.sourceMin > parseInt(values.balance, 10) ||
      convertF2CRates.sourceMax < parseInt(values.balance, 10)
    ) {
      setError({
        hasError: true,
        message: getEnterAmountBetweenError(),
      });
      return true;
    }
    return false;
  };

  const isInvalidInput = () => {
    return isInvalidEmptyInput() || isInvalidMinMax();
  };

  const handleClick = () => {
    if (isInvalidInput()) return;
    navigate("/wallet/buy-coins/account", {
      state: {
        abcBalance: values.balance,
        payBalance: values.balance / parseFloat(convertF2CRates.value),
      },
    });
  };

  const renderFooter = () => {
    return (
      <Box
        ref={footerRef}
        sx={{
          position: "sticky",
          bottom: 0,
          backgroundColor: "background.paper",
          width: "100%",
          padding: "16px 16px 32px 16px",
        }}
      >
        <Box
          sx={{ mb: "16px", display: "flex", justifyContent: "space-between" }}
        >
          <Typography id="lbl_get" variant="subtitle">
            {t("You get")}
          </Typography>
          <Typography id="lbl_getBalance" variant="subtitle">{`${coinFormatter(
            values.balance,
          )} ${abcUnit}`}</Typography>
        </Box>
        <Box
          sx={{ mb: "16px", display: "flex", justifyContent: "space-between" }}
        >
          <Typography id="lbl_pay" variant="subtitle">
            {t("You pay")}
          </Typography>
          <Typography id="lbl_payBalance" variant="subtitle">
            {convertF2CRates?.value
              ? `${toDecimal(
                  values.balance / parseFloat(convertF2CRates?.value),
                )}`
              : "0"}
            {` ${t("Baht")}`}
          </Typography>
        </Box>
        <Button
          id="btn_continue"
          variant="contained"
          fullWidth
          sx={{ height: 48, borderRadius: "24px" }}
          disabled={isDisableButton()}
          onClick={handleClick}
        >
          <Typography variant="button">{t("Continue")}</Typography>
        </Button>
      </Box>
    );
  };

  return (
    <>
      <Helmet>
        <title>{t("Buy")}</title>
      </Helmet>
      <Box
        sx={{
          pt: "32px",
          pb: "32px",
          px: "16px",
          bgcolor: "grey.accent4",
          overflow: "auto",
          height: `calc(${height}px - ${navbarHeight}px - ${footerHeight}px)`,
        }}
        data-testid="buy-coins"
      >
        <Box>
          <Typography
            id="lbl_youBuyTitle"
            variant="subtitle1"
            sx={{ mb: "8px" }}
          >
            {t("You buy")}
          </Typography>
          <ConvertInputCard
            partnerNameStatus={Status.LOADED}
            partnerName={abcUnit}
            logo={Coins}
            min={convertF2CRates?.sourceMin}
            max={convertF2CRates?.sourceMax}
            values={values}
            setValues={setValues}
            error={error}
            setError={setError}
          />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              mt: "-8px",
              mb: "16px",
            }}
          >
            <ConversionRate
              status={convertF2CRatesStatus}
              rate={convertF2CRates?.value}
              rateFrom={abcUnit}
              rateTo={t("Baht")}
              hideConvertIcon={true}
            />
          </Box>
          <Box sx={{ mb: "16px" }}>
            <PaymentMethodCard
              title={t("Payment Method")}
              paymentName={t("TrueMoney Wallet")}
              logo={TmnWalletLogo}
            />
          </Box>
          <BuyCoinsInstruction />
        </Box>
      </Box>
      {renderFooter()}
    </>
  );
};

export default BuyCoins;
