import { Typography } from "@mui/material";
import { Box } from "@mui/system";

const ConvertRate = ({ convertText, rate, from, to }) => {
  return (
    <Box
      sx={{
        backgroundColor: "grey.accent4",
        p: "16px",
        borderRadius: "16px",
      }}
    >
      <Typography variant="body2">
        {`${convertText}: ${rate} ${from} = 1 ${to}`}
      </Typography>
    </Box>
  );
};

export default ConvertRate;
