import { Skeleton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import SwapIcon from "../../../../../assets/icons/swap.svg";
import useTranslation from "../../../../../hooks/useTranslation";
import { Status } from "../../../../../redux/constant";

const ConversionRate = ({
  status,
  rate,
  rateFrom,
  rateTo,
  hideConvertIcon = false,
}) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "convertCoins",
  });

  const renderContent = () => {
    if (status === Status.LOADING) {
      return (
        <Skeleton
          animation="wave"
          height={"30px"}
          width="100px"
          id="rates-loading"
          data-testid="rates-skeleton"
        />
      );
    } else if (status === Status.ERROR) {
      return t("Unable to retrieve conversion rate");
    } else if (status === Status.LOADED) {
      return `${t("Conversion rate")}: ${rate} ${rateFrom} = 1 ${rateTo}`;
    }
    return null;
  };

  return (
    <>
      <Box sx={{ display: "flex" }}>
        {!hideConvertIcon && (
          <Box
            alt="Coins"
            component="img"
            src={SwapIcon}
            sx={{
              objectFit: "cover",
              height: "24px",
              width: "24px",
              mr: "16px",
            }}
          />
        )}
        <Typography
          id="lbl_coversionRate_ConvertLoyaltyPointPage"
          color={"grey.accent2"}
        >
          {renderContent()}
        </Typography>
      </Box>
    </>
  );
};

export default ConversionRate;
