import React, { useEffect, useCallback, useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import useKeycloakSwitch from "channel/useKeycloakSwitch";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import useTranslation from "../../../../hooks/useTranslation";
import {
  useDispatch,
  useSelector,
} from "../../../../redux/store/configureStore";
import {
  fetchAsyncLinkedPartner,
  resetLinkedPartner,
} from "../../redux/slices/linkedPartner/linkedPartner";
import { Status } from "../../../../redux/constant";
import {
  channelName,
  trackChannelUsed,
  trackStartLinkingLoyaltyAccount,
} from "../../../../mixpanel";
import {
  EARTH_CHANNEL,
  FUSION_CHANNEL,
  getChannelConfig,
} from "../../../../config";
import { getAccessToken } from "utils/token";
import { getTrueIdAuthRedirectUrl } from "features/wallet/utils/trueid";
import queryString from "query-string";
import {
  fetchAsyncConvertPartnerRedirectAuth,
  resetConvertPartnerRedirectAuth,
} from "features/wallet/redux/slices/convertPartnerCoins/convertPartnerRedirectAuth";
import Prompt from "components/Prompt/Prompt";

const ConvertLandingPage = () => {
  const { t } = useTranslation("translation", {
    keyPrefix: "convertCoins",
  });
  const { keycloak } = useKeycloakSwitch();
  const { partnerId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { linkedPartner, status: linkedPartnerStatus } = useSelector(
    state => state.linkedPartner,
  );
  const {
    convertPartnerRedirectAuth,
    status: convertPartnerRedirectAuthStatus,
  } = useSelector(state => state.convertPartnerRedirectAuth);

  const [promptErrorOpen, setPromptErrorOpen] = useState(false);

  useEffect(() => {
    trackChannelUsed(channelName);
  }, []);

  useEffect(() => {
    return () => {
      dispatch(resetLinkedPartner());
      dispatch(resetConvertPartnerRedirectAuth());
    };
  }, [dispatch]);

  const mutiChannelFunc = useMemo(
    () => ({
      [EARTH_CHANNEL]: {
        token: keycloak.token,
        isAuthenticated: keycloak.authenticated,
        login: () => {
          const lang = localStorage.getItem("lang") || "th";
          keycloak.login({ locale: lang });
        },
      },
      [FUSION_CHANNEL]: {
        token: getAccessToken(),
        isAuthenticated: !!getAccessToken(),
        login: () => {
          window.location.replace("ascendmoney://closewebview");
        },
      },
    }),
    [keycloak],
  );

  const getQueryParam = useCallback(() => {
    return queryString.parse(location.search);
  }, [location.search]);

  useEffect(() => {
    if (mutiChannelFunc[getChannelConfig().channel].isAuthenticated) {
      dispatch(fetchAsyncLinkedPartner());
    } else {
      mutiChannelFunc[getChannelConfig().channel].login();
    }
  }, [partnerId, mutiChannelFunc, getQueryParam, dispatch, navigate]);

  useEffect(() => {
    if (linkedPartnerStatus === Status.LOADED) {
      const isLinked = linkedPartner?.find(
        item => item.providerName === partnerId,
      )?.connected;
      if (isLinked) {
        navigate(`/partner-convert/${partnerId}`, {
          state: {
            partnerId,
          },
          replace: true,
        });
        return;
      }
      trackStartLinkingLoyaltyAccount(partnerId);
      if (partnerId === "trueid") {
        const redirectUrl = getTrueIdAuthRedirectUrl();
        window.location.href = redirectUrl;
        return;
      }
      dispatch(fetchAsyncConvertPartnerRedirectAuth({ partnerId }));
    } else if (linkedPartnerStatus === Status.ERROR) {
      setPromptErrorOpen(true);
    }
  }, [dispatch, navigate, linkedPartnerStatus, linkedPartner, partnerId]);

  useEffect(() => {
    if (convertPartnerRedirectAuthStatus === Status.LOADED) {
      let url = convertPartnerRedirectAuth?.url;
      if (!url) {
        setPromptErrorOpen(true);
        return;
      }
      window.location.href = url;
    } else if (convertPartnerRedirectAuthStatus === Status.ERROR) {
      setPromptErrorOpen(true);
    }
  }, [convertPartnerRedirectAuthStatus, convertPartnerRedirectAuth]);

  return (
    <>
      <Helmet>
        <title>{t("Convert")}</title>
      </Helmet>
      <Prompt
        isVisible={promptErrorOpen}
        icon={"error"}
        onClose={() => navigate("/wallet", { replace: true })}
        title={"Oops, there was an issue"}
        subTitle={"Please try again"}
        buttonText={"OK"}
      />
    </>
  );
};

export default ConvertLandingPage;
