import React from "react";
import { Outlet } from "react-router-dom";
import { styled } from "@mui/system";

const DashboardLayoutRoot = styled("div")(({ theme }) => ({
  backgroundColor: "transparent",
  display: "flex",
  flexDirection: "row",
  height: "100%",
  overflow: "hidden",
  width: "100%",
}));

const HeaderContentLayoutWrapper = styled("div")(() => ({
  width: "100%",
  flexDirection: "column",
  display: "flex",
}));

const DashboardLayoutContent = styled("div")({
  flex: "1 1 auto",
  overflow: "auto",
  height: "100%",
  position: "relative",
  WebkitOverflowScrolling: "touch",
});

const UserLayout = () => {
  return (
    <DashboardLayoutRoot id="dashboard-layout-root">
      <HeaderContentLayoutWrapper id="header-content-layout">
        <DashboardLayoutContent id="dashboard-layout-content">
          <Outlet />
        </DashboardLayoutContent>
      </HeaderContentLayoutWrapper>
    </DashboardLayoutRoot>
  );
};

export default UserLayout;
