import React from "react";
import { Box } from "@mui/material";
import VideoIcon from "../../../../assets/icons/video.svg";
import GenericErrorImg from "../../../../assets/images/GenericErrorImg.svg";

const NftThumbnail = ({ token, collectionAddress }) => {
  return (
    <Box sx={{ position: "relative" }}>
      <Box
        id={`img-nft-thumbnail-${token?.tokenID}-${collectionAddress}`}
        alt="NFT image"
        component="img"
        src={token?.metadata?.image}
        sx={{
          width: "100%",
          border: "1px solid",
          borderColor: "grey.accent3",
          borderRadius: "16px",
          aspectRatio: "1",
          objectFit: "cover",
        }}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null;
          currentTarget.src = GenericErrorImg;
        }}
      />
      {token?.metadata?.animation_url && (
        <Box
          id={`icon-video-${token?.tokenID}-${collectionAddress}`}
          alt="video icon"
          component="img"
          src={VideoIcon}
          sx={{
            position: "absolute",
            right: "11px",
            top: "11px",
            height: "18px",
            width: "18px",
            boxShadow: 1,
            borderRadius: "50%",
          }}
        />
      )}
    </Box>
  );
};

export default NftThumbnail;
