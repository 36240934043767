import { useWeb3React } from "@web3-react/core";
import useERC721HandlerAction from "smartcontract/hooks/useERC721HandlerAction";

function useNftBridge({ collectionAddress }) {
  const { userWithdraw } = useERC721HandlerAction({
    contract: collectionAddress,
  });
  const { account } = useWeb3React();

  const withdrawNftBridge = async (tokenAddress, tokenId) => {
    const result = await userWithdraw(tokenAddress, account, tokenId);
    return result;
  };

  return { withdrawNftBridge };
}

export default useNftBridge;
