import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import MainAPI from "apiConfig/MainAPI";
import { serviceEndpoint } from "config";
import { Status } from "../../../../../redux/constant";

const initialState = {
  convertF2CRates: {},
  status: Status.INITIAL,
  errorMessage: null,
};

export const fetchAsyncConvertF2CRates = createAsyncThunk(
  "convertF2CRates/fetchAsyncConvertF2CRates",
  async ({ source, target, provider }, { rejectWithValue }) => {
    try {
      const response = await MainAPI.get(
        `${serviceEndpoint.f2c}/rates/${source}/${target}/${provider}`,
      );
      return response.data;
    } catch (err) {
      if (err.response) {
        return rejectWithValue({
          status: err.response.status,
          data: err.response.data,
        });
      }
      return rejectWithValue({
        status: "",
        data: err.message,
      });
    }
  },
);

const ConvertF2CRatesSlice = createSlice({
  name: "convertF2CRates",
  initialState,
  reducers: {
    resetConvertF2CRates: state => {
      state.convertF2CRates = {};
      state.status = Status.INITIAL;
      state.errorMessage = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchAsyncConvertF2CRates.pending, state => {
        state.errorMessage = null;
        state.status = Status.LOADING;
      })
      .addCase(fetchAsyncConvertF2CRates.fulfilled, (state, { payload }) => {
        state.convertF2CRates = payload;
        state.status = Status.LOADED;
      })
      .addCase(fetchAsyncConvertF2CRates.rejected, (state, { payload }) => {
        if (payload === undefined) {
          state.status = Status.ERROR;
          return;
        }
        state.errorMessage = payload.data;
        state.status = Status.ERROR;
      });
  },
});

export const { resetConvertF2CRates } = ConvertF2CRatesSlice.actions;

export default ConvertF2CRatesSlice.reducer;
