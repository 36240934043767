const phoneNumberFormatter = number => {
  if (number) {
    const cleanedNumber = number.toString();
    return (
      cleanedNumber.slice(0, cleanedNumber.length - 7) +
      "-" +
      cleanedNumber.slice(cleanedNumber.length - 7, cleanedNumber.length - 4) +
      "-" +
      cleanedNumber.slice(cleanedNumber.length - 4)
    );
  }
  return number;
};

export default phoneNumberFormatter;

export const phoneNumberFormatter2 = phoneString => {
  if (phoneString.length >= 7) {
    return (
      phoneString.slice(0, 3) +
      "-" +
      phoneString.slice(3, 6) +
      "-" +
      phoneString.slice(6, 12)
    );
  }
  if (phoneString.length >= 4) {
    return phoneString.slice(0, 3) + "-" + phoneString.slice(3, 6);
  }
  return phoneString;
};

export const phoneNumberSpaceSeparatorFormatter = phoneString => {
  const regex = /^\+[0-9]{11}$/g
  const correctRegex = /(^\+\d{3})\s(\d{4})\s\d{4}$/g
  if(phoneString && phoneString.match(correctRegex)) {
    return phoneString
  }else if(phoneString && (phoneString.match(regex))) {
    const first = phoneString.slice(0,4)
    const middle = phoneString.slice(4,8)
    const last = phoneString.slice(8)
    return first + " " + middle + " " + last
  }
  return phoneString
}

export const phoneNumberURIEncoded = mobileNumber => {
  if (!mobileNumber) return;
  return encodeURIComponent(mobileNumber);
};

export const phoneNumberWithCountryCode = (mobileNumber, countryCode = "") => {
  if (!mobileNumber) return;
  const plainNumber = mobileNumber.replace(/-/g, "");
  if (plainNumber.includes("+")) {
    return plainNumber;
  }
  return countryCode + plainNumber.slice(1);
};

export const phoneNumberWithThaiCountryCode = mobileNumber =>
  phoneNumberWithCountryCode(mobileNumber, "+66");

export const phoneNumberURIEncodedWithThaiCountryCode = mobileNumber => {
  const phoneNumber = phoneNumberWithCountryCode(mobileNumber, "+66");
  return phoneNumberURIEncoded(phoneNumber);
};
