import React from "react";
import useTranslation from "../../../../hooks/useTranslation";
import { Box } from "@mui/material";
import CardComponent from "../card/CardComponent";
import DealSection from "../DealSection";
import {
  useDispatch,
  useSelector,
} from "../../../../redux/store/configureStore";
import {
  fetchAsyncDeals,
  status as DealsStatus,
} from "../../redux/slices/deals/Deals";
import DealsSkeleton from "../../skeletonLoading/DealsSkeleton";
import EmptyDeals from "../emptyDeals/EmptyDeals";
import GenericError from "../../../../pages/genericError/GenericError";
import _ from "lodash";

const DEAL_TYPE = {
  Food: "Food",
  Entertainment: "Entertainment",
  Shopping: "Shopping",
  Other: "Other",
};

const AllDeals = () => {
  const { t } = useTranslation("translation", { keyPrefix: "marketplace" });
  const dispatch = useDispatch();
  const { page, deals, status } = useSelector(state => state.deals);
  const limit = 1000;

  React.useEffect(() => {
    dispatch(fetchAsyncDeals({ page, limit }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderDealSection = (dealType, deals) => {
    return (
      <DealSection key={`deal-type-${dealType}`} dealType={dealType}>
        {deals.map(deal => (
          <CardComponent deal={deal} key={deal.id} />
        ))}
      </DealSection>
    );
  };

  const renderList = _.chain(deals)
    .map(value => {
      // Make sure every deal has classification(aka. category)
      if (!value.category) {
        return {
          ...value,
          category: { text: t(DEAL_TYPE.Other), sequence: 4 },
        };
      }
      return value;
    })
    .groupBy("category.text")
    .reduce((result, value, key) => {
      // this will loop throught object(aka. category)
      result.push({
        category: key,
        sequence: value[0].category.sequence,
        dealList: value,
      });
      return result;
    }, [])
    .orderBy(["sequence"], ["asc"])
    .value();
  return (
    <>
      {status === DealsStatus.LOADING ? (
        <DealsSkeleton id="deals-loading" />
      ) : status === DealsStatus.ERROR ? (
        <Box
          sx={{
            height: "100%",
            display: "flex",
            flexGrow: 1,
            alignContent: "center",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <GenericError
            id="deals-error"
            title="Service is not available"
            body="Service is not available at the moment. You may be able to try again."
          />
        </Box>
      ) : status === DealsStatus.EMPTY ? (
        <Box sx={{ m: "32px" }}>
          <EmptyDeals
            id="deals-empty"
            title="No Available Voucher"
            subtitle="Please check back soon for exciting new offers"
          />
        </Box>
      ) : deals && status !== DealsStatus.INITIAL ? (
        <Box id="deals-scroll">
          {renderList?.map((value, index) =>
            renderDealSection(value.category, value.dealList),
          )}
        </Box>
      ) : null}
    </>
  );
};

export default AllDeals;
