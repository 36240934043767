import React, { useEffect, useState, useMemo, useRef } from "react";
import { Helmet } from "react-helmet-async";
import {
  Box,
  Button,
  CardActionArea,
  Snackbar,
  SnackbarContent,
  Typography,
  Slide,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import useNavigationHook from "../../../../hooks/useNavigation";
import useTranslation from "hooks/useTranslation";
import {
  useDispatch,
  useSelector,
} from "../../../../redux/store/configureStore";
import {
  fetchAsyncPartnerList,
  resetPartnerList,
} from "features/wallet/redux/slices/partnerList/partnerList";
import {
  fetchAsyncLinkedPartner,
  resetLinkedPartner,
} from "features/wallet/redux/slices/linkedPartner/linkedPartner";
import { Status } from "redux/constant";
import { useNavigate } from "react-router-dom";
import ManageLinkedRewardsCard from "features/wallet/components/manageLinkedRewards/manageLinkedRewardsCard/ManageLinkedRewardsCard";
import usePartnerBalance from "features/wallet/hooks/usePartnerBalance";
import { getValueByLanguage } from "utils/getLanguage";
import usePartnerRates from "features/wallet/hooks/usePartnerRates";
import {
  trackVisitLinkedRewardsSettings,
  trackConfirmPartnerUnlink,
} from "mixpanel";
import UnlinkRewards from "features/wallet/components/manageLinkedRewards/unlinkRewards/UnlinkRewards";
import { fetchAsyncUnlinkPartner } from "features/wallet/redux/slices/unlinkPartner/unlinkPartner";
import { getChannelConfig } from "config";

const targetId = getChannelConfig().abcCoinSymbol;

const ManageLinkedRewards = () => {
  const { t } = useTranslation("translation", {
    keyPrefix: "ManageLinkedRewards",
  });
  const setActiveMenu = useNavigationHook();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { partnerList, status: partnerListStatus } = useSelector(
    state => state.partnerList,
  );
  const { linkedPartner, status: linkedPartnerStatus } = useSelector(
    state => state.linkedPartner,
  );
  const { status: unlinkPartnerStatus } = useSelector(
    state => state.unlinkPartner,
  );

  const [isEdit, setIsEdit] = useState(false);
  const [selectedUnlinkPartnerId, setSelectedUnlinkPartnerId] = useState();
  const [showUnlinkModal, setShowUnlinkModal] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const partnersBalance = usePartnerBalance();
  const partnersRates = usePartnerRates({ targetId });

  const selectedUnlinkReasonRef = useRef("");
  const selectedUnlinkPartnerIdRef = useRef("");

  useEffect(() => {
    setActiveMenu(true);
  }, [setActiveMenu]);

  useEffect(() => {
    trackVisitLinkedRewardsSettings();
  }, []);

  useEffect(() => {
    if (partnerListStatus === Status.INITIAL) {
      dispatch(fetchAsyncPartnerList());
    }
  }, [dispatch, partnerListStatus]);

  useEffect(() => {
    if (linkedPartnerStatus === Status.INITIAL) {
      dispatch(fetchAsyncLinkedPartner());
    }
  }, [dispatch, linkedPartnerStatus]);

  useEffect(() => {
    return () => {
      dispatch(resetPartnerList());
      dispatch(resetLinkedPartner());
    };
  }, [dispatch]);

  useEffect(() => {
    if (unlinkPartnerStatus === Status.LOADED) {
      dispatch(fetchAsyncLinkedPartner());
      trackConfirmPartnerUnlink({
        partnerName: selectedUnlinkPartnerIdRef.current,
        reason: selectedUnlinkReasonRef.current,
      });
      setOpenSnackbar(true);
      setShowUnlinkModal(false);
    }
  }, [dispatch, unlinkPartnerStatus]);

  const filteredLinkedPartner = useMemo(
    () =>
      linkedPartner?.filter(
        linkedItem =>
          linkedItem.connected &&
          !linkedItem.social &&
          linkedItem.providerName !== "trueid",
      ),
    [linkedPartner],
  );

  const getPartnerDetail = partnerId => {
    return partnerList?.items?.find(item => item.id === partnerId);
  };

  const handleClickManage = () => {
    setIsEdit(s => !s);
  };

  const handleClickAddPartner = () => {
    navigate("/wallet/partner-convert-in");
  };

  const handleClickCard = partnerId => {
    navigate(`/convert/${partnerId}`);
  };

  const handleClickUnlinkCard = partnerId => {
    setSelectedUnlinkPartnerId(partnerId);
    setShowUnlinkModal(true);
  };

  const handleClickCancelUnlinkModal = () => setShowUnlinkModal(false);

  const handleClickUnlinkModal = selectedReason => {
    selectedUnlinkReasonRef.current = selectedReason;
    selectedUnlinkPartnerIdRef.current = selectedUnlinkPartnerId;
    dispatch(fetchAsyncUnlinkPartner({ partnerId: selectedUnlinkPartnerId }));
  };

  const renderRewardCard = () => {
    if (
      partnerListStatus !== Status.LOADED ||
      linkedPartnerStatus !== Status.LOADED
    ) {
      return (
        <>
          {[...Array(3)].map((_, index) => (
            <Box key={index} sx={{ mt: index === 0 ? "0px" : "16px" }}>
              <ManageLinkedRewardsCard isLoading />
            </Box>
          ))}
        </>
      );
    }

    return (
      <>
        {filteredLinkedPartner?.map((item, index) => (
          <Box
            key={item.providerName}
            sx={{ mt: index === 0 ? "0px" : "16px" }}
          >
            <ManageLinkedRewardsCard
              partnerId={item.providerName}
              partnerName={getValueByLanguage(
                getPartnerDetail(item.providerName)?.name,
              )}
              sourceBalance={partnersBalance[item.providerName]}
              sourceUnit={getValueByLanguage(
                getPartnerDetail(item.providerName)?.unitName,
              )}
              targetUnit={targetId}
              rate={partnersRates[item.providerName]?.value}
              isEdit={isEdit}
              isLoadingBalance={!partnersBalance[item.providerName]}
              isLoadingRates={!partnersRates[item.providerName]}
              onClickCard={partnerId => handleClickCard(partnerId)}
              onClickUnlink={partnerId => handleClickUnlinkCard(partnerId)}
            />
          </Box>
        ))}
      </>
    );
  };

  const renderAddPartnerButton = () => {
    return (
      <>
        <Box
          sx={{
            border: "1px solid",
            borderColor: "grey.accent3",
            borderRadius: "16px",
          }}
        >
          <CardActionArea
            id="btn_add-partner"
            sx={{ borderRadius: "16px" }}
            onClick={handleClickAddPartner}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                p: "16px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  bgcolor: "grey.accent4",
                  borderRadius: "50%",
                  width: "40px",
                  height: "40px",
                }}
              >
                <AddIcon />
              </Box>
              <Box sx={{ ml: "8px" }}>
                <Typography variant="subtitle1">
                  {t("Add rewards account")}
                </Typography>
              </Box>
            </Box>
          </CardActionArea>
        </Box>
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>{t("Your Rewards")}</title>
      </Helmet>
      <Box sx={{ mt: "32px", px: "16px" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mb: "24px",
          }}
          data-testid="manage-linked-rewards"
        >
          <Typography id="lbl_title_linked" variant="h6">
            {t("Linked")}
          </Typography>
          <Button
            id="btn_manage-linked-rewards"
            variant="text"
            onClick={handleClickManage}
          >
            <Typography
              id="lbl_manage-linked-rewards"
              variant="body1"
              sx={{ color: "primary.main" }}
            >
              {!isEdit ? t("Manage") : t("Cancel")}
            </Typography>
          </Button>
        </Box>
        <Box sx={{ mb: "24px" }}>{renderRewardCard()}</Box>
        <Box sx={{ mb: "24px" }}>{renderAddPartnerButton()}</Box>
        <UnlinkRewards
          partnerId={selectedUnlinkPartnerId}
          isVisible={showUnlinkModal}
          onClickCancel={handleClickCancelUnlinkModal}
          onClickUnlink={handleClickUnlinkModal}
        />
      </Box>
      <Snackbar
        id="unlinked-success-snackbar"
        data-testid="unlinked-success-snackbar"
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        TransitionComponent={Slide}
        key={Slide.name}
        autoHideDuration={3000}
      >
        <SnackbarContent
          message={t("Unlinked account successfully")}
          sx={{ bgcolor: "toast.main", borderRadius: "16px" }}
        />
      </Snackbar>
    </>
  );
};

export default ManageLinkedRewards;
