import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import consentApi from "../../../apiConfig/consentAPI";

export const termsState = {
  INITIAL: "INITIAL",
  LOADING: "LOADING",
  LOADED: "LOADED",
  ERROR: "ERROR",
};

const initialState = {
  state: termsState.INITIAL,
  isLoading: false,
  terms: null,
  status: null,
  errorMessage: null,
};

//Thunks
export const fetchAsyncTerms = createAsyncThunk(
  "terms/fetchAsyncTerms",
  async (channel, { rejectWithValue }) => {
    try {
      let language = localStorage.getItem("lang");
      language === "en-US" && (language = "en");
      const response = await consentApi.get(`/getLatestTnc`, {
        params: {
          language,
          service: channel,
        },
      });
      return {
        status: response.status,
        data: response.data,
      };
    } catch (err) {
      if (err.response) {
        return rejectWithValue({
          status: err.response.status,
          data: err.response.data,
        });
      }
      return rejectWithValue({
        status: "",
        data: err.message,
      });
    }
  },
);

//Slices
const TermsSlice = createSlice({
  name: "terms",
  initialState,
  reducers: {},

  extraReducers: builder => {
    builder
      .addCase(fetchAsyncTerms.pending, state => {
        state.state = termsState.LOADING;
        state.isLoading = true;
        state.terms = null;
        state.status = null;
        state.errorMessage = null;
      })
      .addCase(fetchAsyncTerms.fulfilled, (state, { payload }) => {
        state.state = termsState.LOADED;
        state.isLoading = false;
        state.terms = payload.data;
        state.status = payload.status;
        state.errorMessage = null;
      })
      .addCase(fetchAsyncTerms.rejected, (state, { payload }) => {
        state.state = termsState.ERROR;
        state.isLoading = false;
        state.terms = null;
        state.status = payload.status;
        state.errorMessage = payload.data;
      });
  },
});
export const { termsReducers } = TermsSlice;

export default TermsSlice.reducer;
