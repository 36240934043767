import React from "react";
import { Box, ListItemButton, Typography, Avatar } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { formatDateTime } from "utils/dateTimeFormatter";
import { getFeatureFlags } from "../../../../config";
import useTranslation from "../../../../hooks/useTranslation";

const VoucherCard = ({ data }) => {
  const { reference, deal } = data;
  const navigate = useNavigate();
  const { enableVoucherValidPeriod } = getFeatureFlags();
  const { t } = useTranslation("translation", { keyPrefix: "voucherDetail" });
  return (
    <ListItemButton
      data-testid="voucherCard"
      id="voucherCard"
      TouchRippleProps={{ sx: { color: "primary.main" } }}
      sx={{
        borderRadius: "16px",
        border: "1px solid",
        borderColor: "grey.accent3",
        backgroundColor: "background.paper",
        mb: "16px",
        mx: "2px",
        p: "8px",
        justifyContent: "flex-start",
      }}
      onClick={() => {
        navigate(`/wallet/voucher/${reference}`, {
          state: {
            ...data,
          },
        });
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
      >
        <Box
          component="img"
          src={deal?.thumbnail}
          sx={{
            objectFit: "cover",
            width: "92px",
            height: "auto",
            aspectRatio: "1/1",
            borderRadius: "16px",
          }}
          id="coverImage"
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            ml: "8px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              mb: "6px",
            }}
          >
            <Box
              sx={{
                mr: "4px",
              }}
            >
              {deal?.merchant?.logo ? (
                <Avatar
                  sx={{
                    height: "24px",
                    width: "24px",
                  }}
                  src={deal.merchant.logo}
                />
              ) : (
                <Avatar
                  sx={{
                    height: "24px",
                    width: "24px",
                  }}
                >
                  <Typography sx={{ fontSize: "11px" }}>{"ABC"}</Typography>
                </Avatar>
              )}
            </Box>
            <Typography
              variant="body2"
              sx={{
                textAlign: "left",
                color: "grey.accent1",
                display: "-webkit-box",
                overflow: "hidden",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 1,
              }}
            >
              {deal?.merchant?.name}
            </Typography>
          </Box>
          <Typography
            variant="subtitle"
            sx={{
              textAlign: "left",
              color: "grey.accent1",
              display: "-webkit-box",
              overflow: "hidden",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 2,
            }}
          >
            {deal?.title}
          </Typography>
          {enableVoucherValidPeriod && (
            <>
              <Typography
                data-testid="voucher-period-title"
                variant="body2"
                fontWeight={600}
              >
                {t("Valid Period")}:
              </Typography>
              <Typography
                data-testid="voucher-period-value"
                variant="body2"
                fontWeight={600}
              >{`${formatDateTime(
                deal?.startDateTime,
                "D MMM YYYY",
              )} - ${formatDateTime(deal?.endDateTime, "D MMM YYYY")}`}</Typography>
            </>
          )}
        </Box>
      </Box>
    </ListItemButton>
  );
};

export default VoucherCard;
