import React from "react";
import { List, Box } from "@mui/material";
import InfiniteScroll from "react-infinite-scroll-component";
import VoucherCard from "../voucherCard/VoucherCard";
import useVouchers from "../../hooks/useVouchers";
import ProgressLoad from "../../../../components/progressLoad/ProgressLoad";
import { status as vouchersStatus } from "../../redux/slices/vouchers/vouchers";
import VouchersSkeleton from "../../skeletonLoading/VouchersSkeleton";
import VouchersError from "./VouchersError";
import VouchersEmpty from "./VouchersEmpty";
import VoucherRedeemed from "../voucherCard/VoucherRedeemed";
import dayjs from "dayjs";

const TransactionHistoryList = () => {
  const { vouchers, status, hasMore, handleNextPage } = useVouchers();

  const sortedVouchers = () => {
    if(vouchers && vouchers.length > 0) {
      return [...vouchers].sort((pre, curr) => {
        if(curr.isRedeemed) return pre.isRedeemed - curr.isRedeemed;
        return dayjs(pre.validUntil).unix() - dayjs(curr.validUntil).unix();
      })
    }

    return [];
  };

  const voucherList = () => {
    return sortedVouchers().map((item) => {
      if (item.isRedeemed) {
        return (
          <VoucherRedeemed key={item.code}>
            <VoucherCard data={item} />
          </VoucherRedeemed>
        );
      } else {
        return <VoucherCard key={item.code} data={item} />;
      }
    });
  };

  const renderVoucherList = () => {
    if (status === vouchersStatus.LOADING && vouchers.length <= 0)
      return <VouchersSkeleton />;
    if (status === vouchersStatus.ERROR) return <VouchersError />;
    if (status === vouchersStatus.EMPTY) return <VouchersEmpty />;

    return (
      <InfiniteScroll
        dataLength={vouchers?.length || 0}
        next={handleNextPage}
        hasMore={hasMore}
        loader={<ProgressLoad />}
      >
        <List>{voucherList()}</List>
      </InfiniteScroll>
    );
  };

  return <Box sx={{ px: "16px" }}>{renderVoucherList()}</Box>;
};

export default TransactionHistoryList;
