import {
  Box,
  Avatar,
  FormControl,
  FormHelperText,
  Typography,
  OutlinedInput,
} from "@mui/material";
import { styled } from "@mui/system";
import useTranslation from "hooks/useTranslation";
import BuyCoinsInstruction from "../buyCoinsInstruction/BuyCoinsInstruction";

const MobileNumberOutlinedInput = styled(OutlinedInput)(({ theme }) => ({
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: theme.palette.grey.accent3,
  },
  "&.Mui-error .MuiOutlinedInput-notchedOutline": {
    borderColor: theme.palette.error.main,
  },
}));

const regEx = /^\d*$/;

const EnterAccountCard = ({ logo, useMobileNumber }) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "BuyCoins",
  });

  const handleChangeMobileNumber = e => {
    const { value } = e.target;
    const justPhoneString = value.replace(/-/g, "");
    if (!justPhoneString) {
      useMobileNumber.setMobileNumber({ number: "", errorMessage: "" });
      return;
    }
    if (!regEx.test(justPhoneString)) {
      return;
    }
    useMobileNumber.setMobileNumber({
      number: justPhoneString,
      errorMessage: "",
    });
  };

  const onEnter = e => {
    if (e.key === "Enter") {
      e.target.blur();
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "background.paper",
        border: 1,
        borderColor: "grey.accent3",
        borderRadius: "16px",
        p: "24px",
      }}
      data-testid="enter-account-card"
    >
      <Box sx={{ mb: "8px" }}>
        <Avatar
          id="img_logo-enterAccountCard"
          sx={{
            height: "56px",
            width: "56px",
          }}
          src={logo}
        />
      </Box>
      <Box sx={{ mb: "16px" }}>
        <Typography id="lbl_enterAccount" variant="h6">
          {t("Enter your TrueMoney account")}
        </Typography>
      </Box>
      <Box sx={{ mb: "16px", width: "100%" }}>
        <FormControl
          fullWidth
          error={!!useMobileNumber.mobileNumber.errorMessage}
        >
          <MobileNumberOutlinedInput
            value={useMobileNumber.mobileNumber.number}
            onChange={handleChangeMobileNumber}
            placeholder={t("Input phone number (10 digit)")}
            sx={{
              borderRadius: "16px",
            }}
            inputProps={{
              inputMode: "numeric",
            }}
            onKeyUp={onEnter}
          />
          {!!useMobileNumber.mobileNumber.errorMessage && (
            <FormHelperText sx={{ m: 0, mt: "8px" }}>
              {useMobileNumber.mobileNumber.errorMessage}
            </FormHelperText>
          )}
        </FormControl>
      </Box>
      <Box sx={{ width: "100%" }}>
        <BuyCoinsInstruction />
      </Box>
    </Box>
  );
};

export default EnterAccountCard;
