import { Avatar, Typography } from "@mui/material";
import { Box } from "@mui/system";

const PaymentMethodCard = ({ title, paymentName, logo }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        backgroundColor: "background.paper",
        border: 1,
        borderColor: "grey.accent3",
        borderRadius: "16px",
        p: "16px",
      }}
      data-testid="payment-method-card"
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minWidth: "90px",
        }}
      >
        <Box sx={{ mb: "16px" }}>
          <Typography variant="subtitle1" id="lbl_title-paymentMethodCard">
            {title}
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Avatar
              id="img_logo-paymentMethodCard"
              sx={{
                height: "40px",
                width: "40px",
              }}
              src={logo}
            />
          </Box>
          <Box sx={{ ml: "8px" }}>
            <Typography
              id="lbl_paymentName-paymentMethodCard"
              variant="subtitle1"
            >
              {paymentName}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PaymentMethodCard;
