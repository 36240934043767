import { useNftContract } from "./useContract";
import useTransaction from "./useTransaction";

const useERC721HandlerAction = ({ contract }) => {
  const { sendTransaction } = useTransaction();
  const nftContract = useNftContract(contract);

  const userWithdraw = async (tokenAddress, recipient, tokenId) => {
    return await sendTransaction(nftContract, "userWithdraw", [
      tokenAddress,
      recipient,
      tokenId,
    ]);
  };

  return {
    userWithdraw,
  };
};

export default useERC721HandlerAction;
