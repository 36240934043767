import React, { useRef, useEffect, useState } from "react";
import { Box, Typography, IconButton } from "@mui/material";
import Circle from "@mui/icons-material/FiberManualRecordRounded";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import useTranslation from "hooks/useTranslation";
import useNavbarHeight from "hooks/useNavbarHeight";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { paletteLight } from "theme";
import BridgeOnboarding1 from "assets/images/Bridge/Bridge-onboarding 1.png";
import BridgeOnboarding2 from "assets/images/Bridge/Bridge-onboarding 2.png";
import BridgeOnboarding3 from "assets/images/Bridge/Bridge-onboarding 3.png";
import BridgeOnboarding4 from "assets/images/Bridge/Bridge-onboarding 4.png";

const CarouselOnboarding = ({ footerHeight }) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "bridgeNft",
  });
  const navbarHeight = useNavbarHeight();

  const steps = [
    {
      id: "onboarding-step-1",
      title: t("What’s Bridge?"),
      desc: t("What’s Bridge? description"),
      thumbnail: BridgeOnboarding1,
    },
    {
      id: "onboarding-step-2",
      title: t("Where you can start journey?"),
      desc: t("Where you can start journey? description"),
      thumbnail: BridgeOnboarding2,
    },
    {
      id: "onboarding-step-3",
      title: t("What do you need to have?"),
      desc: t("What do you need to have? description"),
      thumbnail: BridgeOnboarding3,
    },
    {
      id: "onboarding-step-4",
      title: t("What happened after Bridge?"),
      desc: t("What happened after Bridge? description"),
      thumbnail: BridgeOnboarding4,
    },
  ];

  const renderArrow = direction => (onClickHandler, shouldBeEnabled, label) => {
    if (!shouldBeEnabled) {
      return;
    }

    const styles = {
      position: "absolute",
      zIndex: 2,
      bottom: `calc(100vh - ${navbarHeight}px - 310px)`,
      top: "unset",
      backgroundColor: "transparent",
      color: "#000000",
    };

    if (direction === "prev") {
      styles.left = 10;
    } else {
      styles.right = 10;
    }

    return (
      <IconButton onClick={onClickHandler} style={styles}>
        {direction === "prev" ? <NavigateBeforeIcon /> : <NavigateNextIcon />}
      </IconButton>
    );
  };

  const renderIndicator = (onClickHandler, isSelected, index, label) => {
    const defaultStyle = {
      padding: 0,
      fontSize: "15px",
    };

    const style = isSelected
      ? { ...defaultStyle, color: paletteLight.primary.accent2 }
      : { ...defaultStyle, color: paletteLight.grey.accent3 };

    return (
      <IconButton
        onClick={onClickHandler}
        onKeyDown={onClickHandler}
        style={style}
        key={index}
        tabIndex={0}
        aria-label={`${label} ${index + 1}`}
      >
        <Circle fontSize="inherit" />
      </IconButton>
    );
  };

  return (
    <Carousel
      showStatus={false}
      showArrows={true}
      swipeable={true}
      emulateTouch={true}
      stopOnHover={true}
      renderArrowPrev={renderArrow("prev")}
      renderArrowNext={renderArrow("next")}
      transitionTime={500}
      preventMovementUntilSwipeScrollTolerance={true}
      swipeScrollTolerance={50}
      renderIndicator={renderIndicator}
      showThumbs={false}
    >
      {steps.map((item, index) => (
        <Box
          key={item.id}
          display="flex"
          justifyContent="center"
          height={`calc(100vh - ${navbarHeight}px - ${footerHeight}px)`}
          sx={{
            width: "100%",
          }}
        >
          <Box
            sx={{
              width: { xs: "100%", sm: "360px" },
              overflowY: "auto",
              maxHeight: `calc(100vh - ${navbarHeight}px - ${footerHeight}px - 44px)`,
            }}
          >
            <Box
              component="img"
              alt={`img_thumbnail-${item.id}`}
              data-testid={`img_thumbnail-${item.id}`}
              src={item.thumbnail}
              sx={{
                width: "100%",
                objectFit: "cover",
                height: "280px",
                verticalAlign: "baseline !important",
              }}
            />
            <Box
              p="16px"
              display="flex"
              flexDirection="column"
              textAlign="start"
              sx={{ gap: "8px" }}
            >
              <Typography data-testid={`text_title-${item.id}`} variant="h6">
                {item.title}
              </Typography>
              <Typography
                data-testid={`text_desc-${item.id}`}
                variant="body1"
                color="grey.accent2"
                sx={{ whiteSpace: "pre-line" }}
              >
                {item.desc}
              </Typography>
            </Box>
          </Box>
        </Box>
      ))}
    </Carousel>
  );
};

export default CarouselOnboarding;
