import React from "react";
import { Box, Typography } from "@mui/material";

const NftAction = ({ id, title, handleAction, icon, disabled = false }) => {
  return (
    <Box
      pl="12px"
      pr="12px"
      display="flex"
      flexDirection="column"
      alignItems="center"
      gap="8px"
      p="0"
      minWidth="72px"
    >
      <Box
        data-testid={id}
        height="48px"
        width="48px"
        bgcolor={disabled ? "grey.accent2" : "primary.main"}
        borderRadius="100%"
        color="#ffffff"
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ cursor: disabled ? "normal" : "pointer" }}
        onClick={() => (disabled ? () => null : handleAction())}
      >
        {icon}
      </Box>
      <Typography variant="subtitle1" textAlign="center">
        {title}
      </Typography>
    </Box>
  );
};

export default NftAction;
